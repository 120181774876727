import {AbstractControl, AsyncValidatorFn, FormArray, FormGroup, ValidatorFn} from '@angular/forms';
import {delay, first, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {SadaCustomValidator} from '../../../validator/sada-custom-validator';
import moment from 'moment/moment';
import {ProgramType} from '../../../models/program-type';
import {bankNameChoices} from '../../../pages/bank-details/bank-details.util';

/**
 * validate expression.
 * @param regex The regular-expression string.
 * @param errorKey The error-message key to use if validation fails.
 */
export function regexValidators(regex: string, errorKey: string): ValidatorFn {

  return (control: AbstractControl): {  [key: string]: any } | null => {

    let valid = false;

    try {
      const value = control.value;
      const regexp = new RegExp(regex);
      valid =  regexp.test(value);
    } catch (e) { }

    return valid ? null : { customErrorKey: errorKey };
  };
}

// isLinkParamNeedTranslate supported only in sd-date component
export function functionValidator(fn: any, errorKey: string, errorParam?: any, params?: any[],
                                  isLinkParamNeedTranslate: boolean = false): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let valid = true;

    try {
      const value = control.value;
      if (value) {
        valid = !!params ? fn([value, ...params]) : fn([value]);
      }
    } catch (e) {
    }
    return valid ? null : {
      customErrorKey: errorKey,
      customErrorParam: errorParam,
      isParamNeedTranslate: isLinkParamNeedTranslate // This is supported only in sd-date component
    };
  };
}

/**
 * Converts provided function to AsyncValidatorFn.
 * In order to use this method, passed in fn should accept AbstractControl and return Observable<boolean>.
 */
export function toAsyncValidatorFn(fn: any, errorKey: string): AsyncValidatorFn {

  return control => {
    return of(control).pipe(
      delay(2), // Delay is required to control the number of calls.
      switchMap((value) => {
        return fn(control)
      }),
      map((valid: boolean) => {
          return (valid ? null : {asyncValidatorFn: errorKey})
      }),
      first());
  }

}

/**
 * Converts provided function to AsyncValidatorFn. In order to use this method, passed in fn should return Observable<boolean>
 */
export function asyncFunctionValidator(fn: any, errorKey: string, delayTime?: number): AsyncValidatorFn {
  return control => {
    if (control.value) {
      return of(control.value).pipe(
        delay(2), // Delay is required to control the number of calls.
        switchMap((value) => {
          if (value) {
            return fn(value)
          } else {
            return of(true)
          }
        }),
        map((valid: boolean) => {
          return (valid ? null : {customErrorKey: errorKey})
        }),
        first());
    } else {
      return of(null);
    }
  }
}

// Min and Max Length validator with custom error message
export function customErrMsgMinAndMaxLengthValidators(errorKey: string, minLength, maxLength): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let valid = true;

    try {
      const value = control.value;
      if (value) {
        valid = value.length >= minLength && value.length <= maxLength;
      }
    } catch (e) {
    }
    return valid ? null : {
      customMinMaxErrorKey: errorKey
    };
  };
}

// validate vehicle Year from today
export function validateVehicleYear(param: number[]): boolean {
  const inputYear = param[0];
  if (!inputYear) {
    return false;
  }
  return inputYear - new Date().getFullYear() <= vehicleModelYearCurrentYearDifference;
}

export const positiveCurrencyPattern = '^(([1-9][0-9]*)?[0-9](\\.[0-9]{0,2})?|\\.[0-9]{1,2})$';
export const nameCharacterPattern = '^[a-zA-Z0-9 .&\'-]+$';
export const numberPatternWithoutDecimal = '^(([1-9][0-9]*))$';
export const currencyPattern = '^-?([0-9]+)?([.]?[0-9]{0,2})?$';
export const inValidVehicleModelYearPattern = '(100[0-9]|10[1-9][0-9]|1[1-8][0-9]{2})'; // Year between 1000 to 1899
export const lettersPattern = '^[a-zA-Z-ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü]*$';
export const alphaSplCharPattern = '^[a-zA-Z0-9-ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü\'. ]*$';
export const alphaNonNumericSplCharPattern = '^[a-zA-Z-ÀÇ-ÌÎÏÙÒÔÛÜßàç-ìîïòôùûü\'’.() ]*$';
export const lettersSplCharPattern = '^[a-zA-Z -]*$';
export const amountMaxLength = 10;
export const vehicleModelYearCurrentYearDifference = 1;

export function validateDateWithMinAndFuture(date1ControlKey: string, date2ControlKey: string) {
  let valid = false;

  return (control: AbstractControl): {  [key: string]: any } | null => {

    const date1ControlValue = control.get(date1ControlKey)?.value
    // const date2ControlValue = '1992     /    01      /     01'
    const date2ControlValue = control.get(date2ControlKey)?.value

    if (date1ControlValue !== undefined && date1ControlValue !== ''
      && date2ControlValue !== undefined && date2ControlValue !== '') {
      valid = SadaCustomValidator.validateDateWithMinAndFuture([date1ControlValue, date2ControlValue])
    } else {
      valid = true
    }

    if (!valid) {
      control.get(date1ControlKey).setErrors({ crossField: true });
    }

    return valid ? null : { crossField: true };

  };
}

/**
 * Validate whether value of field2 is greater than value of field1.
 * Note: It is implemented to be used as a cross-field validator.
 * @param field1 The field that is supposed to have the lesser value.
 * @param field2 The field that is supposed to have the greater value.
 */
export function validateGreaterThan(field1: string, field2: string): ValidatorFn {
  let valid = true;

  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.get(field2).value && control.get(field1).value) {
      valid = Number(control.get(field2).value) > Number(control.get(field1).value);
    }

    if (!valid) {
      control.get(field2).setErrors({...control.get(field2).errors, crossField: true});
    } else if (control.get(field1).value && control.get(field2).value) {
      if (control.get(field2)?.errors?.crossField) {
        if (Object.keys(control.get(field2)?.errors).length > 1) { // Has other error types
          delete control.get(field2).errors.crossField;  // Only reset the crossField error
        } else {
          control.get(field2).setErrors(null); // Reset the error object
        }
      }
    }

    return valid ? null : control.get(field2).errors;
  };
}

/**
 * Validate whether value of field2 is greater than value of field1.
 * Note: It is implemented to be used as a cross-field validator.
 * @param field1 The field that is supposed to have the greater value.
 * @param field2 The field that is supposed to have the lesser value.
 */
export function validateGreaterThanOtherValue(field1: string, field2: string): ValidatorFn {
  let valid = true;

  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.get(field2).value && control.get(field1).value) {
      valid = Number(control.get(field2).value) >= Number(control.get(field1).value);
    }

    if (!valid) {
      control.get(field1).setErrors({...control.get(field1).errors, crossField: true});
    } else if (control.get(field1).value && control.get(field2).value) {
      if (control.get(field1)?.errors?.crossField) {
        if (Object.keys(control.get(field1)?.errors).length > 1) { // Has other error types
          delete control.get(field1).errors.crossField;  // Only reset the crossField error
        } else {
          control.get(field1).setErrors(null); // Reset the error object
        }
      }
    }

    return valid ? null : control.get(field1).errors;
  };
}
export function validateGreaterThanProvidedValue(field: string, value: number): ValidatorFn {
  let valid = true;

  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.get(field).valid) {
      return null;
    }

    if (value && control.get(field).value) {
      valid = Number(control.get(field).value) > value;
    }

    if (!valid) {
      control.get(field).setErrors({crossField: true});
    }

    return valid ? null : {crossField: true};

  };
}

export function requiredIfOtherCheckBoxIsFalse(checkBoxField: string, otherCheckBoxField: string) {
  const valid = true;

  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.get(otherCheckBoxField)?.value[0] && !control.get(checkBoxField)?.value[0]) {
      control.get(checkBoxField).setErrors({crossField: true});
    } else {
      control.get(checkBoxField).setErrors(null);
    }

    return valid ? null : {crossField: true};

  };
}

export function requiredIfOtherCheckBoxesAreFalse(checkBoxField: string, otherCheckBoxFields: string[]) {
  const valid = true;

  return (control: AbstractControl): { [key: string]: any } | null => {
    let count=0;
    otherCheckBoxFields.forEach(otherCheckBoxField=>{
      if(!control.get(otherCheckBoxField)?.value[0] && !control.get(checkBoxField)?.value[0]) {
        count++;
      }
    });


    if(count===otherCheckBoxFields.length){
      control.get(otherCheckBoxFields[otherCheckBoxFields.length-1]).setErrors({crossField: true});
    } else {
      control.get(checkBoxField).setErrors(null);
    }
    return valid ? null : {crossField: true};

  };
}

export function requireCheckboxesToBeChecked(minRequired = 1): ValidatorFn {
  return function validate(formArray: FormArray) {
    let checked = 0;

    Object.keys(formArray.controls).forEach(key => {
      const control = formArray.controls[key];

      if (control.value === true) {
        checked++;
      }
    });

    if (checked < minRequired) {
      return {
        requireCheckboxesToBeChecked: true,
      };
    }

    return null;
  };
}

export function validateReceivedSocialAssistanceApplicationDate(field1: string, field2: string, applicantType: string) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let date;
    let program;

    if (!!control.get(field1)?.value && !!control.get(field2)?.value &&
      SadaCustomValidator.isValidYearMonth([control.get(field1).value])) {
      date = control.get(field1).value
      program = control.get(field2).value;
      if (applicantType === 'Applicant' &&
        SadaCustomValidator.isDateWithinPastMonthsOnCondition(moment(date, 'YYYY/MM'),
          0, 3, program === ProgramType.ONW)) {
        control.get(field1).setErrors({
          crossFieldErrorKey: 'error.reopenPastApplication',
          crossFieldErrorParam: {link: 'start-two.dialog.reOpenApplication.link'},
          isParamNeedTranslate: true
        });
      } else {
        if (control.get(field1)?.errors?.crossFieldErrorKey) {
          if (Object.keys(control.get(field1)?.errors).length > 1) {
            delete control.get(field1).errors.crossFieldErrorKey;
            delete control.get(field1).errors.crossFieldErrorParam;
            delete control.get(field1).errors.isParamNeedTranslate;
            control.get(field1).setErrors(null);
          } else {
            control.get(field1).setErrors(null); // Reset the error object
          }
        }
      }
    }
    return null;
  };
}

// TODO: Need to remove when ODSP resubmission is allowed
export function validateODSProgramForFamilyApp(applicantType: string, isODSPResubmissionAllowed: boolean) {
  if(isODSPResubmissionAllowed){
    return null;
  }

  if (applicantType !== 'Applicant') {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return null;
    }
  } else {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const receivedProgramControl = control.get('receivedSocialAssistanceProgram');
      const receivedProgramValue = receivedProgramControl?.value;
      const moneyForImmediateNeedValue = control.root.get('moneyForImmediateNeedInFamily')?.value;
      const applicantSelectedForImmNeedPanelFG = control.root.get('moneyForImmediateNeedPanel.panels.0') as FormGroup;
      const applicantSelectedForImmNeedPanelValue = applicantSelectedForImmNeedPanelFG?.get(
        'moneyForImmediateNeedInFamilyCheckbox')?.getRawValue();
      const applicantSelectedForImmNeed = applicantSelectedForImmNeedPanelValue ? applicantSelectedForImmNeedPanelValue[0] : undefined;
      const notValid = !!receivedProgramValue &&
        ((!!moneyForImmediateNeedValue && moneyForImmediateNeedValue === 'no') ||
          (applicantSelectedForImmNeed !== undefined && !applicantSelectedForImmNeed)) && receivedProgramValue === ProgramType.ODS;
      if (notValid) {
        receivedProgramControl
          .setErrors({...receivedProgramControl.errors, crossField: true});
      } else {
        if (receivedProgramControl?.errors?.crossField) {
          if (Object.keys(receivedProgramControl?.errors).length > 1) {
            delete receivedProgramControl.errors.crossField;
          } else {
            receivedProgramControl.setErrors(null); // Reset the error object
          }
        }
      }
      return !notValid ? null : receivedProgramControl.errors;
    };
  }
}

// TODO: Need to remove when ODSP resubmission is allowed
export function onChangeOfImmFinNeedForFamilyApp(isODSPResubmissionAllowed: boolean, isApplicantCheckBox?: boolean) {
  if(isODSPResubmissionAllowed){
    return null;
  }

  const path = 'receivedSocialAssistanceInPastPanel.panels.0.receivedSocialAssistanceInPastCheckBoxPanelApplicant.panels.0.receivedSocialAssistanceProgram';
  return (formGroup: FormGroup, moneyForImmediateNeedFieldInFamilyValue: any) => {
    const applicantSelectedForImmNeedPanelFG = formGroup.root.get(
      'moneyForImmediateNeedPanel.panels.0') as FormGroup;
    const applicantSelectedForImmNeedPanelValue = applicantSelectedForImmNeedPanelFG?.get(
      'moneyForImmediateNeedInFamilyCheckbox')?.getRawValue();
    const applicantSelectedForImmNeed = applicantSelectedForImmNeedPanelValue ? applicantSelectedForImmNeedPanelValue[0] : undefined;
    let receivedProgramControl = formGroup.get(path);
    // For check box questions, parent form group is not passed
    if (isApplicantCheckBox) {
      receivedProgramControl = formGroup.root.get(path);
    } else {
      receivedProgramControl = formGroup.get(path);
    }
    const receivedProgram = receivedProgramControl?.value;
    const notValid = !!receivedProgram && receivedProgram === ProgramType.ODS &&
      ((!!moneyForImmediateNeedFieldInFamilyValue && moneyForImmediateNeedFieldInFamilyValue === 'no') ||
        (applicantSelectedForImmNeed !== undefined && !applicantSelectedForImmNeed));
    if (notValid) {
      receivedProgramControl.setErrors({...receivedProgramControl.errors, crossField: true});
      receivedProgramControl.updateValueAndValidity()
    } else {
      if (receivedProgramControl?.errors?.crossField) {
        if (Object.keys(receivedProgramControl?.errors).length > 1) {
          delete receivedProgramControl.errors.crossField;
          receivedProgramControl.updateValueAndValidity()
        } else {
          receivedProgramControl.setErrors(null); // Reset the error object
          receivedProgramControl.updateValueAndValidity()
        }
      }
    }
  };
}

/**
 * This validates the value of the sibling form-controls of the same parent form-group does not have any duplicate (case-insensitive).
 * @param questionName The name of the question that the field needs to be validated except for the value of 'duplicateAllowedValue'
 */
export function validateDescriptionExists(questionName: string, duplicateAllowedValue?: string) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const groups = control.parent?.controls as FormGroup[];
    if (!groups || groups.length === 1) {
      return null;
    }
    // Find duplicates of the provided question name in FormGroups of different panels.
    const duplicates = groups.filter((group, index) =>
      groups.findIndex((grp) =>
        grp.get(questionName).value.toLowerCase() === group.get(questionName).value.toLowerCase() && grp.controls[questionName].status !== 'DISABLED'
      ) !== index &&
      // Empty description and duplicate of other question are not considered.
      control.value[questionName]?.length > 0 && control.value[questionName] === group.value[questionName] &&
      // Exclude the duplicateAllowedValue if the value is set.
      // (i.e. 'My income type is not listed here' for the other income type field in the Household income page)
      (!duplicateAllowedValue || !!duplicateAllowedValue && group.value[questionName] !== duplicateAllowedValue));

    if (duplicates?.length > 0) {
      // Need to set the error for the dynamic-question to pick up the message key.
      control.get(questionName).setErrors({crossField: true});

      // Need to remove error flag on the firstMatch only from parent group
      const firstMatchIndex = groups.findIndex((grp) =>
        grp.get(questionName).value.toLowerCase() === control.get(questionName).value.toLowerCase() && grp.controls[questionName].status !== 'DISABLED');
      groups[firstMatchIndex].get(questionName).setErrors({crossField: false});
    }
    return duplicates?.length > 0 ? {crossField: true} : null;
  };
}

export function validateAccountNumberLength() {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const bankName = control.get('bankName')?.value;
    const accountNumberControl = control.get('accountNumber')
    const accountNumber = accountNumberControl?.value;
    if(!accountNumber){
      return null;
    }
    const bankNameValues = bankNameChoices().filter(bank=>bank.value === bankName);
    const accountNumPattern = !!bankNameValues.length ? new RegExp(`^\\d{7,${bankNameValues[0].relatedValue}}$`) : new RegExp(`^\\d{7,12}$`)
    const accountNumErrorKey = !!bankNameValues.length && accountNumber.length >= 7 ? 'bank-details.error.invalidAccountNumber.custom' : 'bank-details.error.invalidAccountNumber'
    const accountNumErrorParam = !!bankNameValues.length ? bankNameValues[0].relatedValue : null
    if(!accountNumPattern.test(accountNumber)){
      // tslint:disable-next-line:max-line-length
      accountNumberControl.setErrors({
        crossField: true,
        dynamicErrorKey: accountNumErrorKey,
        dynamicErrorParam: {maxAccountNumberLength: [accountNumErrorParam, false]}});
      return {crossField: true}
    }
    accountNumberControl.setErrors(null);
    return null;
  }
}
