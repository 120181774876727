import {AfterViewChecked, ChangeDetectorRef, Component, Inject, LOCALE_ID, NgZone, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {PageBaseComponent} from '../PageBaseComponent';
import {IntakeService} from '../../services/intake.service';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ApplicantType} from '../../models/applicant-type';
import {FieldsCheckUtil} from '../../utils/fields-check-util';

import {Languages, MaritalStatus, Sexes} from '../../common/utils/questions/question-choices';
import {ExternalRouter} from '../../external.router';
import {FormControlConfigs} from '../../common/status-in-canada-questions/util/status-in-canada-component.formcontrols';
import {ConfigService} from '../../services/config.service';
import {SadaCustomValidator} from '../../validator/sada-custom-validator';
import {EmailService} from '../../services/email.service';
import {CaseWorkerAuthorizeService} from '../../services/caseWorkerAuthorizeService';
import {DataType} from '../../common/ui/text-question/util/text-question-component.util';
import {NameUpdateUtil} from '../../utils/name-update-util';
import {AuthService} from '../../services/auth.service';
import {PageInfo} from '../../models/page-map';
import {PageService} from '../../services/page.service';
import {distinctUntilChanged, filter, startWith, take} from 'rxjs/operators';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {UrlInfo} from '../../models/url-map';
import {ValidationFnsUtil} from '../../utils/validation-fns-util';
import {ReapplicationService} from '../../services/reapplication.service';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent extends PageBaseComponent implements OnInit, OnDestroy, AfterViewChecked {
  form: FormGroup | undefined;
  applicantType = ApplicantType.APPLICANT;

  maritalStatusChoices = MaritalStatus;

  showError = false;
  officeLocationLinkParam: any;
  isLoggedInPS = false;
  authenticatedMyBApplicant = false;
  isReapplication = false;

  readonly dataType = DataType;
  readonly customRequiredErrorMessage = 'error.empty.email.address';
  emailValidationFns;
  emailAsyncValidationFns;
  sexes = Sexes;
  languages = Languages;
  dobValidationFunctions;
  noLastNameCheckBoxItems = [{value: 'yes', label: 'personal-information.noLastName', checked: false}]
  noUniqueEmailCheckBoxItem = [{value: 'yes', label: 'personal-information.noUniqueEmail', checked: false}]
  firstNameValidationFunction = [
    {
    validationFunction: SadaCustomValidator.validateInvalidNameWithOnlySpecialCharacter,
    errorKey: 'error.invalid.firstNameWithOnlyCharacters'
    },
    {
      validationFunction: SadaCustomValidator.validateName,
      errorKey: 'error.invalid.firstName'
    },
    {
      validationFunction: SadaCustomValidator.validateInvalidFrCharacterInName,
      errorKey: 'error.invalid.fr.character'
    }
  ];
  lastNameValidationFunction = [
    {
      validationFunction: SadaCustomValidator.validateInvalidNameWithOnlySpecialCharacter,
      errorKey: 'error.invalid.lastNameWithOnlyCharacters'
    },
    {
      validationFunction: SadaCustomValidator.validateName,
      errorKey: 'error.invalid.lastName'
    },
    {
      validationFunction: SadaCustomValidator.validateInvalidFrCharacterInName,
      errorKey: 'error.invalid.fr.character'
    }
  ];

  constructor(public formBuilder: FormBuilder, private router: Router, public route: ActivatedRoute,
              public intake: IntakeService, public translator: TranslateService,
              public ngZone: NgZone, public idle: Idle, public dialog: MatDialog, private readonly changeDetectorRef: ChangeDetectorRef,
              public externalRouter: ExternalRouter, public configService: ConfigService, private emailService: EmailService,
              public authorizeService: CaseWorkerAuthorizeService, @Inject(LOCALE_ID) protected localeId: string,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService,
              private reapplicationService: ReapplicationService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.personalInfo;
    this.form = this.formBuilder.group({
      firstName: [],
      lastName: [],
      noLastName: [],
      dateOfBirth: [],
      sexAtBirth: [],
      email: [],
      noUniqueEmail:[],
      phone: [],
      contactLanguage: [],
      maritalStatus: [''],
      childrenLivingWithYou: [],
    });
  }

  ngOnInit(): void {
    this.isAuthorizedUser = this.authorizeService.isAuthorized();  // Caseworker
    this.isLoggedInPS = this.authService.isAuthorizedToSave();    // Logged in through public secure / myb flow
    this.authenticatedMyBApplicant = this.isLoggedInPS && this.authService.isMyBApplicant();
    this.isReapplication = this.isLoggedInPS && this.reapplicationService.isReapplication();

    this.setupLinks();
    this.initializeForm();

    this.emailValidationFns = ValidationFnsUtil.emailValidationFns(
      this.applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse, this.isAuthorizedUser)
    this.emailAsyncValidationFns = [
      {
        validationFunction: (value) => this.emailService.validateEmailAddress(value),
        errorKey: 'error.invalid.email.domain'
      }
    ];
    this.dobValidationFunctions = this.getDOBValidationFunctions();

    this.translator.onLangChange.subscribe((lang) => {
      this.setupLinks();
      this.dobValidationFunctions = this.getDOBValidationFunctions();
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  onSubmit(toContinue): void {
    this.showPsRedirectError = false;  // Reset the error flag.
    this.showRequiredInfoBanner = false;
    let notSubmitForm = false; // prevent submitting the form when the user changes the field value while it's handling ASYNC validators

    if (this.form.valid) {
      this.saveAndContinueOrExit(toContinue);
    } else if (this.form.invalid || FieldsCheckUtil.findInvalidField(this.form.controls)) {
      this.showError = true
      this.scrollToInvalidFormControl(toContinue);
    } else {
      // Below handling is required to handle ASYNC validators. Submission should be delayed until all async validators are executed.
      // Until async validators are done, status would be 'PENDING'
      this.subscriptions$.push(
        this.form.statusChanges.pipe(
          distinctUntilChanged(),
          startWith(this.form.status),
          filter(status => status !== 'PENDING'),
          take(1)).subscribe(status => {
          this.form.updateValueAndValidity();

          // Until async validators are done status would be 'PENDING'
          if (!notSubmitForm && this.form.valid && status === 'VALID') {
            this.saveAndContinueOrExit(toContinue);
          } else if(!notSubmitForm && status !== 'PENDING') {
            notSubmitForm = true;
            this.showError = true
            this.scrollToInvalidFormControl(toContinue);
          }
      }));
    }
  }

  private setupLinks(): void {
    this.officeLocationLinkParam = {
      link: this.configService.getUrl(this.translator.currentLang, UrlInfo.officeLocation)
    };
  }
  setEmail(value:any){
    this.form.get('email').setValue(value);
  }
  private convertStatusInCanadaField(controlName: string): string {
    const statusInCanadaFields = {
      applicantSponsored: 'sponsored',
      socialInsuranceNumber: 'sinNumber'
    }
    const keys = Object.keys(statusInCanadaFields);
    for (const key of keys) {
        if (key === controlName){
          return statusInCanadaFields[key]
        }
    }
    return controlName
  }

  public shouldShowInfoMessage(): string {
    const date = this.form.controls.dateOfBirth?.value;
    const ageBetween16And17Half = SadaCustomValidator.validateAgeBetween16And17_5(date);
    if (ageBetween16And17Half) {
      return 'personal-information.notEligible.odsp.ageLimit18';
    }
    return '';
  }
  private proceedToCreateAnAccountPage(): boolean {
    return !this.isLoggedInPS && this.applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse === 'APPLICATION_SELF';
  }

  saveAndContinueOrExit(toContinue: boolean) {
    if (this.form.controls.firstName?.value && this.applicationAnswers.jsonData.firstName) {
      NameUpdateUtil.updateFirstNameInSavedData(this.form.controls.firstName.value,
        this.applicationAnswers.jsonData, this.localeId, ApplicantType.APPLICANT, 0);
    }

    // Clean up spouse or child info if not required any more
    this.updateAppDataIfFamilyInfoChanges()

    this.updateAppDataIfNotSponsored()

    this.subscriptions$.push(this.saveForm(toContinue)?.pipe(distinctUntilChanged()).subscribe(() => {
      if (toContinue) {
        let redirectTo: string;
        if (this.proceedToCreateAnAccountPage()) {
            this.router.navigate(['/', 'intake', PageInfo.createAccount]);
            return;
          }
        if (this.isSpouseInfoRequired()) {
          this.intake.setAppSpouseRequired(true)
          if(this.isChildInfoRequired()) { this.intake.setAppChildRequired(true);}
          redirectTo = ['/', 'intake/', PageInfo.spouseInfo].join('');
        } else if (this.isChildInfoRequired()) {
          this.intake.setAppChildRequired(true)
          redirectTo = ['/', 'intake/', PageInfo.childrenInfo].join('');
        } else {
          redirectTo = ['/', 'intake/', PageInfo.additionalInfo].join('');
        }
        this.router.navigate([redirectTo]);
      } else {
        this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
      }
    }));
  }
  get maritalStatusValue(): string {
    return this.form.controls.maritalStatus?.value
  }

  get childrenLivingWithYouValue(): string {
    return this.form.controls.childrenLivingWithYou?.value;
  }

  postInitializeForm() {
    if (this.applicationAnswers.jsonData?.noLastName?.length) {
      this.noLastNameCheckBoxItems = [{value: 'yes', label: 'personal-information.noLastName', checked: true}]
      this.form.controls.lastName.reset();
      this.form.controls.lastName.disable();
    }

    if (this.applicationAnswers.jsonData?.noUniqueEmail?.length) {
      this. noUniqueEmailCheckBoxItem = [{value: 'yes', label: 'personal-information.noUniqueEmail', checked: true}]
      this.form.controls.email.reset();
      this.form.controls.email.disable();
    }


  }

  preSaveApplication() {}

  private updateAppDataIfFamilyInfoChanges() {
    if (!this.isSpouseInfoRequired()) {
      delete this.applicationAnswers.jsonData.spouseFirstName
      delete this.applicationAnswers.jsonData.spouseLastName
      delete this.applicationAnswers.jsonData.spouseNoLastName
      delete this.applicationAnswers.jsonData.spouseDateOfBirth
      delete this.applicationAnswers.jsonData.spouseSexAtBirth
      delete this.applicationAnswers.jsonData.spouseEmail
      delete this.applicationAnswers.jsonData.spouseNoUniqueEmail
      Object.keys(FormControlConfigs.spouse).forEach(field => {
        const name = FormControlConfigs.spouse[field].name
        delete this.applicationAnswers.jsonData[name]
      })
      this.intake.setAppSpouseRequired(false)
    }

    if (!this.isChildInfoRequired()) {
      delete this.applicationAnswers.jsonData.childList
      this.intake.setAppChildRequired(false)
    }
  }

  private isSpouseInfoRequired() {
    return this.maritalStatusValue && ('Married' === this.maritalStatusValue || 'Common Law' === this.maritalStatusValue);
  }

  private isChildInfoRequired() {
    return this.childrenLivingWithYouValue && 'yes' === this.childrenLivingWithYouValue;
  }

  private updateAppDataIfNotSponsored() {
    if(!this.form.get('applicantSponsored')){
      delete this.applicationAnswers.jsonData.applicantSponsored
      delete this.applicationAnswers.jsonData.immigrationFileNumber
      this.removeSponsorInfo()
      this.removeSponsorListInfo()
    }
    if(!this.form.get('arrivalDateToCanada')){
      delete this.applicationAnswers.jsonData.arrivalDateToCanada
    }
    if( this.form.get('applicantSponsored')?.value === 'no'){
      this.removeSponsorInfo()
      this.removeSponsorListInfo()
    }
  }

  private removeSponsorInfo() {
    delete this.applicationAnswers.jsonData.sponsorFirstName
    delete this.applicationAnswers.jsonData.sponsorLastName
    delete this.applicationAnswers.jsonData.sponsorLivesWith
    delete this.applicationAnswers.jsonData.sponsorSupportAmount
  }

  private removeSponsorListInfo() {
    const {anyoneSponsoredList, anyoneSponsoredCheckbox} = this.applicationAnswers.jsonData
    let newSponsoredList = anyoneSponsoredList?.filter((app: {applicantType: string }) => app.applicantType !== 'Applicant') || [];
    const newSponsoredCheckbox = anyoneSponsoredCheckbox?.filter((title: string) => !title.startsWith('Applicant') && !title.startsWith('Demandeur')) || []

    function getSponsorInfoForSpouse(lastName: string|undefined, firstName: string) {
      return newSponsoredList.map((app: {applicantType: string, sponsorFirstName: string, sponsorLastName: string }) => {
        if(app.applicantType === 'Spouse'){
          if(!lastName){
            return ({...app, sponsorFirstName: firstName})
          }else{
            return ({...app,
              sponsorFirstName: firstName,
              sponsorLastName: lastName
            })

          }
        }
        return {...app}
      })
    }
    function getSponsorInfoForTheOnlyChild(lastName: string|undefined, firstName: string) {
      return newSponsoredList.map((app: {applicantType: string, sponsorFirstName: string, sponsorLastName: string }) => {
        if(app.applicantType.startsWith('Child')){
          if(!lastName){
            return ({...app, sponsorFirstName: firstName})
          }else{
            return ({...app,
              sponsorFirstName: firstName,
              sponsorLastName: lastName
            })

          }
        }
        return {...app}
      })
    }
    if(newSponsoredCheckbox.length < 2 && this.applicationAnswers.jsonData.commonSponsorFirstName){
      newSponsoredList = getSponsorInfoForSpouse(this.applicationAnswers.jsonData.commonSponsorLastName,
        this.applicationAnswers.jsonData.commonSponsorFirstName)
      newSponsoredList = getSponsorInfoForTheOnlyChild(this.applicationAnswers.jsonData.commonSponsorLastName,
        this.applicationAnswers.jsonData.commonSponsorFirstName)
      delete this.applicationAnswers.jsonData.commonSponsorFirstName
      delete this.applicationAnswers.jsonData.commonSponsorLastName
      delete this.applicationAnswers.jsonData.allHaveSameSponsor

    }
    if(newSponsoredCheckbox.length){
      this.applicationAnswers.jsonData = {
        ...this.applicationAnswers.jsonData,
        anyoneSponsoredList: [...newSponsoredList],
        anyoneSponsoredCheckbox: [...newSponsoredCheckbox]
      };
    }else{
      delete this.applicationAnswers.jsonData.anyoneSponsoredList
      delete this.applicationAnswers.jsonData.anyoneSponsoredCheckbox
    }
  }

  onNoLastNameCheckBoxChanges(questionKey: string, event: any): void {
    if(!this.authenticatedMyBApplicant){
      if (event && event.length) {
        this.form.controls.noLastName.setValue(event)
        this.form.controls.lastName.reset();
        this.form.controls.lastName.disable();
        this.openDialog(NoLastNamePopup.noLastName)
      } else {
        delete this.applicationAnswers.jsonData.noLastName
        this.form.controls.noLastName.reset()
        this.form.controls.lastName.enable()
      }
    }
  }
  isEmailRequired():boolean
  {
    return this.isAuthorizedUser || this.applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse === 'APPLICATION_SELF'
    || this.intakeService.getIsRelatedToLocalOfficeAssistance()
  }

  private getDOBValidationFunctions() {
    return [
      // This validation has to be in this order, to override the previous validation error.
      {
        validationFunction: (value: []) => {
          return SadaCustomValidator.validateDateWithMinAndFuture([...value, '1900/01/01']);
        },
        errorKey: 'error.invalid.year'
      },
      {
        validationFunction: (value: []) => {
          return !SadaCustomValidator.validateDateWithMinAndFuture([...value, '1900/01/01'])
            || SadaCustomValidator.validateOptionalOverAge([...value, '16']);
        },
        errorKey: 'personal-information.notEligible.ageLimit16', errorParam: this.officeLocationLinkParam
      }
    ]
  }

  onNoUniqueEmailCheckBoxChanges(questionKey: string, event: any): void {
    if (event && event.length) {
      this.form.controls.noUniqueEmail.setValue(event)
      this.form.controls.email.reset();
      this.form.controls.email.disable();
    } else {
      delete this.applicationAnswers.jsonData.noUniqueEmail
      this.form.controls.noUniqueEmail.reset()
      this.form.controls.email.enable()
    }
  }

  isNoLastNameSelected() {
    return this.form.controls.noLastName?.value?.length
  }

  get isApplyingForSomeOneElse(): boolean {
    return 'APPLICATION_SELF' !== this.applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse;
  }

  disableForAuthenticatedApplicant(): boolean {
    return (this.isLoggedInPS && this.isApplyingForMyself());
  }

  private isApplyingForMyself(): boolean {
    return this.applicationAnswers?.jsonData?.applyingForYourselfOrSomeoneElse === 'APPLICATION_SELF';
  }
}

export const NoLastNamePopup = {
  noLastName: {
    title: 'dialog.noLastName.title',
    body: 'dialog.noLastName',
    button: 'btn.continue',
    isInactive: true
  }
}
