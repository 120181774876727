import {Observable} from 'rxjs';

export abstract class AuthService {
  abstract getAuthToken(): string

  abstract checkLoggedIn(): void

  abstract isLoggedIn(): boolean

  // @ts-ignore
  abstract login(pathname  = ''): void

  abstract logout(): Observable<object>

  abstract psLogout(redirectUrl: string): Observable<string>

  abstract scheduleLogout(): void

  abstract validateRedirectTokenAndInitializeSession(token: string, isNew: string, lang: string): Observable<boolean>;

  abstract isAuthorizedToSave(): boolean;

  abstract setAuthorizedToSave(authorizedToSave: boolean);

  abstract isActiveSessionExists(): boolean;

  abstract setActiveSessionExists(activeSessionExists: boolean);

  abstract getPSRedirectURL(email: string, lang: string): Observable<string>

  abstract resetApplication(lang: string): Observable<null>

  abstract mockLogin(email: string): Observable<URL>

  abstract getUserName(): Observable<string>;

  abstract setUserName(userName: string): void;

  abstract getAccessToken(): string;

  abstract setAccessToken(token: string);

  abstract isMyBApplicant(): boolean;

  abstract resetAuthValues();
}
