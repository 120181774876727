import {ProgramType} from '../models/program-type';

export abstract class ReapplicationService {

  abstract isReapplication(): boolean;

  abstract setIsReapplication(isReapplication: boolean);

  abstract reapplicationProgramType(): string;

  abstract setProgramType(programType: ProgramType);
}
