import {Converter} from '../../../common/utils/converters/converter';
import {ApplicationAnswers} from '../../../models/data.model';
import {Panel, Question, VisibleOnCondition} from '../../../common/utils/questions/question';
import {
  addChildOptions,
  addDisableOthersOnCondition,
  addSpouseOption,
  createDynamicPanel,
  removeSpaces
} from '../../../common/utils/dynamic-panel/dynamic-panel.util';
import {Injectable} from '@angular/core';
import {FamilyInformationQuestionService} from '../family-information-question.service';
import {Choice} from '../../../common/utils/questions/question-choices';
import {AdditionalInfoMappingUtil} from './additional-info-mapping.util';

@Injectable()
export class AppDataToFamilyInfoQuestions implements Converter<ApplicationAnswers, Question<string>[]> {

  constructor(private questionService: FamilyInformationQuestionService) {
  }

  convert(appData: ApplicationAnswers, questions: Question<string>[], isODSPResubmissionAllowed?: boolean): void {
    this.convertSocialAssistanceInPastQuestions(appData, questions, isODSPResubmissionAllowed);
    this.convertDisabilityProgramQuestions(appData, questions);
    this.convertLivingInInstitutionQuestions(appData, questions);
    this.convertCurrentlyIncarceratedQuestions(appData, questions);
    this.convertFamilyAccommodationServices(appData,questions);
    this.convertSpecialDietQuestions(appData, questions);
    this.convertReceivingACSDQuestions(appData, questions);
    this.convertMoneyForImmediateNeed(appData, questions);
    this.convertNutritionalNeedsQuestions(appData, questions);
    this.convertFullTimeStudentQuestions(appData, questions);
    this.setFieldValue('caringForChild', appData, questions);
  }

  private addApplicantOptions(appData: ApplicationAnswers, question: Question<string>, selectedOptions: string[],
                              disableApplicantCheckbox: boolean = false): void {
    question.options[0].labelParam = {name: appData.jsonData.firstName};
    question.options[0].alternateValue = 'Applicant: '.concat(appData.jsonData.firstName);
    question.options[0].value = selectedOptions?.includes(question.options[0].alternateValue);
    question.options[0].disabled = disableApplicantCheckbox
    if ((appData?.jsonData?.maritalStatus === 'Common Law' || appData?.jsonData?.maritalStatus === 'Married')
      && appData?.jsonData?.spouseFirstName) {
      addSpouseOption(appData, question, selectedOptions);
    }

    if (appData?.jsonData?.childrenLivingWithYou === 'yes' && appData?.jsonData?.childList?.length >0) {
      addChildOptions(appData, question, selectedOptions);
    }
  }

  private convertSocialAssistanceInPastQuestions(appData: ApplicationAnswers, questions: Question<string>[], isODSPResubmissionAllowed: boolean) {
    this.setFieldValue('familyReceivedSocialAssistanceInPast', appData, questions);

    const panelQuestions = questions.find(
      question => question.key === 'receivedSocialAssistanceInPastPanel').panels[0].questions;
    this.addApplicantOptions(appData, panelQuestions[0], appData.jsonData.socialAssistanceRecipients);
    const datesOfBirth = this.populateApplicantDatesOfBirth(appData);

    const index = questions.findIndex(q => q.key === 'receivedSocialAssistanceInPastPanel');
    addDynamicPanels('familyReceivedSocialAssistanceInPast',
      'receivedSocialAssistanceInPastCheckBox',
      index,
      questions,
      panelQuestions,
      this.populateAssistanceRecipientDetail(appData, questions, datesOfBirth, isODSPResubmissionAllowed),
      [], {showAddButton: false, showLineSeparator: true});
  }

  private convertDisabilityProgramQuestions(appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setFieldValue('personWithDisabilityInFamily', appData, questions);
    const panelQuestions = questions.find(
      question => question.key === 'disabilityPanel').panels[0].questions;
    const disableApplicantCheckBox = (appData.jsonData.livingOnFirstNationsReserveLand === 'yes' && appData.jsonData.withDisability === 'yes')
    this.addApplicantOptions(appData, panelQuestions[0], appData.jsonData.personWithDisabilityInFamilyCheckbox,disableApplicantCheckBox)

    addDynamicPanels('disabilityPanel',
      'personWithDisabilityInFamilyCheckbox',
      0,
      questions,
      panelQuestions,
      this.populateDisabilityPanelQuestions(appData, questions),
      [], {showAddButton: false, showLineSeparator: true});
  }

  /**
   * Returns the list of dates (in YYYY/MM/DD format) of birth of applicants in the application.
   */
  private populateApplicantDatesOfBirth(appData: ApplicationAnswers): string[] {
    const datesOfBirth: string[] = [];
    datesOfBirth.push(appData.jsonData.dateOfBirth);
    if ((appData?.jsonData?.maritalStatus === 'Common Law' || appData?.jsonData?.maritalStatus === 'Married')
        && appData?.jsonData?.spouseFirstName) {
      datesOfBirth.push(appData.jsonData.spouseDateOfBirth);
    }

    if (appData?.jsonData?.childrenLivingWithYou === 'yes' && appData?.jsonData?.childList?.length > 0) {
      appData.jsonData.childList.forEach(child => {
        datesOfBirth.push(child.childDateOfBirth);
      });
    }
    return datesOfBirth;
  }

  private populateAssistanceRecipientDetail(appData: ApplicationAnswers,
      questions: Question<string>[], datesOfBirth: string[], isODSPResubmissionAllowed: boolean): Map<string, Map<number, Question<string>[]>> {
    const map: Map<string, Map<number, Question<string>[]>> = new Map();
    const question = questions.find(q => q.key === 'receivedSocialAssistanceInPastPanel');
    const qu = question.panels[0].questions[0];
    qu.options.forEach((o, i) => {
      let detailsQuestions;
      if (o.alternateValue.startsWith('Applicant')) {
        detailsQuestions = [...this.questionService.getSocialAssistanceRecipientDetailQuestions(
          'additional-information.multipleApplicants.applicantAssistanceDate', [o.labelParam?.name], i, datesOfBirth, 'Applicant', isODSPResubmissionAllowed)];
      } else if (o.alternateValue.startsWith('Spouse')) {
        detailsQuestions = [...this.questionService.getSocialAssistanceRecipientDetailQuestions(
          'additional-information.multipleApplicants.spouseAssistanceDate', [o.labelParam?.name], i, datesOfBirth, 'Spouse', isODSPResubmissionAllowed)];
      } else if (o.alternateValue.startsWith('Child')) {
        detailsQuestions = [...this.questionService.getSocialAssistanceRecipientDetailQuestions(
          'additional-information.multipleApplicants.childAssistanceDate', [o.labelParam.index, o.labelParam?.name], i, datesOfBirth, 'Child', isODSPResubmissionAllowed)];
      }

      const applicantValue = appData.jsonData.socialAssistanceDetailsList?.find(a => a.applicant === o.alternateValue);
      if (applicantValue) {
        detailsQuestions[0].value = applicantValue.receivedSocialAssistanceProgram;
        detailsQuestions[1].value = applicantValue.applicationDate;
        detailsQuestions[2].value = applicantValue.memberID;
      }
      const panelMap: Map<number, Question<string>[]> = new Map();
      panelMap.set(0, detailsQuestions);
      map.set(o.alternateValue, panelMap);
    });
    return map;
  }

  private convertLivingInInstitutionQuestions(appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setFieldValue('familyMembersResidingInInstitution', appData, questions);
    const panelQuestions = questions.find(
      question => question.key === 'livingInAnInstitutionPanel').panels[0].questions;
    this.addApplicantOptions(appData, panelQuestions[0], appData.jsonData.familyMembersResidingInInstitutionCheckbox)

    addDynamicPanels('livingInAnInstitutionPanel',
      'familyMembersResidingInInstitutionCheckbox', 0,
      questions,
      panelQuestions,
      this.populateLivingInInstitutionDetail(appData, questions),
      [], {showAddButton: false, showLineSeparator: true});
  }

  private populateLivingInInstitutionDetail(appData: ApplicationAnswers,
                                            questions: Question<string>[]): Map<string, Map<number, Question<string>[]>> {

    const map: Map<string, Map<number, Question<string>[]>> = new Map();
    const question = questions.find(q => q.key === 'livingInAnInstitutionPanel');
    const qu = question.panels[0].questions[0];
    qu.options.forEach(o => {
      let detailsQuestions;
      if (o.alternateValue.startsWith('Applicant')) {
        detailsQuestions = [...this.questionService.getLivingInAnInstitutionDetailQuestions(
          'additional-information.multipleApplicants.applicantInstitutionLocation',
          [o.labelParam?.name])];
      } else if (o.alternateValue.startsWith('Spouse')) {
        detailsQuestions = [...this.questionService.getLivingInAnInstitutionDetailQuestions(
          'additional-information.multipleApplicants.spouseInstitutionLocation',
          [o.labelParam?.name])];
      } else if (o.alternateValue.startsWith('Child')) {
        detailsQuestions = [...this.questionService.getLivingInAnInstitutionDetailQuestions(
          'additional-information.multipleApplicants.childInstitutionLocation',
          [o.labelParam.index, o.labelParam?.name])];
      }
      const applicantValue = appData.jsonData.familyMembersResidingInInstitutionList?.find(
        a => a.applicant === o.alternateValue);
      if (applicantValue) {
        detailsQuestions[0].value = applicantValue.institutionLocation;
        detailsQuestions[1].value = applicantValue.institutionName;
      }
      const panelMap: Map<number, Question<string>[]> = new Map();
      panelMap.set(0, detailsQuestions);
      map.set(o.alternateValue, panelMap);
    });
    return map;
  }

  private convertCurrentlyIncarceratedQuestions(appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setFieldValue('familyMembersCurrentlyIncarcerated', appData, questions);
    const panelQuestions = questions.find(
      question => question.key === 'currentlyIncarceratedPanel').panels[0].questions;
    this.addApplicantOptions(appData, panelQuestions[0], appData.jsonData.familyMembersCurrentlyIncarceratedCheckbox)

    addDynamicPanels('currentlyIncarceratedPanel',
      'familyMembersCurrentlyIncarceratedCheckbox', 0,
      questions,
      panelQuestions,
      this.populateCurrentlyIncarceratedDetail(appData, questions),
      [], {showAddButton: false, showLineSeparator: true});
  }

  private populateCurrentlyIncarceratedDetail(appData: ApplicationAnswers,
                                              questions: Question<string>[]): Map<string, Map<number, Question<string>[]>> {

    const map: Map<string, Map<number, Question<string>[]>> = new Map();
    const question = questions.find(q => q.key === 'currentlyIncarceratedPanel');
    const qu = question.panels[0].questions[0];
    qu.options.forEach(o => {
      let detailsQuestions;
      if (o.alternateValue.startsWith('Applicant')) {
        detailsQuestions = [...this.questionService.getCurrentlyIncarceratedDetailQuestions(
          true,
          'additional-information.multipleApplicants.applicantExpectedReleaseDate',
          'additional-information.multipleApplicants.applicantStayPartTimeOrFullTime',
          'additional-information.multipleApplicants.applicantStayPartTimeOrFullTime.context',
          [o.labelParam?.name])];
      } else if (o.alternateValue.startsWith('Spouse')) {
        detailsQuestions = [...this.questionService.getCurrentlyIncarceratedDetailQuestions(
          false,
          'additional-information.multipleApplicants.spouseExpectedReleaseDate',
          'additional-information.multipleApplicants.spouseStayPartTimeOrFullTime',
          'additional-information.multipleApplicants.spouseStayPartTimeOrFullTime.context',
          [o.labelParam?.name])];
      } else if (o.alternateValue.startsWith('Child')) {
        detailsQuestions = [...this.questionService.getCurrentlyIncarceratedDetailQuestions(
          false,
          'additional-information.multipleApplicants.childExpectedReleaseDate',
          'additional-information.multipleApplicants.childStayPartTimeOrFullTime',
          'additional-information.multipleApplicants.childStayPartTimeOrFullTime.context',
          [o.labelParam.index, o.labelParam?.name])];
      }
      const applicantValue = appData.jsonData.familyMembersCurrentlyIncarceratedList?.find(
        a => a.applicant === o.alternateValue);
      if (applicantValue) {
        detailsQuestions[0].value = applicantValue.expectedReleaseDate;
        detailsQuestions[1].value = applicantValue.stayPartTimeOrFullTime;
      }
      const panelMap: Map<number, Question<string>[]> = new Map();
      panelMap.set(0, detailsQuestions);
      map.set(o.alternateValue, panelMap);
    });
    return map;
  }

  private convertFamilyAccommodationServices(appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setFieldValue('additionalFamilyAccommodationServices', appData, questions);
    const panelQuestions = questions.find(
      question => question.key === 'accommodationServicesPanel').panels[0].questions;
    this.addApplicantOptions(appData, panelQuestions[0], appData.jsonData.familyAccommodationServicesCheckbox)
    addDynamicPanels('additionalFamilyAccommodationServices',
      'familyAccommodationServicesCheckbox', 0,
      questions,
      panelQuestions,
      this.populateFamilyAccommodationServiceDetail(appData, questions),
      [], {showAddButton: false, showLineSeparator: true});
  }

  private convertSpecialDietQuestions(appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setFieldValue('specialDietOrMedicalConditionInFamily', appData, questions);
    const panelQuestions = questions.find(
      question => question.key === 'specialDietOrMedicalConditionPanel').panels[0].questions;
    this.addApplicantOptions(appData, panelQuestions[0], appData.jsonData.specialDietOrMedicalConditionInFamilyCheckbox)
  }

  private convertReceivingACSDQuestions(appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setFieldValue('receivingACSDFamily', appData, questions);
    const panelQuestions = questions.find(
      question => question.key === 'receivingACSDPanel').panels[0].questions;
    const applicantsBelow20 = AdditionalInfoMappingUtil.getApplicantsBelow20AgeThreshold(appData, 20);
    this.addApplicantOptions(appData, panelQuestions[0], appData.jsonData.receivingACSDFamilyCheckbox);
      const filteredOptions: Choice[] = [];
      panelQuestions[0].options.forEach(option => {
        if(applicantsBelow20.includes(option.alternateValue)) {
          filteredOptions.push(option);
        }
      });
    panelQuestions[0].options = filteredOptions;
  }

  private convertMoneyForImmediateNeed(appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setFieldValue('moneyForImmediateNeedInFamily', appData, questions);
    const panelQuestions = questions.find(
        question => question.key === 'moneyForImmediateNeedPanel').panels[0].questions;
    const disableApplicantCheckBox = appData.jsonData.moneyForImmediateNeed === 'yes';
    this.addApplicantOptions(appData, panelQuestions[0], appData.jsonData.moneyForImmediateNeedInFamilyCheckbox, disableApplicantCheckBox);
  }

  private convertNutritionalNeedsQuestions(appData: ApplicationAnswers, questions: Question<string>[]) {
    if (AdditionalInfoMappingUtil.getApplicantsAboveAgeThreshold(appData, 10).length === 0) {
      appData.jsonData.pregnantOrBreastFeedingInFamily = 'no';
    }

    this.setFieldValue('pregnantOrBreastFeedingInFamily', appData, questions);
    const panelQuestions = questions.find(question => question.key === 'nutritionalNeedsPanel').panels[0].questions;
    const panelQuestion = panelQuestions[0];
    const applicantsAbove10 = AdditionalInfoMappingUtil.getApplicantsAboveAgeThreshold(appData, 10);

    if (applicantsAbove10.length > 0) {
      this.addApplicantOptions(appData, panelQuestion, appData.jsonData.pregnantOrBreastFeedingInFamilyCheckbox);
      const filteredOptions: Choice[] = [];
      panelQuestions[0].options.forEach(option => {
        if(applicantsAbove10.includes(option.alternateValue)) {
          filteredOptions.push(option);
        }
      });
      panelQuestion.options = filteredOptions;

      addDynamicPanels('pregnantOrBreastFeedingInFamily',
        'pregnantOrBreastFeedingInFamilyCheckbox', 0,
        questions,
        panelQuestions,
        this.populateNutritionalNeedsDetail(appData, questions),
        [], {showAddButton: false, showLineSeparator: true});
    }
  }

  private populateFamilyAccommodationServiceDetail(appData: ApplicationAnswers,
                                         questions: Question<string>[]): Map<string, Map<number, Question<string>[]>> {
    const map: Map<string, Map<number, Question<string>[]>> = new Map();
    const question = questions.find(q => q.key === 'accommodationServicesPanel');
    const qu = question.panels[0].questions[0];
    qu.options
      .forEach(o => {
        const detailsQuestions = [...this.questionService.getAdditionalAccommodationServicesDetailQuestions()];
        const applicantValue = appData.jsonData.additionalAccommodationServicesInFamilyList?.find(
          a => a.applicant === o.alternateValue);

        if (applicantValue) {
          if (applicantValue.visualCheckbox) {
            detailsQuestions[0].options[0].value = applicantValue.visualCheckbox === 'yes';
          }
          if (applicantValue.visualDescription) {
            detailsQuestions[1].value = applicantValue.visualDescription;
          }
          if (applicantValue.hearingCheckbox) {
            detailsQuestions[2].options[0].value = applicantValue.hearingCheckbox === 'yes';
          }
          if (applicantValue.hearingDescription) {
            detailsQuestions[3].value = applicantValue.hearingDescription;
          }
          if (applicantValue.verbalCheckbox) {
            detailsQuestions[4].options[0].value = applicantValue.verbalCheckbox === 'yes';
          }
          if (applicantValue.verbalDescription) {
            detailsQuestions[5].value = applicantValue.verbalDescription;
          }
          if (applicantValue.communicationCheckbox) {
            detailsQuestions[6].options[0].value = applicantValue.communicationCheckbox === 'yes';
          }
          if (applicantValue.communicationDescription) {
            detailsQuestions[7].value = applicantValue.communicationDescription;
          }
          if (applicantValue.mobilityCheckbox) {
            detailsQuestions[8].options[0].value = applicantValue.mobilityCheckbox === 'yes';
          }
          if (applicantValue.mobilityDescription) {
            detailsQuestions[9].value = applicantValue.mobilityDescription;
          }
        }
        const panelMap: Map<number, Question<string>[]> = new Map();
        panelMap.set(0, detailsQuestions);
        map.set(o.alternateValue, panelMap);
      });
    return map;
  }

  private populateDisabilityPanelQuestions(appData: ApplicationAnswers,
                                         questions: Question<string>[]): Map<string, Map<number, Question<string>[]>> {
    const map: Map<string, Map<number, Question<string>[]>> = new Map();
    const question = questions.find(q => q.key === 'disabilityPanel');
    const qu = question.panels[0].questions[0];
    qu.options
      .forEach(o => {
        let detailsQuestions;
        if (o.alternateValue.startsWith('Applicant')) {
          detailsQuestions = [...this.questionService.getDSOFamilyQuestion(
            'additional-information.multipleApplicants.applicantDSO',[o.labelParam?.name])];
        } else if (o.alternateValue.startsWith('Spouse')) {
          detailsQuestions = [...this.questionService.getDSOFamilyQuestion(
            'additional-information.multipleApplicants.spouseDSO',[o.labelParam?.name])];
        } else if (o.alternateValue.startsWith('Child')) {
          detailsQuestions = [...this.questionService.getDSOFamilyQuestion(
            'additional-information.multipleApplicants.childDSO',[o.labelParam.index, o.labelParam?.name])];
        }
        const applicantValue = appData.jsonData.developmentServicesOntarioList?.find(
          a => a.applicant === o.alternateValue);
        if (applicantValue) {
          detailsQuestions[0].value = applicantValue.developmentServicesOntario;
        }
        const panelMap: Map<number, Question<string>[]> = new Map();
        panelMap.set(0, detailsQuestions);
        map.set(o.alternateValue, panelMap);
      });
    return map;
  }


  private populateNutritionalNeedsDetail(appData: ApplicationAnswers,
                                         questions: Question<string>[]): Map<string, Map<number, Question<string>[]>> {
    const map: Map<string, Map<number, Question<string>[]>> = new Map();
    const question = questions.find(q => q.key === 'nutritionalNeedsPanel');
    const qu = question.panels[0].questions[0];
    qu.options
      .forEach(o => {
        let detailsQuestions;
        if (o.alternateValue.startsWith('Applicant')) {
          detailsQuestions = [...this.questionService.getAdditionalNutritionalNeedsDetailQuestions('additional-information.multipleApplicants.applicantPregnantDueDate','additional-information.multipleApplicants.describesYourSituationApplicant',
            'additional-information.multipleApplicants.applicantLactoseIntolerantDiet', 'additional-information.multipleApplicants.applicantLactoseIntolerantDiet.context',[o.labelParam?.name])];
        } else if (o.alternateValue.startsWith('Spouse')) {
          detailsQuestions = [...this.questionService.getAdditionalNutritionalNeedsDetailQuestions('additional-information.multipleApplicants.spousePregnantDueDate','additional-information.multipleApplicants.describesYourSituationSpouse',
            'additional-information.multipleApplicants.spouseLactoseIntolerantDiet','additional-information.multipleApplicants.spouseLactoseIntolerantDiet.context',
            [o.labelParam?.name])];
        } else if (o.alternateValue.startsWith('Child')) {
          detailsQuestions = [...this.questionService.getAdditionalNutritionalNeedsDetailQuestions('additional-information.multipleApplicants.childPregnantDueDate','additional-information.multipleApplicants.describesYourSituationChild',
            'additional-information.multipleApplicants.childLactoseIntolerantDiet','additional-information.multipleApplicants.childLactoseIntolerantDiet.context',
            [o.labelParam.index, o.labelParam?.name])];
        }
        const applicantValue = appData.jsonData.pregnantOrBreastFeedingInFamilyList?.find(
          a => a.applicant === o.alternateValue);

        if (applicantValue) {
          if (applicantValue.pregnantCheckbox) {
            detailsQuestions[0].options[0].value = applicantValue.pregnantCheckbox[0] === 'yes';
          }
          if (applicantValue.breastFeedingCheckbox) {
            detailsQuestions[1].options[0].value = applicantValue.breastFeedingCheckbox === 'yes';  // second question is a space.
          }
          detailsQuestions[3].value = applicantValue.dueDate;
          detailsQuestions[4].value = applicantValue.lactoseIntolerantDiet;
        }
        const panelMap: Map<number, Question<string>[]> = new Map();
        panelMap.set(0, detailsQuestions);
        map.set(o.alternateValue, panelMap);
      });
    return map;
  }

  private convertFullTimeStudentQuestions(appData: ApplicationAnswers, questions: Question<string>[]) {
    this.setFieldValue('fullTimeStudentInFamily', appData, questions);
    const applicantsAbove16 = AdditionalInfoMappingUtil.getApplicantsAboveAgeThreshold(appData, 16);
    const panelQuestions = questions.find(
      question => question.key === 'fullTimeStudentInFamilyPanel').panels[0].questions;
    const panelQuestion = panelQuestions[0];
    if (applicantsAbove16.length > 0) {
      this.addApplicantOptions(appData, panelQuestions[0], appData.jsonData.fullTimeStudentInFamilyCheckbox)
      const filteredOptions: Choice[] = [];
      panelQuestions[0].options.forEach(option => {
        if (applicantsAbove16.includes(option.alternateValue)) {
          filteredOptions.push(option);
        }
      })
      panelQuestion.options = filteredOptions;

      addDynamicPanels('fullTimeStudentInFamily',
        'fullTimeStudentInFamilyCheckbox', 0,
        questions,
        panelQuestions,
        this.populateFullTimeStudentDetail(appData, questions),
        [], {showAddButton: false, showLineSeparator: true});
    }
  }

  private populateFullTimeStudentDetail(appData: ApplicationAnswers,
                                        questions: Question<string>[]): Map<string, Map<number, Question<string>[]>> {

    const map: Map<string, Map<number, Question<string>[]>> = new Map();
    const question = questions.find(q => q.key === 'fullTimeStudentInFamilyPanel');
    const qu = question.panels[0].questions[0];
    qu.options.forEach(o => {
      let detailsQuestions;
      if (o.alternateValue.startsWith('Applicant')) {
        detailsQuestions = [...this.questionService.getFullTimeStudentDetailQuestions(
          'additional-information.multipleApplicants.applicantSchool',
          [o.labelParam?.name])];
      } else if (o.alternateValue.startsWith('Spouse')) {
        detailsQuestions = [...this.questionService.getFullTimeStudentDetailQuestions(
          'additional-information.multipleApplicants.spouseSchool',
          [o.labelParam?.name])];
      } else if (o.alternateValue.startsWith('Child')) {
        detailsQuestions = [...this.questionService.getFullTimeStudentDetailQuestions(
          'additional-information.multipleApplicants.childSchool',
          [o.labelParam.index, o.labelParam?.name])];
      }
      const applicantValue = appData.jsonData.fullTimeStudentInFamilyList?.find(
        a => a.applicant === o.alternateValue);
      if (applicantValue) {
        detailsQuestions[0].value = applicantValue.schoolType;
      }
      const panelMap: Map<number, Question<string>[]> = new Map();
      panelMap.set(0, detailsQuestions);
      map.set(o.alternateValue, panelMap);
    });
    return map;
  }

  private setFieldValue(qKey: string, appData: ApplicationAnswers, questions: Question<string>[]): void {
    const question: Question<string> = questions.find(q => q.key === qKey)
    if (question.controlType === 'checkbox' && appData.jsonData[qKey] && appData.jsonData[qKey][0]) {
      question.options[0].value = appData.jsonData[qKey];
    } else if (appData.jsonData[qKey]) {
      question.value = appData.jsonData[qKey];
    }
  }
}

export function addDynamicPanels(parentQuestionKey: string, questionKey: string, index: number,
                                 questions: Question<string>[],
                                 panelQuestions: Question<string>[],
                                 newQuestions: Map<string, Map<number, Question<string>[]>>,
                                 additionalVisibleConditions: VisibleOnCondition[], panelConfig: Panel): void {

  const parentQuestion = questions.find(q => q.key === parentQuestionKey);
  const question = panelQuestions.find(q => q.key === questionKey);

  const panels: Question<string>[] = [];

  if (question?.options.length >= 1) {

    for (let i = 0; i < question.options.length; i++) {
      const option = question.options[i];
      const altValue = option.alternateValue;
      let childHeadingIndex = '0';

      if (altValue.includes('Child')) {
        const value = altValue.substr(0, altValue.indexOf(':'));
        childHeadingIndex = value.substr(value.length - 1);
      }
      const questionKeyPanel = questionKey.concat('Panel').concat(removeSpaces(option.alternateValue));

      const newPanelConfig:Panel = {...panelConfig}
      if (panelConfig.showLineSeparator && i !== 0) {
        newPanelConfig.showLineSeparator = true
      } else {
        newPanelConfig.showLineSeparator = false
      }

      const q = createDynamicPanel(questionKeyPanel,
        option.label.concat('.panel.heading'), parseInt(childHeadingIndex, 10), altValue,
        questionKey, i, option.labelParam.name, newQuestions.get(option.alternateValue), additionalVisibleConditions, newPanelConfig);
      q.displayInNewPanel = true;
      q.displayWithInnerCard = true;
      panels.push(q);

      // add disabled conditions for dynamic panels
      question.disableOthersOnCondition?.push(addDisableOthersOnCondition(questionKeyPanel, 'panel', [false], i));
      parentQuestion?.disableOthersOnCondition?.push(addDisableOthersOnCondition(questionKeyPanel, 'panel', [false], 0))
    }

    panelQuestions.splice(index + 1, 0, ...panels);
  }
}
