<div [formGroup]="form">
  <div class="card-container">
    <div class="ontario-radios">
      <div class="ontario-radios__item">
        <input class="ontario-radios__input" [formControlName]="controlName" [id]="inputId" [attr.aria-label]="programTypeContext"
               name="programType" type="radio" [value]="programType">
        <label class=" ontario-radios__label" [id]="labelId" [for]="inputId" [attr.aria-hidden]="true">
        </label>
      </div>
    </div>
    <div class="banner-img-container ontario-padding-right-24-!" *ngIf="displayImage">
      <img class="banner-img" [src]="imagePath" alt="{{ imageAlt | translate}}"/>
    </div>
    <div class="content-container">
      <div [id]="titleId" class="ontario-h5" [attr.aria-hidden]="true">{{titleContent | translate}}</div>
      <!--  If image should be displayed (recommended option or larger device) -->
      <div *ngIf="displayImage" class="list-content-with-img">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
  </div>
  <!--  If image should not be displayed (not a recommended option or smaller device)-->
  <div *ngIf="!displayImage; else contentforsmallmedia" class="ontario-padding-top-8-!">
    <ng-container *ngTemplateOutlet="content; context: {media:'large'}"></ng-container>
  </div>

  <ng-template #contentforsmallmedia>
    <div class="list-content-without-img ontario-padding-top-8-!">
      <ng-container *ngTemplateOutlet="content; context: {media:'small'}"></ng-container>
    </div>
  </ng-template>

  <ng-template #content let-media='media'>
    <ng-container [ngSwitch]="programType">
      <ng-container *ngSwitchCase="ProgramType.ONW">
        <ng-container *ngTemplateOutlet="onw; context: {media:media}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ProgramType.ODS">
        <ng-container *ngTemplateOutlet="ods; context: {media:media}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ProgramType.ONWODS">
        <ng-container *ngTemplateOutlet="onwods; context: {media:media}"></ng-container>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #onw let-media='media'>
    <ul id="onw-benefit" class="ontario-margin-left-24-!">
      <li id="onw-detail-item1">{{ 'program-recommendation.onw.detail.item1' | translate}}</li>
      <li id="onw-detail-item2">{{ 'program-recommendation.onw.detail.item2' | translate}}</li>
    </ul>
    <strong>{{'program-recommendation.onw.not-intended' | translate}}</strong>
    <sd-hint-text [id]="media+'.onw.hint'"
                  [text]="'program-recommendation.onw.hintText'"
                  [expandableContent]="'program-recommendation.onw.hintContent'"
                  [expandableContentParam]="ontarioWorksLinkParam">
    </sd-hint-text>
  </ng-template>
  <ng-template #ods let-media='media'>
    <ul id="ods-benefit" class="ontario-margin-left-24-!" type="1">
      <li id="ods-detail-item1">{{ 'program-recommendation.ods.detail.item1' | translate}}</li>
      <li id="ods-detail-item2">{{ 'program-recommendation.ods.detail.item2' | translate}}</li>
      <li id="ods-detail-item3">{{ 'program-recommendation.ods.detail.item3' | translate}}</li>
      <li id="ods-detail-item4"><span [innerHTML]="'program-recommendation.ods.detail.item4' | translate: odspAdjudicationExceptionsLinkParam"></span></li>
    </ul>
    <strong>{{'program-recommendation.ods.not-intended' | translate}}</strong>
    <sd-hint-text [id]="media+'.ods.hint'"
                  [text]="'program-recommendation.ods.hintText'"
                  [expandableContent]="'program-recommendation.ods.hintContent'"
                  [expandableContentParam]="applyForOdspLinkParam">
    </sd-hint-text>
  </ng-template>
  <ng-template #onwods let-media='media'>
    <ul id="onwods-benefit" class="ontario-margin-left-24-!" type="1">
      <li id="onwods-detail-item1">{{ 'program-recommendation.onwods.detail.item1' | translate}}</li>
      <li id="onwods-detail-item2">{{ 'program-recommendation.onwods.detail.item2' | translate}}</li>
      <li id="onwods-detail-item3"><span [innerHTML]="'program-recommendation.onwods.detail.item3' | translate"></span></li>
      <li id="onwods-detail-item4">{{ 'program-recommendation.onwods.detail.item4' | translate}}</li>
      <li id="onwods-detail-item5"><span [innerHTML]="'program-recommendation.onwods.detail.item5' | translate: odspAdjudicationExceptionsLinkParam"></span></li>
      <li id="onwods-detail-item6"><span [innerHTML]="'program-recommendation.onwods.detail.item6' | translate"></span></li>
    </ul>
    <strong>{{'program-recommendation.onwods.intended' | translate}}</strong>
    <sd-hint-text [id]="media+'.onwods.hint'"
                  [text]="'program-recommendation.onwods.hintText'"
                  [expandableContent]="'program-recommendation.onwods.hintContent'"
                  [expandableContentParam]="ontarioWorksLinkParam">
    </sd-hint-text>
  </ng-template>
</div>
