import {FormGroup} from '@angular/forms';
import {FormConverter} from 'src/app/common/utils/converters/converter';
import {Question} from 'src/app/common/utils/questions/question';
import {ApplicationAnswers} from 'src/app/models/data.model';
import {Injectable} from '@angular/core';

@Injectable()
export class FormToAdditionalInfoData implements FormConverter<FormGroup, ApplicationAnswers, Question<string>[]> {
  convert(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[]): void {
    this.setValueFromForm(form, appData, 'additionalCurrentlyResidingInAnInstitution');
    this.setValueFromPanelField(form, appData, 'additionalLivingInAnInstitutionPanel', 0, 'additionalLivingInAnInstitutionDropdown', 'additionalCurrentlyResidingInAnInstitution');
    this.setValueFromPanelField(form, appData, 'additionalLivingInAnInstitutionPanel', 0, 'additionalInstitutionName', 'additionalCurrentlyResidingInAnInstitution');
    this.setValueFromPanelField(form, appData, 'additionalLivingInAnInstitutionPanel', 0, 'additionalStayPartTimeOrFullTime', 'additionalCurrentlyResidingInAnInstitution');
    this.setValueFromForm(form, appData, 'additionalAccommodationServices');
    this.setCheckBoxValueAsStringFromPanelField(form, appData, 'accommodationServicesPanel', 0, 'visualCheckbox', 'additionalAccommodationServices');
    this.setCheckBoxValueAsStringFromPanelField(form, appData, 'accommodationServicesPanel', 0, 'hearingCheckbox', 'additionalAccommodationServices');
    this.setCheckBoxValueAsStringFromPanelField(form, appData, 'accommodationServicesPanel', 0, 'verbalCheckbox', 'additionalAccommodationServices');
    this.setCheckBoxValueAsStringFromPanelField(form, appData, 'accommodationServicesPanel', 0, 'communicationCheckbox', 'additionalAccommodationServices');
    this.setCheckBoxValueAsStringFromPanelField(form, appData, 'accommodationServicesPanel', 0, 'mobilityCheckbox', 'additionalAccommodationServices');
    this.setValueFromPanelField(form, appData, 'accommodationServicesPanel', 0, 'visualDescription', 'additionalAccommodationServices');
    this.setValueFromPanelField(form, appData, 'accommodationServicesPanel', 0, 'hearingDescription', 'additionalAccommodationServices');
    this.setValueFromPanelField(form, appData, 'accommodationServicesPanel', 0, 'verbalDescription', 'additionalAccommodationServices');
    this.setValueFromPanelField(form, appData, 'accommodationServicesPanel', 0, 'communicationDescription', 'additionalAccommodationServices');
    this.setValueFromPanelField(form, appData, 'accommodationServicesPanel', 0, 'mobilityDescription', 'additionalAccommodationServices');
    this.setValueFromForm(form, appData, 'additionalSpecialDietOrMedicalCondition');
    this.setValueFromForm(form, appData, 'additionalFullTimeStudent');
    this.setValueFromPanelField(form, appData, 'additionalSchoolType', 0, 'additionalSchoolType', 'additionalFullTimeStudent');
    this.setValueFromForm(form, appData, 'additionalCaringForChild');
    this.setValueFromForm(form, appData, 'personWithDisability');
    this.setValueFromForm(form, appData, 'receivingACSD');
    this.setValueFromPanelField(form, appData, 'disabilityProgramQuestionsPanel',0,'developmentServicesOntario','personWithDisability');
    this.setValueFromForm(form, appData, 'additionalNutritionalNeeds');
    this.setCheckBoxValueFromPanelField(form, appData, 'additionalNutritionalNeedsPanel', 0, 'pregnantCheckbox', 'additionalNutritionalNeeds');
    this.setValueFromPanelField(form, appData, 'additionalNutritionalNeedsPanel', 0, 'dueDate', 'additionalNutritionalNeeds');
    this.setCheckBoxValueAsStringFromPanelField(form, appData, 'additionalNutritionalNeedsPanel', 0, 'breastFeedingCheckbox', 'additionalNutritionalNeeds');
    this.setValueFromPanelField(form, appData, 'additionalNutritionalNeedsPanel', 0, 'lactoseIntolerantDiet', 'additionalNutritionalNeeds');
  }

  setValueFromPanelField(form: FormGroup, appData: ApplicationAnswers, panelName: string, index: number,
                         fieldName: string, parentQuestion: string) {
    if (form.get(parentQuestion).value === 'yes' && form.get(panelName)?.value?.panels[index][fieldName]) {
      appData.jsonData[fieldName] = form.get(panelName)?.value?.panels[index][fieldName];
    } else {
      delete appData.jsonData[fieldName];
    }
  }

  setCheckBoxValueFromPanelField(form: FormGroup, appData: ApplicationAnswers, panelName: string, index: number,
                                 fieldName: string, parentQuestion: string) {
    if (form.get(parentQuestion).value === 'yes' && form.get(panelName)?.value?.panels[index][fieldName][0]) {
      appData.jsonData[fieldName] = ['yes'];
    } else {
      delete appData.jsonData[fieldName];
    }
  }

  setCheckBoxValueAsStringFromPanelField(form: FormGroup, appData: ApplicationAnswers, panelName: string, index: number,
                                         fieldName: string, parentQuestion: string) {
    if (form.get(parentQuestion).value === 'yes' && form.get(panelName)?.value?.panels[index][fieldName][0]) {
      appData.jsonData[fieldName] = 'yes';
    } else {
      delete appData.jsonData[fieldName];
    }
  }

  setValueFromForm(form: FormGroup, appData: ApplicationAnswers, fieldName: string, parentQuestion?: string) {
    if ((parentQuestion && form.get(parentQuestion).value === 'yes' || !parentQuestion) && form.get(fieldName)?.value) {
      appData.jsonData[fieldName] = form.get(fieldName).value;
    } else {
      delete appData.jsonData[fieldName];
    }
  }
}
