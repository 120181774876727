import {Injectable} from '@angular/core';
import {ReapplicationService} from './reapplication.service';
import {ProgramType} from '../models/program-type';

@Injectable({
  providedIn: 'root'
})
export class ReapplicationServiceImpl implements ReapplicationService {

  private reapplication = false;

  private programType: ProgramType;

  constructor() {
  }

  isReapplication(): boolean {
    return this.reapplication;
  }

  setIsReapplication(isReapplication: boolean) {
    this.reapplication = isReapplication;
  }

  reapplicationProgramType(): ProgramType {
    return this.programType;
  }

  setProgramType(programType: ProgramType) {
    this.programType = programType;
  }
}
