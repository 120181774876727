import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PageInfo} from '../models/page-map';
import {IntakeService} from '../services/intake.service';

import {AuthService} from '../services/auth.service';
import {ReapplicationService} from '../services/reapplication.service';

@Component({
  selector: 'app-mccss-save-continue-button',
  templateUrl: './mccss-save-continue-button.component.html',
  styleUrls: ['./mccss-save-continue-button.component.scss']
})
export class MccssSaveContinueButtonComponent implements OnInit {
  isPageAllowedToSave = false;


  @Input()
  pageId: string;
  @Input()
  buttonLabel: string;
  @Input()
  hidden: boolean;
  @Input()
  disabled: boolean;
  @Output()
  handlePrimaryButtonClick = new EventEmitter<string>();
  @Output()
  handleSecondaryButtonClick = new EventEmitter<string>();

  constructor(private route: ActivatedRoute, public authService: AuthService, public intake: IntakeService,
              private reapplicationService: ReapplicationService) {
    this.route.data?.subscribe((value) => (this.isPageAllowedToSave = (value?.allowToSave)));
  }

  ngOnInit(): void {

    this.setIsAllowedToSave();
  }

  onHandlePrimaryButton() {
    this.handlePrimaryButtonClick.emit();
  }
  onHandleSecondaryButton() {
    this.handleSecondaryButtonClick.emit();
  }

  private setIsAllowedToSave(){

    const isLoggedInPS: boolean = this.authService.isAuthorizedToSave();    // Logged in through public secure / myb flow
    const isReapplication: boolean = isLoggedInPS && this.reapplicationService.isReapplication();
    const pagesNotEligibleToSaveDuringReapplication = [PageInfo.bya,PageInfo.personalInfo,PageInfo.additionalInfo];
    const pagesNotEligibleForSaving = [PageInfo.bya,PageInfo.personalInfo];

    if (this.pageId && pagesNotEligibleForSaving.includes(this.pageId) && !isLoggedInPS) {
      this.isPageAllowedToSave = false;
    }

    if (this.pageId && pagesNotEligibleToSaveDuringReapplication.includes(this.pageId) && isReapplication) {
      this.isPageAllowedToSave = false;
    }

    if (this.isPageAllowedToSave && this.intake.getIsApplyingForSomeoneElse()) {
      this.isPageAllowedToSave = false;
    }
  }
}
