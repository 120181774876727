<sd-page-container [pageId]="pageId">
  <div class="ontario-form-group ontario-margin-bottom-40-!">
    <div class="ontario-margin-bottom-24-!">
      <div class="referenceCodeText">
        <div id="reference-number-label" class="ontario-margin-top-8-! ontario-h7">{{'app-status.results.referenceNumber' | translate}}
          <span id="reference-number-text" class="referenceCode">{{referenceCode}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="showResult && myBCalloutMessageFlag" class="ontario-alert ontario-alert&#45;&#45;informational myB-info">
      <div class="ontario-alert__header">
        <h2 id="myB-info-header" class="ontario-alert__header-title ontario-h4"
            [innerHTML]="'app-status.results.myBCalloutHeader' | translate">
        </h2>
      </div>
      <div>
        <p id="myB-info-content" [innerHTML]="'app-status.results.myBCalloutBody' | translate: {messageParam: alertWarningParam}"></p>
      </div>
    </div>
    <div *ngIf="showResult && statusType != AscProgressStatusType.ONW; else normalDivider" class="thin-divider" aria-hidden="true"></div>
    <ng-template #normalDivider><hr class="divider" aria-hidden="true"/></ng-template>

    <div *ngIf="!showResult && alertWarningCode" class="ontario-margin-top-40-! ontario-margin-bottom-40-!">
      <div class="ontario-alert__header">
        <div class="ontario-alert__header-icon">
          <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
               viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" id="alert-img">
            <use href="#ontario-icon-alert-information"></use>
          </svg>
        </div>
        <h2 class="ontario-alert__header-title ontario-h4" id="alert-title">
          <span [innerHTML]="'app-status.results.alert.'.concat(alertWarningCode).concat('.title') | translate"></span>
        </h2>
      </div>
      <div class="ontario-alert__body sm-custom-body" >
        <p id="alert-banner-content" [innerHTML]="'app-status.results.alert.'.concat(alertWarningCode).concat('.text')  | translate: {messageParam: alertWarningParam}"></p>
      </div>
    </div>

    <div *ngIf="showResult && statusType === AscProgressStatusType.ODS">
      <sd-ods-asc-status [applicationStatus]="applicationProgramStatus"></sd-ods-asc-status>
    </div>
    <div *ngIf="showResult && statusType === AscProgressStatusType.ONW">
      <sd-onw-asc-status [applicationStatus]="applicationProgramStatus"></sd-onw-asc-status>
    </div>
    <div *ngIf="showResult && statusType === AscProgressStatusType.ONWODS">
      <sd-multi-programs-asc-status [applicationStatus]="applicationProgramStatus"></sd-multi-programs-asc-status>
    </div>
    <div *ngIf="showResult && (statusType === AscProgressStatusType.ONW || statusType === AscProgressStatusType.ONWODS)" class="ontario-alert ontario-alert&#45;&#45;informational lookingForJob-info">
      <div class="ontario-alert__header">
        <h2 id="lookingForJob-info-header" class="ontario-alert__header-title ontario-h4"
            [innerHTML]="'app-status.results.lookingForaJobHeader' | translate">
        </h2>
      </div>
      <div *ngIf="showResult && (statusType === AscProgressStatusType.ONW)">
        <p id="lookingForJob-info-content_ONW" [innerHTML]="'app-status.results.lookingForaJobBody' | translate: {messageParam: employmentCounsellorLinkParam}"></p>
      </div>
      <div *ngIf="showResult && (statusType === AscProgressStatusType.ONWODS)">
        <p id="lookingForJob-info-content_ONWODS" [innerHTML]="'app-status.results.lookingForJobDescription' | translate: {messageParam: employmentCounsellorLinkParam}"></p>
      </div>
    </div>
    <div class="ontario-margin-top-40-! ontario-margin-bottom-40-!">
      <div *ngIf="contactInfoToDisplay == 'ODS_DAU';else generalContactHeader" id="question-about-status-label-ods-dau" class="ontario-h5 no-max-width">
        {{'app-status.results.questionAboutStatus.ODS_DAU' | translate}}
      </div>
      <ng-template #generalContactHeader>
        <div id="question-about-status-label-general" class="ontario-h5 no-max-width">
          {{'app-status.results.questionAboutStatus.general' | translate}}
        </div>
      </ng-template>
    </div>
    <div [ngSwitch]="contactInfoToDisplay" id="contact-information-container" class=" ontario-margin-top-40-!">
      <div *ngSwitchCase="'GENERAL'" id="general-contact-information" class=" ontario-margin-top-40-!">
        <svg class="ontario-icon contact-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
          <use href="#ontario-icon-phone"></use>
        </svg>
        <span>{{ 'app-status.results.contactPhoneNumber.label' | translate}}</span><a href=tel:18889991142 target="_blank">{{'app-status.results.contactPhoneNumber.general' | translate}}</a>
        <div class="contact-info">
          <div>{{ 'app-status.results.contactCallCentres' | translate}}</div>
          <div>{{ 'app-status.results.contactOperatingDays' | translate}}</div>
          <div>{{ 'app-status.results.contactOperatingHours' | translate}}</div>
        </div>
        <svg class="ontario-icon contact-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
          <use href="#ontario-icon-tty"></use>
        </svg>
        <span>{{ 'app-status.results.contactTTY.label' | translate}}</span><a href=tel:18003875559 target="_blank">{{'app-status.results.contactTTY.general' | translate}}</a>
      </div>
      <div *ngSwitchCase="'ODS_DAU'" id="ods-dau-contact-information" class=" ontario-margin-top-40-!">
        <svg class="ontario-icon contact-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
          <use href="#ontario-icon-phone"></use>
        </svg>
        <span>{{ 'app-status.results.contactPhoneNumber.label' | translate}}</span><a href=tel:18882566758 target="_blank">{{'app-status.results.contactPhoneNumber.ODS_DAU' | translate}}</a>
        <div class="contact-info">
          <div>{{ 'app-status.results.contactOperatingDays' | translate}}</div>
          <div>{{ 'app-status.results.contactOperatingHours' | translate}}</div>
        </div>
        <svg class="ontario-icon contact-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
          <use href="#ontario-icon-tty"></use>
        </svg>
        <span>{{ 'app-status.results.contactTTY.label' | translate}}</span><a href=tel:18667806050 target="_blank">{{'app-status.results.contactTTY.ODS_DAU' | translate}}</a>
      </div>
    </div>
  </div>

  <app-mccss-submit-button buttonId="home-button" dataE2E="continueBtn" buttonLabel="btn.back.to.home.page"
                           [preventDoubleClick]="false" (handleClick)="onSubmit()"></app-mccss-submit-button>
</sd-page-container>
