import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectorRef, Injectable, Injector} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {InvisibleReCaptchaComponent} from 'ngx-captcha';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleReCaptchaV2Service {
  /**
   * Enabling ReCaptcha indicator
   */
  public enableRecaptcha = false;
  /**
   * Recaptcha site key
   */
  public siteKey: string = null;
  /**
   * ReCaptcha language, need to revisit after surveyjs is gone
   */
  public lang = this.translate.currentLang;
  /**
   * Indicates if captcha is loaded
   */
  public captchaIsLoaded = false;
  /**
   * Indicates if user completed reCaptcha successfully
   */
  public captchaSuccess = undefined;
  /**
   * Indicates if the reCaptcha expired
   */
  public captchaIsExpired = false;
  /**
   * Holds reCaptcha response from google
   */
  public captchaResponse?: string;

  public reCaptchaVerifyCallFailure = false;

  public useGlobalDomain: boolean;
  public scheme: 'dark' | 'light';
  public size: 'compact' | 'normal'
  public type: 'audio' | 'image';

  /**
   * IMPORTANT: this reference MUST be set from component by
   * calling setChangeDetectorRef(cdr: ChangeDetectorRef) method
   */
  public cdr: ChangeDetectorRef = null;
  /**
   * recaptcha v2 (ReCaptcha2Component) component reference
   */
  private captchaElem: InvisibleReCaptchaComponent = null;

  constructor(
    private injector: Injector,
    private translate: TranslateService,
    private configService: ConfigService,
    public http: HttpClient
  ) {
    // console.log(this.configService.getClientConfig());
    this.enableRecaptcha = this.configService.getClientConfig()?.recaptchaEnabled;
    this.useGlobalDomain = this.configService.getClientConfig()?.recaptchaUseGlobalDomain;
    this.scheme = this.configService.getClientConfig()?.recaptchaScheme;
    this.size = this.configService.getClientConfig()?.recaptchaSize;
    this.type= this.configService.getClientConfig()?.recaptchaType;
    this.siteKey = this.configService.getClientConfig()?.recaptchaSiteKey;
    // set initial language, need to revisit after surveyjs is gone
    this.lang = this.translate.currentLang ? this.translate.currentLang : 'en';
  }

  private static handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred during google recaptcha verification:', error.error.message);
    } else {
      console.error(
        `google recaptcha verification returned code ${error.status}, ` +
        `google recaptcha verification body was: ${error.statusText}`
      );
    }
    return of(null);
  }

  /**
   * Updates language of google recaptcha by swapping the hl tag within the iframe
   */

  public changeLanguage(captchaElem: InvisibleReCaptchaComponent) {
    const url =  document.getElementById(captchaElem.captchaElemId).getElementsByTagName('iframe')[0].getAttribute('src');
    // tslint:disable-next-line:max-line-length
    document.getElementById(captchaElem.captchaElemId).getElementsByTagName('iframe')[0].setAttribute('src', url.replace(/hl=(.*?)&/,'hl='+this.translate.currentLang + '&'))
  }

  /**
   * Helper method for developers who forget to call setChangeDetectorRef() method.
   * Throws error and provides implementation instructions.
   */
  private handleMissingChangeDetectorRef(): void {
      if (this.cdr === null) {
      const msg = `ChangeDetectorRef not passed to GoogleReCaptchaV2Service.
        Call GoogleReCaptchaV2Service.setChangeDetectorRef(cdr: ChangeDetectorRef) from
        component that implements google recaptcha service.
        To obtain component's ChangeDetectorRef, inject it to constructor of the implementing
        component, ex: constructor(private cdr: ChangeDetectorRef)`;
      throw new Error(msg);
    }
  }

  /**
   * Run detect changes using ChangeDetectorRef
   */
  private detectChanges(): void {
    this.handleMissingChangeDetectorRef();
    this.cdr.detectChanges();
  }

  /**
   * Must call this from component in order to pass component's ChangeDetectorRef
   */
  public setChangeDetectorRef(cdr: ChangeDetectorRef): void {
    this.cdr = cdr;
  }

  /**
   * Sets reference to recaptcha v2 (ReCaptcha2Component) component.
   * This is needed to be set if you need to programmatically reload, reset,
   * or get captcha ID programmatically.
   * @param captchaElem reference is passed by implementing component
   */
  public setCaptchaElementRef(captchaElem: InvisibleReCaptchaComponent): void {
    this.captchaElem = captchaElem;
  }

  /**
   * Handle captcha reset event. Assign this method to reCaptcha's (reset) event
   */
  public handleReset(): void {
    this.captchaSuccess = undefined;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.reCaptchaVerifyCallFailure = false;
    this.detectChanges();
  }

  /**
   * Handle captcha success event. Assign this method to captcha's (success) event
   */
  public handleSuccess(captchaResponse: string): Observable<any> {
    this.captchaResponse = captchaResponse;
    // console.log(`invisible reCAPTCHA v2 client-side response token: ` + captchaResponse);

    // return recaptcha validation call as observable
    // subscribe at page-level for logic based on response
    return this.validateRecaptcha(captchaResponse).pipe(
      map(resp => {
        // console.log(`invisible reCAPTCHA v2 Google API response:`);
        // console.log(resp);
        this.captchaIsExpired = false;
        this.reCaptchaVerifyCallFailure = resp === null;
        this.captchaSuccess = resp === null ? undefined : resp.success;
        this.detectChanges();
      })
    );
  }

  /**
   * Handle captcha load event. Assign this method to captcha's (load) event
   */
  public handleLoad(): void {
    this.captchaIsLoaded = true;
    console.log('invisible reCAPTCHA v2: loaded');
    this.captchaIsExpired = false;
    this.reCaptchaVerifyCallFailure = false;
    this.detectChanges();
  }

  /**
   * Handle captcha expire event. Assign this method to captcha's (expire) event
   */
  public handleExpire(): void {
    this.captchaSuccess = undefined;
    console.log('invisible reCAPTCHA v2: token expired');
    this.captchaIsExpired = true;
    this.reCaptchaVerifyCallFailure = false;
    this.detectChanges();
  }

  public validateRecaptcha(payload: string): Observable<any> {
    // console.log('invisible reCAPTCHA v2: validating...');
    return this.http.post<any>(
      `/v1/recaptcha/verify/application`,
      {payload},
      {withCredentials: true})
      .pipe(
      map(r => {
        return r;
      }),
      catchError((o: HttpErrorResponse) => {
        this.reCaptchaVerifyCallFailure = true
        return GoogleReCaptchaV2Service.handleError(o)
      })
    );
  }

  public validateAppStatusRecaptcha(payload: string): Observable<any> {
    return this.http.post<any>(
      `/v1/recaptcha/verify/app-status`,
      {payload},
      {withCredentials: true})
      .pipe(
        map(r => {
          return r;
        }),
        catchError((o: HttpErrorResponse) => {
          this.reCaptchaVerifyCallFailure = true
          return GoogleReCaptchaV2Service.handleError(o)
        })
      );
  }

  /**
   * Handle Application Status Checker captcha success event. Assign this method to captcha's (success) event
   */
  public handleAppStatusRecaptchaSuccess(captchaResponse: string): Observable<any> {
    this.captchaResponse = captchaResponse;
    // console.log(`invisible reCAPTCHA v2 client-side response token: ` + captchaResponse);
    // return recaptcha validation call as observable
    // subscribe at page-level for logic based on response
    return this.validateAppStatusRecaptcha(captchaResponse).pipe(
      map(resp => {
        // console.log(`invisible reCAPTCHA v2 Google API response:`);
        // console.log(resp);
        this.captchaIsExpired = false;
        this.reCaptchaVerifyCallFailure = resp === null;
        this.captchaSuccess = resp === null ? undefined : resp.success;
        this.detectChanges();
      })
    );
  }
}
