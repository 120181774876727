import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {PageBaseComponent} from '../PageBaseComponent';
import {ActivatedRoute, Router} from '@angular/router';
import {IntakeService} from '../../services/intake.service';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ExternalRouter} from '../../external.router';
import {ConfigService} from '../../services/config.service';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {PageInfo} from '../../models/page-map';
import {FormBuilder} from '@angular/forms';
import {ReapplicationService} from '../../services/reapplication.service';
import { MccssAddressSearchStore } from '@mccss/pclookup-common-ui';

@Component({
  selector: 'sd-re-application',
  templateUrl: './re-application.component.html',
  styleUrls: ['./re-application.component.scss']
})
export class ReApplicationComponent extends PageBaseComponent implements OnInit, OnDestroy {

  RE_APPLY = 'RE_APPLY';
  NEW_APP = 'NEW_APP';

  constructor(private router: Router,
              public route: ActivatedRoute,
              public intakeService: IntakeService,
              public translator: TranslateService,
              private translate: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService,
              public formBuilder: FormBuilder,
              private reapplicationService: ReapplicationService,
              private addressStore: MccssAddressSearchStore) {
    super(intakeService, translator, ngZone, idle, dialog, route, externalRouter, configService, authService,
      pageService,
      loadingSpinnerService);
    this.pageId = PageInfo.reApplication;
    this.form = this.formBuilder.group({
      ['reapplyOption']: this.RE_APPLY
    });
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    super.onDestroy();
  }

  postInitializeForm(): void {
  }

  preSaveApplication(): void {
  }

  get selectedOption() {
    return this.form.get('reapplyOption')?.value;
  }

  get isReapplication() {
    return this.selectedOption === this.RE_APPLY
  }

  get isNewApplication() {
    return this.selectedOption === this.NEW_APP
  }

  onSubmit(toContinue?: boolean): void {
    this.reapplicationService.setIsReapplication(this.isReapplication);
    this.addressStore.setShowReapplicationTitle(this.isReapplication);
    this.applicationAnswers.jsonData.isReapplication = this.isReapplication ? 'yes' : 'no';
    this.saveToIntake()
      .subscribe(() => {
        this.router.navigate(this.pageService.findRouteUrl(PageInfo.personalInfo), {queryParams: {action: 'resume'}});
      });
  }
}
