<sd-page-container [pageId]="pageId" [showResumeBanner]="showResumeBanner" [showPsRedirectError]="showPsRedirectError"
                   [showBackButton]=false>
  <div class="ontario-card__container">
    <form [formGroup]="form">
      <div id="reapplyCard" class="ontario-card ontario-card--cards-per-row-1"
           [ngClass]="{'ontario-card__active': isReapplication}">
        <div class="ontario-bg-dark-purple recommendation-header">
          <p class="ontario-application-subheader__heading">
            {{'re-application.onw.subHeader' | translate}}
          </p>
        </div>
        <div class="ontario-card__description">
          <div class="card-container">
            <div class="ontario-radios">
              <div class="ontario-radios__item">
                <input class="ontario-radios__input" formControlName="reapplyOption" id="reapply"
                       name="reapplyOption" type="radio" [value]="RE_APPLY">
                <label class=" ontario-radios__label" id="reapplyLabel" for="reapply" [attr.aria-hidden]="true">
                </label>
              </div>
            </div>
            <div class="content-container">
              <div id="reapplyContent" class="ontario-h5"><strong>{{'re-application.onw.radio' | translate}}</strong></div>
              <div class="ontario-padding-top-8-!">{{'re-application.onw.description' | translate}}</div>
              <div class="ontario-padding-top-16-!">
                <ul id="onw-detail" class="ontario-margin-left-24-!">
                  <li id="onw-detail-item1"><span [innerHTML]="'re-application.onw.item1' | translate"></span></li>
                  <li id="onw-detail-item2"><span [innerHTML]="'re-application.onw.item2' | translate"></span></li>
                  <li id="onw-detail-item3"><span [innerHTML]="'re-application.onw.item3' | translate"></span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
          class="ontario-padding-top-16-! ontario-padding-bottom-16-!">{{ 're-application.newApp.intro' | translate}}</div>

      <div id="newApplicationCard" class="ontario-card ontario-card--cards-per-row-1 ontario-padding-top-8-!"
           [ngClass]="{'ontario-card__active': isNewApplication}">
        <div class="ontario-card__description">
          <div class="card-container">
            <div class="ontario-radios">
              <div class="ontario-radios__item">
                <input class="ontario-radios__input" formControlName="reapplyOption" id="newApplication"
                       name="reapplyOption" type="radio" [value]="NEW_APP">
                <label class=" ontario-radios__label" id="newApplicationLabel" for="reapply" [attr.aria-hidden]="true">
                </label>
              </div>
            </div>
            <div class="content-container">
              <div id="newApplicationContent"
                   class="ontario-h5">{{ 're-application.newApp.description' | translate}}</div>
              <div class="ontario-padding-top-8-!">
                <ul id="onw-benefit1" class="ontario-margin-left-24-!">
                  <li id="new-app-detail-item1"><span [innerHTML]="'re-application.newApp.item1' | translate"></span>
                  </li>
                  <li id="new-app-detail-item2"><span [innerHTML]="'re-application.newApp.item2' | translate"></span>
                  </li>
                  <li id="new-app-detail-item3"><span [innerHTML]="'re-application.newApp.item3' | translate"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <app-mccss-save-continue-button [pageId]="pageId"
                                  dataE2E="continueBtn"
                                  (handlePrimaryButtonClick)="onSubmit(true)"
                                  (handleSecondaryButtonClick)="onSubmit(false)">
  </app-mccss-save-continue-button>
</sd-page-container>
