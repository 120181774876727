import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import {catchError, concatMap, delay, retryWhen, tap} from 'rxjs/operators';
import {SadaErrorCodes} from '../utils/sada-error-codes';
import {AppStateService} from '../services/app-state.service';
import {InitializeService} from '../services/initialize.service';
import {AuthService} from '../services/auth.service';
import {PageInfo} from '../models/page-map';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly maxRetry = 3;

  constructor(private router: Router, private appStateService: AppStateService, private initializeService: InitializeService,
              private authService: AuthService) {
  }

  private handleAuthError(err: HttpErrorResponse): Observable<HttpEvent<HttpErrorResponse> | never> {
    if (err.status === 401) {
      this.router.navigateByUrl('session-timeout');
      // if you've caught / handled the error,
      // you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return EMPTY;
    }
    return throwError(err);
  }

  private handleAuthTokenError(err: HttpErrorResponse): Observable<HttpEvent<HttpErrorResponse> | never> {
    // AUTHEC09 and AUTHEC12 are possible only when the auth data in current tab is out of sync with backend. This can happen if
    // a new tab was opened with new auth info (new login/ login confirmation) or no auth info(un authenticated).
    if (err.status === 400 && (SadaErrorCodes.AUTHEC09 === err.error.errorCode || SadaErrorCodes.AUTHEC12 === err.error.errorCode)) {
      // Initialize syncs up the auth info
      this.initializeService.initialize().subscribe(() => {
        this.router.navigate(['/', 'intake', PageInfo.home], {queryParams: {errorCode: SadaErrorCodes.AUTHEC09}})
      });
      return EMPTY;
    } else if (err.status === 400 && SadaErrorCodes.AUTHEC16 === err.error.errorCode) { //  or should I completely remove this?
      this.authService.resetAuthValues();
      this.router.navigate(['/', PageInfo.globalError], {queryParams: {errorCode: SadaErrorCodes.AUTHEC16}})
      return EMPTY;
    } else if (err.status === 400 && SadaErrorCodes.EC0101 === err.error.errorCode) {
      return EMPTY;
    }
    return throwError(err);
  }

  private handleDuplicateApplicationSubmissionError(err: HttpErrorResponse): Observable<HttpEvent<HttpErrorResponse> | never> {
    if (err.status === 409 && SadaErrorCodes.EC0001 === err.error.errorCode) {
      this.router.navigate(['/', 'error-page-with-ref-number'], {replaceUrl: true});
      return EMPTY;
    }
    return throwError(err);
  }

  // tslint:disable-next-line:max-line-length
  private handleUnknownErrorForSavingApplication(src: Observable<HttpEvent<HttpErrorResponse>>, method: string, url: string): Observable<HttpEvent<HttpErrorResponse>>{
    return src.pipe(
      retryWhen(error =>
        error.pipe(
          concatMap((err, count) => {
            if(err.status === 0 && this.isForSavingApplication(method, url)){
              if (count < this.maxRetry) {
                return of(err);
              }
              if(count === this.maxRetry){
                // display error box
                this.appStateService.emitState({hasHttpError: true});
                return EMPTY;
              }
            }
            return throwError(err);
          }),
          delay(1000) // retry wait milliseconds
        )
      )
    )
  }

  private handleServerErrorForSavingApplication(err: HttpErrorResponse, method: string, url: string): Observable<HttpEvent<HttpErrorResponse>> {

    if(err.status > 499 && this.isForSavingApplication(method, url) ) {
      this.appStateService.emitState({hasHttpError: true});
      return EMPTY;
    }
    return throwError(err);
  }

  private handlePreconditionFailedError(err: HttpErrorResponse): Observable<HttpEvent<HttpErrorResponse>> {

    if (err.status === 412) {
      // Initialize syncs up the auth info
      this.initializeService.initialize().subscribe(() => {
        this.router.navigate(['/', 'intake', PageInfo.home], {onSameUrlNavigation: 'reload'})
      });
      return EMPTY;
    }
    return throwError(err);
  }

  private isForSavingApplication(method: string, url: string) {
    return (url.indexOf('/application/') > 0 && method === 'PUT') ||
      (url.indexOf('/update-program-type') > 0 && method === 'PUT');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators

    return next.handle(req)
      .pipe(
        tap(() => this.appStateService.emitState({hasHttpError: false})),
        (source) => this.handleUnknownErrorForSavingApplication(source, req.method, req.url),
        catchError(err => this.handleServerErrorForSavingApplication(err, req.method, req.url)),
        catchError(err => this.handlePreconditionFailedError(err)),
        catchError(x => this.handleAuthTokenError(x)),
        catchError(x => this.handleAuthError(x)),
        catchError(x => this.handleDuplicateApplicationSubmissionError(x)))
  }
}
