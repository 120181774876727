import {AppDataToFamilyInfoQuestions} from './converters/app-data-to-family-info-questions';
import {AppDataToAdditionalInfoQuestions} from './converters/app-data-to-additional-info-questions';
import {FormToAdditionalInfoData} from './converters/form-to-additional-info-data';
import {FormToFamilyInformationData} from './converters/form-to-family-information-data';
import {Question} from '../../common/utils/questions/question';
import {ApplicationAnswers} from '../../models/data.model';
import {FormGroup} from '@angular/forms';
import {Injectable} from '@angular/core';
import {enableReceivingACSDFamilyFn} from './family-information-question.service';

@Injectable()
export class AdditionalInformationMapper {

  constructor(private appDataToFamilyInfoConverter: AppDataToFamilyInfoQuestions,
              private appDataToAdditionalInfoConverter: AppDataToAdditionalInfoQuestions,
              private formToAdditionalInfoDataConverter: FormToAdditionalInfoData,
              private formToFamilyInfoConverter: FormToFamilyInformationData
  ) {}

  mapAppDataToQuestions(appData: ApplicationAnswers, questions: Question<string>[], isSingle: boolean, isODSPResubmissionAllowed: boolean): void {
    this.preSelectSocialAssistanceQuestion(appData, isSingle);
    this.preSelectDisabilityQuestion(appData, isSingle);
    this.preSelectMoneyForImmediateNeedQuestion(appData, isSingle);
    this.preSelectIncarcerationQuestion(appData, isSingle);
    this.dataCheckOnACSDQuestion(appData, isSingle);
    if (isSingle) {
      this.appDataToAdditionalInfoConverter.convert(appData, questions);
    } else {
      this.appDataToFamilyInfoConverter.convert(appData, questions, isODSPResubmissionAllowed);
    }
  }

  mapFormValuesToAppData(form: FormGroup, appData: ApplicationAnswers, questions: Question<string>[], isSingle: boolean) {
    if (isSingle) {
      this.formToAdditionalInfoDataConverter.convert(form, appData, questions);
      this.deleteFamilyApplicantData(appData);
    } else {
      this.formToFamilyInfoConverter.convert(form, appData, questions);
      this.deleteSingleApplicantData(appData);
    }

    this.updateEarnedIncomeDisabilityWorkExpenseData(appData);
    this.updateBeforeYouStart2SocialAssistanceData(appData, isSingle);
    this.updateBeforeYouStart2IncarcerationData(appData, isSingle);
    this.updateBeforeYouStart2Disability(appData, isSingle);
    this.updateBeforeYouStart2MoneyForImmNeed(appData, isSingle);
  }

  /**
   * Preselect social assistance question based on user selection from Before you start 2 page
   */
  private preSelectSocialAssistanceQuestion(appData: ApplicationAnswers, isSingle: boolean) {
    const data = appData.jsonData;
    const applicantValue = 'Applicant: ' + data.firstName;
      if (isSingle) {
        data.additionalReceivedSocialAssistanceInPast = data.receivedSocialAssistanceInPast;
        if(data.receivedSocialAssistanceInPast === 'yes') {
          data.additionalApplicationDate = data.dateOfSocialAssistanceInPast;
          data.additionalReceivedSocialAssistanceProgram = data.receivedSocialAssistanceProgram;
          if (data.memberID) {
            data.additionalMemberID = data.memberID;
          }
        }
      } else if (data.receivedSocialAssistanceInPast === 'yes') {
        const socialAssistanceRecipients: any[] = data.socialAssistanceRecipients ? data.socialAssistanceRecipients : [];
        if (!socialAssistanceRecipients.includes(applicantValue)) {
          data.familyReceivedSocialAssistanceInPast = 'yes';
          data.socialAssistanceRecipients = [applicantValue, ...socialAssistanceRecipients];
        }

        this.updateAssistanceDetail(appData, applicantValue);
      }
     else {
      if (data.additionalReceivedSocialAssistanceInPast) {
        data.additionalReceivedSocialAssistanceInPast = 'no';
      }

      if (data.familyReceivedSocialAssistanceInPast && !!data.socialAssistanceRecipients?.find(recipient => recipient === applicantValue)) {
        // Remove the applicant from recipient list
        data.socialAssistanceRecipients.splice(
          data.socialAssistanceRecipients.findIndex(recipient => recipient === applicantValue), 1);

        // Remove the detail
        data.socialAssistanceDetailsList?.splice(
          data.socialAssistanceDetailsList?.findIndex(detail => detail.applicant === applicantValue), 1);
      }
    }
  }

  private updateAssistanceDetail(appData: ApplicationAnswers, applicantValue: string) {
    const jsonData = appData.jsonData; // From question on before you start 2
    const memberId = jsonData.memberID; // From question on before you start 2
    const receivedSocialAssistanceProgramName = jsonData.receivedSocialAssistanceProgram;
    const dateOfSocialAssistanceInPast = jsonData.dateOfSocialAssistanceInPast;
    const socialAssistanceDetailsQuestion = jsonData.socialAssistanceDetailsList;
    const socialAssistanceDetailsList: any[] = socialAssistanceDetailsQuestion ? socialAssistanceDetailsQuestion : [];
    const applicantAssistanceDetail = socialAssistanceDetailsList.find(v => v.applicant === applicantValue);
    if (applicantAssistanceDetail) {
      applicantAssistanceDetail.receivedSocialAssistanceProgram = receivedSocialAssistanceProgramName;
      applicantAssistanceDetail.applicationDate = dateOfSocialAssistanceInPast
      applicantAssistanceDetail.memberID = memberId;
      jsonData.socialAssistanceDetailsList = socialAssistanceDetailsList;
    } else {
      jsonData.socialAssistanceDetailsList = [{
        applicant: applicantValue,
        applicantType: 'Applicant',
        applicantDisplayType: 'Applicant',
        applicantName: ' ' + jsonData.firstName,
        receivedSocialAssistanceProgram: receivedSocialAssistanceProgramName,
        memberID: memberId,
        applicationDate: dateOfSocialAssistanceInPast
      }, ...socialAssistanceDetailsList];
    }
  }

  // Called from ngOnInit. For family
  private updateIncarceratedDetail(appData: ApplicationAnswers, applicantValue: string) {
    const jsonData = appData.jsonData; // From question on before you start 2
    const dateOfReleaseFromInstitution = appData.jsonData.dateOfReleaseFromInstitution;
    const incarceratedStayPartTimeOrFullTime = appData.jsonData.incarceratedStayPartTimeOrFullTime;
    const incarceratedDetailsQuestion = jsonData.familyMembersCurrentlyIncarceratedList;
    const incarceratedDetailsList: any[] = incarceratedDetailsQuestion ? incarceratedDetailsQuestion : [];
    const applicantIncarceratedDetail = incarceratedDetailsList.find(v => v.applicant === applicantValue);
    if (applicantIncarceratedDetail) {
      applicantIncarceratedDetail.expectedReleaseDate = dateOfReleaseFromInstitution;
      applicantIncarceratedDetail.stayPartTimeOrFullTime = incarceratedStayPartTimeOrFullTime;
      jsonData.familyMembersCurrentlyIncarceratedList = incarceratedDetailsList;
    } else {
      jsonData.familyMembersCurrentlyIncarceratedList = [{
        applicant: applicantValue,
        applicantType: 'Applicant',
        applicantDisplayType: 'Applicant',
        applicantName: ' ' + jsonData.firstName,
        expectedReleaseDate: dateOfReleaseFromInstitution,
        stayPartTimeOrFullTime: incarceratedStayPartTimeOrFullTime
      }, ...incarceratedDetailsList];
    }
  }

  /**
   * ======================= Called on init =======================
   * Preselect incarceration (jail, prison, or overnight detention centre) question based on user selection from Before you start 2 page
   */
  private preSelectIncarcerationQuestion(appData: ApplicationAnswers, isSingle: boolean) {
    const data = appData.jsonData;
    const applicantValue = 'Applicant: ' + data.firstName;
    if (!!data.dateOfReleaseFromInstitution) {
      if (isSingle) { // Pre-select applicant incarceration on additional info
        data.additionalCurrentlyIncarcerated = 'yes';
        data.incarceratedExpectedReleaseDate = data.dateOfReleaseFromInstitution;
      }
      else { // Pre-select applicant incarceration on family info
        const incarceratedCheckbox: any[] = data.familyMembersCurrentlyIncarceratedCheckbox ?
                                            data.familyMembersCurrentlyIncarceratedCheckbox : [];
        if (!incarceratedCheckbox.includes(applicantValue)) {
          data.familyMembersCurrentlyIncarcerated = 'yes';
          data.familyMembersCurrentlyIncarceratedCheckbox = [applicantValue, ...incarceratedCheckbox];
        }
        this.updateIncarceratedDetail(appData, applicantValue);
      }
    }
    else { // No data to pre-select from bya, must delete existing incarcerated details for primary applicant
      if (data.additionalCurrentlyIncarcerated) {
        data.additionalCurrentlyIncarcerated = 'no';
      }

      if (data.familyMembersCurrentlyIncarcerated === 'yes'
          && !!data.familyMembersCurrentlyIncarceratedCheckbox?.find(recipient => recipient === applicantValue)) {
        // Remove the applicant from checkbox list
        data.familyMembersCurrentlyIncarceratedCheckbox.splice(
          data.familyMembersCurrentlyIncarceratedCheckbox.findIndex(recipient => recipient === applicantValue), 1);

        // Remove the detail
        data.familyMembersCurrentlyIncarceratedList?.splice(
          data.familyMembersCurrentlyIncarceratedList?.findIndex(detail => detail.applicant === applicantValue), 1);
      }

      if (data.familyMembersCurrentlyIncarceratedCheckbox?.length === 0) {
        delete data.familyMembersCurrentlyIncarcerated; // user re-confirm entry
      }
    }
  }


  /**
   * Carries over Development Services Ontario (DSO) response when switching application types.
   */
  private updateDevelopmentServicesOntarioResponse(appData: ApplicationAnswers, isSingle: boolean) {
    const data = appData.jsonData;

    if (isSingle) {
      // Transitioning to single applicant; carry over DSO response from family list to single field
      const dsoFamilyResponse = data.developmentServicesOntarioList?.find(a => a.applicant.startsWith('Applicant:'));
      if (dsoFamilyResponse) {
        data.developmentServicesOntario = dsoFamilyResponse.developmentServicesOntario;
      }
    } else {
      // Transitioning to family application; carry over DSO response from single field to family list
      if (data.developmentServicesOntario) {
        const applicantValue = `Applicant: ${data.firstName}`;
        const dsoList: any[] = data.developmentServicesOntarioList ? data.developmentServicesOntarioList : [];
        const applicantDsoDetail = dsoList.find(a => a.applicant === applicantValue);

        if (applicantDsoDetail) {
          // Update existing entry
          applicantDsoDetail.developmentServicesOntario = data.developmentServicesOntario;
        } else {
          // Add new entry if it doesn't exist
          dsoList.push({
            applicant: applicantValue,
            applicantType: 'Applicant',
            applicantDisplayType: 'Applicant',
            applicantName: ` ${data.firstName}`,
            developmentServicesOntario: data.developmentServicesOntario
          });
          data.developmentServicesOntarioList = dsoList;
        }
      }
    }
  }

  /**
   * Preselect disability question based on user selection from Before you start 2 page
   */
  private preSelectDisabilityQuestion(appData: ApplicationAnswers, isSingle: boolean) {
    const data = appData.jsonData;
    const applicantValue = 'Applicant: ' + data.firstName;
      if (isSingle) {
        data.personWithDisability = data.withDisability;
        if (data.withDisability === 'yes') {
          this.updateDevelopmentServicesOntarioResponse(appData, true);
        }
      } else if (data.withDisability === 'yes') {
          const personWithDisabilityInFamilyCheckbox: any[] = data.personWithDisabilityInFamilyCheckbox ?
            data.personWithDisabilityInFamilyCheckbox : [];
          if (!personWithDisabilityInFamilyCheckbox.includes(applicantValue)) {
            data.personWithDisabilityInFamily = 'yes';
            data.personWithDisabilityInFamilyCheckbox = [applicantValue, ...personWithDisabilityInFamilyCheckbox];
            this.updateDevelopmentServicesOntarioResponse(appData, false);
          }
      }
     else {
      if (data.personWithDisability) {
        data.personWithDisability = 'no';
      }
      if (data.personWithDisabilityInFamily &&
          !!data.personWithDisabilityInFamilyCheckbox?.find(recipient => recipient === applicantValue)) {
        data.personWithDisabilityInFamilyCheckbox.splice(
          data.personWithDisabilityInFamilyCheckbox.findIndex(recipient => recipient === applicantValue), 1);
      }
    }
  }

  private preSelectMoneyForImmediateNeedQuestion(appData: ApplicationAnswers, isSingle: boolean) {
    const data = appData.jsonData;
    const applicant = 'Applicant: ' + data.firstName;

    if (!isSingle) {
      if (data.moneyForImmediateNeed === 'yes') {
        const values: any[] = data.moneyForImmediateNeedInFamilyCheckbox ? data.moneyForImmediateNeedInFamilyCheckbox : [];
        if (!values.includes(applicant)) {
          data.moneyForImmediateNeedInFamily = 'yes';
          data.moneyForImmediateNeedInFamilyCheckbox = [applicant, ...values];
        }
      } else {
        if (data.moneyForImmediateNeedInFamily &&
          !!data.moneyForImmediateNeedInFamilyCheckbox?.find(recipient => recipient === applicant)) {
          data.moneyForImmediateNeedInFamilyCheckbox.splice(
            data.moneyForImmediateNeedInFamilyCheckbox.findIndex(recipient => recipient === applicant), 1);
        }
      }
    }
  }

  /**
   * Verify if DOB revised in previous pages and update ACSD keys
   */
  private dataCheckOnACSDQuestion(appData: ApplicationAnswers, isSingle: boolean) {
    if (!enableReceivingACSDFamilyFn(appData)) {
      if (isSingle && appData.jsonData.receivingACSD) {
        delete appData.jsonData.receivingACSD;
      } else if (appData.jsonData.receivingACSDFamily || appData.jsonData.receivingACSDFamilyCheckbox) {
        delete appData.jsonData.receivingACSDFamily;
        delete appData.jsonData.receivingACSDFamilyCheckbox;
      }
    }
  }
  /**
   * ============== Called on onSubmit of additional info page =================
   * Update incarceration (correctional facility, prison or overnight detention centre)
   * data for question in Before you start 2 page based user selection in additional information page
   */
  private updateBeforeYouStart2IncarcerationData(appData: ApplicationAnswers, isSingle: boolean) {
    const data = appData.jsonData;
    const familyMembersCurrentlyIncarcerated = data.familyMembersCurrentlyIncarcerated;

    // single applicant/additional info
   if (!isSingle && familyMembersCurrentlyIncarcerated) {
      if (familyMembersCurrentlyIncarcerated === 'yes') {
        const firstName = data.firstName;
        const applicantValue = `Applicant: ${firstName}`;
        const familyMembersCurrentlyIncarceratedList: any[] = data.familyMembersCurrentlyIncarceratedList ?
                                                              data.familyMembersCurrentlyIncarceratedList : [];
        const applicantIncarceratedDetail: any = familyMembersCurrentlyIncarceratedList.find(v => v.applicant === applicantValue);
        // update bya/screening data
        if (applicantIncarceratedDetail) {
          data.releasedFromInstitution = 'yes';
          data.dateOfReleaseFromInstitution = applicantIncarceratedDetail.expectedReleaseDate;
          data.incarceratedStayPartTimeOrFullTime = applicantIncarceratedDetail.stayPartTimeOrFullTime;
        } else {
          data.releasedFromInstitution = 'no';
          delete appData.jsonData.dateOfReleaseFromInstitution;
          delete appData.jsonData.incarceratedStayPartTimeOrFullTime;
        }
      } else {
        // Remove all incarceration related data
        // bya
        data.releasedFromInstitution = 'no';
        delete appData.jsonData.dateOfReleaseFromInstitution;
        delete appData.jsonData.incarceratedStayPartTimeOrFullTime;
        // family info
        delete appData.jsonData.familyMembersCurrentlyIncarceratedList;
        delete appData.jsonData.familyMembersCurrentlyIncarceratedCheckbox;
      }
    }
  }

  private updateEarnedIncomeDisabilityWorkExpenseData(appData: ApplicationAnswers) {
    const data = appData.jsonData;

    if((data.personWithDisability === 'no' || data.personWithDisabilityInFamily === 'no') && !!data.earnedIncome){
      appData.jsonData.earnedIncome.forEach(earnedIncome => {
        earnedIncome.earnedIncomeSourceList.forEach(incomeSource => {
          delete incomeSource.disabilityWorkExpense
          delete incomeSource.disabilityWorkExpenseMonthlyAmount
        })
      })
    }

    if(data.personWithDisabilityInFamily === 'yes' && !!data.earnedIncome){
      const peopleWithDisabilityApplicantType = this.getApplicantTypesWithDisability(data);
      appData.jsonData.earnedIncome
        .filter(earnedIncome => !peopleWithDisabilityApplicantType.includes(earnedIncome.applicantType))
        .forEach(earnedIncome => {earnedIncome.earnedIncomeSourceList.forEach(incomeSource => {
          delete incomeSource.disabilityWorkExpense
          delete incomeSource.disabilityWorkExpenseMonthlyAmount
        })})
    }
  }

  /**
   * Update social assistance data for question in Before you start 2 page based user selection in additional information page
   */
  private updateBeforeYouStart2SocialAssistanceData(appData: ApplicationAnswers, isSingle: boolean) {
    const data = appData.jsonData;
    const receivedSocialAssistanceInPast = data.receivedSocialAssistanceInPast;
    const familyReceivedSocialAssistanceInPast = data.familyReceivedSocialAssistanceInPast;
    if (isSingle && receivedSocialAssistanceInPast) {
      if (receivedSocialAssistanceInPast === 'yes') {
        data.additionalReceivedSocialAssistanceProgram = data.receivedSocialAssistanceProgram;
        data.additionalMemberID = data.memberID;
        data.additionalApplicationDate = data.dateOfSocialAssistanceInPast;
      } else {
        // Remove all social assistance related data
        this.deleteApplicantPastAssistanceInfo(appData);
        delete appData.jsonData.additionalReceivedSocialAssistanceProgram;
        delete appData.jsonData.additionalApplicationDate;
        delete appData.jsonData.additionalMemberID;
      }
    } else if (!isSingle && familyReceivedSocialAssistanceInPast) {
      if (familyReceivedSocialAssistanceInPast === 'yes') {
        const firstName = data.firstName;
        const applicantValue = `Applicant: ${firstName}`;
        const socialAssistanceDetailsList: any[] = data.socialAssistanceDetailsList ? data.socialAssistanceDetailsList : [];
        const applicantAssistanceDetail: any = socialAssistanceDetailsList.find(v => v.applicant === applicantValue);
        if (applicantAssistanceDetail) {
          data.receivedSocialAssistanceInPast = 'yes'
          data.receivedSocialAssistanceProgram = applicantAssistanceDetail.receivedSocialAssistanceProgram;
          data.memberID = applicantAssistanceDetail.memberID
          data.dateOfSocialAssistanceInPast = applicantAssistanceDetail.applicationDate
        } else {
          data.receivedSocialAssistanceInPast = 'no'
          this.deleteApplicantPastAssistanceInfo(appData);
        }
      } else {
        // Remove all social assistance related data
        data.receivedSocialAssistanceInPast = 'no'
        this.deleteApplicantPastAssistanceInfo(appData);
        delete appData.jsonData.socialAssistanceDetailsList;
        delete appData.jsonData.socialAssistanceRecipients;
      }
    }
  }

  /**
   * Update Disability data for question in Before you start 2 page based user selection in additional information page
   */
  private updateBeforeYouStart2Disability(appData: ApplicationAnswers, isSingle: boolean) {
    const data = appData.jsonData;
    const additionalDisability = data.personWithDisability;
    if ((isSingle && additionalDisability === 'yes') || !isSingle &&
      this.getApplicantTypesWithDisability(data)?.includes('Applicant')) {
      data.withDisability = 'yes';
    } else {
      // Remove disability related data
      data.withDisability = 'no'
    }
  }

  private updateBeforeYouStart2MoneyForImmNeed(appData: ApplicationAnswers, isSingle: boolean) {
    const data = appData.jsonData;
    if ((isSingle && data.moneyForImmediateNeed === 'yes') || !isSingle &&
      this.getApplicantTypesWithMoneyForImmNeed(data)?.includes('Applicant')) {
      data.moneyForImmediateNeed = 'yes';
    } else {
      data.moneyForImmediateNeed = 'no'
    }
  }

  private getApplicantTypesWithDisability(data: any) {
    return data.personWithDisabilityInFamilyCheckbox?.map(personWithDisability => {
      const personWithDisabilityTypeName = personWithDisability.split(':')
      return personWithDisabilityTypeName[0]
    })
  }

  private getApplicantTypesWithMoneyForImmNeed(data: any) {
    return data.moneyForImmediateNeedInFamilyCheckbox?.map(person => {
      const typeName = person.split(':')
      return typeName[0]
    })
  }

  private deleteApplicantPastAssistanceInfo(appData: ApplicationAnswers) {
    delete appData.jsonData.receivedSocialAssistanceProgram;
    delete appData.jsonData.memberID;
    delete appData.jsonData.dateOfSocialAssistanceInPast;
  }

  private deleteFamilyApplicantData(appData: ApplicationAnswers) {
    delete appData.jsonData.fullTimeStudentInFamilyList;
    delete appData.jsonData.pregnantOrBreastFeedingInFamilyList;
    delete appData.jsonData.socialAssistanceDetailsList;
    delete appData.jsonData.familyMembersResidingInInstitutionList;
    delete appData.jsonData.developmentServicesOntarioList;
    delete appData.jsonData.socialAssistanceRecipients;
    delete appData.jsonData.personWithDisabilityInFamilyCheckbox;
    delete appData.jsonData.moneyForImmediateNeedInFamilyCheckbox;
    delete appData.jsonData.specialDietOrMedicalConditionInFamilyCheckbox;
    delete appData.jsonData.fullTimeStudentInFamilyCheckbox;
    delete appData.jsonData.familyMembersResidingInInstitutionCheckbox;
    delete appData.jsonData.familyMembersCurrentlyIncarceratedCheckbox;
    delete appData.jsonData.familyMembersCurrentlyIncarceratedList;
    delete appData.jsonData.familyMembersCurrentlyIncarcerated;
    delete appData.jsonData.pregnantOrBreastFeedingInFamilyCheckbox;
    delete appData.jsonData.familyReceivedSocialAssistanceInPast;
    delete appData.jsonData.pregnantOrBreastFeedingInFamily;
    delete appData.jsonData.specialDietOrMedicalConditionInFamily;
    delete appData.jsonData.fullTimeStudentInFamily;
    delete appData.jsonData.personWithDisabilityInFamily;
    delete appData.jsonData.receivingACSDFamily;
    delete appData.jsonData.receivingACSDFamilyCheckbox;
    delete appData.jsonData.moneyForImmediateNeedInFamily;
    delete appData.jsonData.familyMembersResidingInInstitution;
    delete appData.jsonData.caringForChild;
    delete appData.jsonData.familyAccommodationServicesCheckbox;
    delete appData.jsonData.additionalAccommodationServicesInFamilyList;
    delete appData.jsonData.additionalFamilyAccommodationServices;
  }

  private deleteSingleApplicantData(appData: ApplicationAnswers) {
    delete appData.jsonData.additionalFullTimeStudent;
    delete appData.jsonData.additionalSchoolType;
    delete appData.jsonData.additionalReceivedSocialAssistanceInPast;
    delete appData.jsonData.additionalReceivedSocialAssistanceProgram;
    delete appData.jsonData.additionalApplicationDate;
    delete appData.jsonData.developmentServicesOntario;
    delete appData.jsonData.additionalMemberID;
    delete appData.jsonData.pregnantCheckbox;
    delete appData.jsonData.dueDate;
    delete appData.jsonData.breastFeedingCheckbox;
    delete appData.jsonData.lactoseIntolerantDiet;
    delete appData.jsonData.additionalNutritionalNeeds;
    delete appData.jsonData.additionalCurrentlyResidingInAnInstitution;
    delete appData.jsonData.additionalLivingInAnInstitutionDropdown;
    delete appData.jsonData.additionalInstitutionName;
    delete appData.jsonData.additionalCurrentlyIncarcerated;
    delete appData.jsonData.incarceratedExpectedReleaseDate;
    delete appData.jsonData.accommodationServices;
    delete appData.jsonData.additionalSpecialDietOrMedicalCondition;
    delete appData.jsonData.additionalCaringForChild;
    delete appData.jsonData.additionalAccommodationServices;
    delete appData.jsonData.visualCheckbox;
    delete appData.jsonData.visualDescription;
    delete appData.jsonData.hearingCheckbox;
    delete appData.jsonData.hearingDescription;
    delete appData.jsonData.verbalCheckbox;
    delete appData.jsonData.verbalDescription;
    delete appData.jsonData.communicationCheckbox;
    delete appData.jsonData.communicationDescription;
    delete appData.jsonData.mobilityCheckbox;
    delete appData.jsonData.mobilityDescription;
    delete appData.jsonData.personWithDisability;
    delete appData.jsonData.receivingACSD;
    delete appData.jsonData.moneyForImmediateNeed;
  }
}
