<sd-page-container [pageId]="pageId"
                   [showBackButton]=true
                   [showPsRedirectError]="showPsRedirectError"
                   [showResumeBanner]="showResumeBanner"
                   [showRequiredInfoBanner]="showRequiredInfoBanner"
                   [pageTitleInsideComponent]="true">

  <div class="card-container">
    <div class="page-inner-container">
      <mccss-address-summary
        [addressData]="addressData"
        [showReapplTitle]="showReapplTitle"
        (editAddress)="onEditAddress()"
      ></mccss-address-summary>
    </div>
  </div>

  <app-mccss-save-continue-button [pageId]="pageId"
    dataE2E="continueBtn"
    (handlePrimaryButtonClick)="onSubmit(true)"
    (handleSecondaryButtonClick)="onSubmit(false)">
  </app-mccss-save-continue-button>
</sd-page-container>