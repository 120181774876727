<sd-page-container [pageId]="pageId"
                   [showBackButton]=true
                   [showPsRedirectError]="showPsRedirectError"
                   [showResumeBanner]="showResumeBanner"
                   [showRequiredInfoBanner]="showRequiredInfoBanner">
  <div id="page-alerts-container">
    <sd-info-text *ngIf="isApplyingForSomeOneElse" header="important.info.header"
                  content="personal-information.applyingForSomeOneElse.info.text"></sd-info-text>
  </div>
  <form [formGroup]="form" sdDisableAutocomplete>
    <div class="form-group required">

      <sd-text-question [showError]="showError" [id]="'personal-information.firstName'" [required]="true"
                        [validationFns]="firstNameValidationFunction" [capitalizeFirstLetter]= true
                        [minLength]= 1 [maxLength]= 65
                        [characterWidth]= 20 [truncateMultipleSpaces]="true"
                        [disabled]="authenticatedMyBApplicant"
                        [hintText]="'personal-information.firstName.hintText'"
                        controlName="firstName">
      </sd-text-question>

      <sd-text-question [showError]="showError" [id]="'personal-information.lastName'"
                        [required]="!isNoLastNameSelected()"
                        [validationFns]="lastNameValidationFunction" [capitalizeFirstLetter]= true
                        [minLength]= 1 [maxLength]= 65
                        [characterWidth]= 20 [truncateMultipleSpaces]="true"
                        [disabled]="authenticatedMyBApplicant"
                        [hintText]="'personal-information.lastName.hintText'"
                        controlName="lastName">
      </sd-text-question>

      <sd-checkbox-question [id]="'personal-information.noLastName'"
                            [options]="noLastNameCheckBoxItems"
                            [disabled]="authenticatedMyBApplicant"
                            [showLabel]=false
                            (toggle)="onNoLastNameCheckBoxChanges('noLastName',$event)">
      </sd-checkbox-question>

      <sd-date [showError]="showError"
               id="personal-information-dateOfBirth"
               label="personal-information.dateOfBirth"
               controlNameDate="dateOfBirth"
               [disabled]="authenticatedMyBApplicant"
               [infoMessage]="shouldShowInfoMessage()"
               [validationFns]="dobValidationFunctions"
               [needMarginTop] = true
               [required]=true>
      </sd-date>

      <sd-radio-question [choices]="sexes" [showError]="showError" [id]="'personal-information.sexAtBirth'" [required]="true"
                         [hintText]="'personal-information.sexAtBirth.hintText'"
                         controlName="sexAtBirth" [context]="'personal-information.sexAtBirth.context'">
      </sd-radio-question>

      <sd-dropdown-question [choices]="maritalStatusChoices" [showError]="showError" [id]="'personal-information.maritalStatus'"
                            [hintText]="'personal-information.maritalStatus.hintText'" [hintExpandableContent]="'personal-information.maritalStatus.hintContent'"
                            [required]="true"
                            [disabled]="isReapplication"
                            controlName="maritalStatus">
      </sd-dropdown-question>
      <sd-radio-question [showError]="showError" [id]="'personal-information.childrenLivingWithYou'"
                         [hintText]="'personal-information.childrenLivingWithYou.hintText'"
                         [disabled]="isReapplication"
                         controlName="childrenLivingWithYou" [required]="true" [context]="'personal-information.childrenLivingWithYou.context'">
      </sd-radio-question>

      <sd-status-in-canada-questions [id]="'personal-information.status'"
                                     [applicationAnswers]="applicationAnswers"
                                     [applicantType]="applicantType"
                                     [disableIdentifyingFields]="authenticatedMyBApplicant"
                                     [showError]="showError"
                                     [hintText]="'true'" [hintContent]="'true'"
                                     [hintContentParam]="officeLocationLinkParam">
      </sd-status-in-canada-questions>

      <sd-text-question
        [showError]="showError"
        [id]="'personal-information.email'"
        [showCustomRequiredErrorMessage]="customRequiredErrorMessage"
        [validationFns]="emailValidationFns"
        [asyncValidationFns]="emailAsyncValidationFns"
        [disabled]="disableForAuthenticatedApplicant() || authenticatedMyBApplicant"
        [required]="isEmailRequired()"
        (keydown.enter)="preventDefaultAction($event)"
        [characterWidth]="20"
        (blurEvent)="setEmail($event)"
        [hintText]="authorizeService.isIBauUser() ? 'emailHintWithDesc' : 'emailHint'"
        controlName="email">
      </sd-text-question>

      <sd-checkbox-question *ngIf="authorizeService.isAuthorized()" [id]="'personal-information.noUniqueEmail'" [options]="noUniqueEmailCheckBoxItem"
                            [showLabel]=false
                            (toggle)="onNoUniqueEmailCheckBoxChanges('noUniqueEmail',$event)">
      </sd-checkbox-question>

      <sd-text-question [showError]="showError" [id]="'personal-information.phone'"
                        [dataType]="dataType.PN"
                        [required]= true
                        [characterWidth]= 10
                        [hintText]="'personal-information.phone.hintText'"
                        [hintExpandableContent]="'personal-information.phone.hintContent'"
                        controlName="phone">
      </sd-text-question>

      <sd-radio-question [choices]="languages" [showError]="showError" [id]="'personal-information.contactLanguage'"
                         [required]= true
                         controlName="contactLanguage" [context]="'personal-information.contactLanguage.context'">
      </sd-radio-question>
    </div>
    <app-mccss-save-continue-button [pageId]="pageId"
                                    dataE2E="continueBtn"
                                    (handlePrimaryButtonClick)="onSubmit(true)"
                                    (handleSecondaryButtonClick)="onSubmit(false)">
    </app-mccss-save-continue-button>
  </form>
</sd-page-container>
