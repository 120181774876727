<div attr.name="{{fieldName}}" class="ontario-form-group {{required ? 'required' : ''}}" [formGroup]="form">
  <div>
    <label class="ontario-label" id="{{id}}" [for]="getElementId(ELEMENT_ID_PREFIX)">
      {{label | translate : labelParam }}
      <span class="ontario-label__flag">{{(required ? 'Required' : 'Optional') | translate}}
      </span>
    </label>
    <sd-hint-text *ngIf="hintText && !hintExpandableContent"
                  id="{{id}}-question-hintText"
                  [occurrence]="labelParam?.index"
                  [text]="hintText">
    </sd-hint-text>
  </div>

  <div class="ontario-margin-bottom-16-!" id="{{id}}-extraLabel" *ngIf="extraLabelDescription" [innerHTML]="extraLabelDescription | translate"></div>

  <div *ngIf="isAlert" class="ontario-margin-bottom-16-!">
    <div class="alert mccssWarning">
      <span role="alert">
        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
          <use href="#ontario-icon-alert-information"></use>
        </svg>
        {{alertMessage | translate}}</span>
    </div>
  </div>

  <div *ngIf="showError && formControl.hasError('required')">
    <sd-alert-err *ngIf="showCustomRequiredErrorMessage" [errorId]="id.concat('-required-err')" [errorMessage]="showCustomRequiredErrorMessage | translate" [isInnerHtml]="'true'"></sd-alert-err>
    <sd-alert-err *ngIf="!showCustomRequiredErrorMessage" [errorId]="id.concat('-required-err')" [errorMessage]="'error.required' | translate"></sd-alert-err>
  </div>

  <sd-alert-err *ngIf="!formControl.hasError('required') && formControl.errors?.customErrorKey && (formControl.touched || showError)"
                [errorId]="id.concat('-custom-err-key')" [errorMessage]="formControl.errors?.customErrorKey | translate: customErrorParam" [isInnerHtml]="'true'">
  </sd-alert-err>

  <div *ngIf="(formControl.hasError('minlength') || formControl.hasError('maxlength')) && (formControl.touched || showError)" >
    <sd-alert-err *ngIf="!showCustomMinMaxLengthErrorMessage" [errorId]="id.concat('-minmax-length-err-key')" [errorMessage]="'error.minMaxLength' | translate: { 'minLengthValue': minLength, 'maxLengthValue': maxLength }"></sd-alert-err>
    <sd-alert-err *ngIf="showCustomMinMaxLengthErrorMessage" [errorId]="id.concat('-custom-minmax-length-err-key')" [errorMessage]="showCustomMinMaxLengthErrorMessage | translate: { 'minLengthValue': minLength, 'maxLengthValue': maxLength }"></sd-alert-err>
  </div>

  <sd-alert-err *ngIf="formControl.hasError('email') && (formControl.touched || showError)"
                [errorId]="id.concat('-email-format-err-key')" [errorMessage]="'error.invalid.email.format' | translate"></sd-alert-err>
  <sd-alert-err *ngIf="formControl.hasError('validationRegex') && (formControl.touched || showError)"
                [errorId]="id.concat('-regex-err-key')" [errorMessage]="validationRegexError | translate">
  </sd-alert-err>
  <sd-alert-err *ngIf="showCustomError && (formControl.touched || showError)"
                [errorId]="id.concat('-custom-err')" [errorMessage]="showCustomError | translate: showCustomErrorParam" [isInnerHtml]="'true'"></sd-alert-err>

  <sd-alert-err *ngIf="!formControl.hasError('required') && formControl.errors?.customMinMaxErrorKey && (formControl.touched || showError)"
                [errorId]="id.concat('-custom-minmax-length-err-key')" [errorMessage]="formControl.errors?.customMinMaxErrorKey | translate" [isInnerHtml]="'true'">
  </sd-alert-err>

  <div *ngIf="!isAutoComplete" [ngClass]="{'currency-wrap': isDollarValue }">
    <span *ngIf="isDollarValue" class="currency-code">$</span>
    <input *ngIf="!allowsNumbersOnly"
           id="{{getElementId(ELEMENT_ID_PREFIX)}}"
           [formControlName]="controlName"
           type="text"
           (blur)="onBlur()" (focus)="onFocus()" (paste)="onPaste($event)"
           class="ontario-input {{getInputClass(characterWidth)}}" [ngClass]="{'currency-padding': isDollarValue, 'ontario-input__error': shouldShowErrorStyle }"
           attr.aria-required="{{required}}"
           [attr.aria-disabled]="disabled ? true : null"
           attr.aria-describedby="{{id}}-err {{id}}-phone-err {{id}}-custom-err {{id}}-custom-err-key">
    <input *ngIf="allowsNumbersOnly"
           id="{{getElementId(ELEMENT_ID_PREFIX)}}"
           [formControlName]="controlName"
           type="text"
           (blur) = "onBlur()" (focus)="onFocus()" (paste)="onPaste($event)"
           class="ontario-input {{getInputClass(characterWidth)}}" [ngClass]="{'currency-padding': isDollarValue, 'ontario-input__error': shouldShowErrorStyle }"
           attr.aria-required="{{required}}"
           [attr.aria-disabled]="disabled ? true : null"
           attr.aria-describedby="{{id}}-err {{id}}-phone-err {{id}}-custom-err {{id}}-custom-err-key"
           numbersOnly>
  </div>
  <div *ngIf="isAutoComplete">
    <mat-form-field>
      <input id="{{getElementId(ELEMENT_ID_PREFIX)}}"
             [formControlName]="controlName"
             type="text"
             (blur)="onBlur()" (focus)="onFocus()" (paste)="onPaste($event)"
             class="ontario-input {{getInputClass(characterWidth)}}"
             [ngClass]="{'currency-padding': isDollarValue, 'ontario-input__error': shouldShowErrorStyle }"
             attr.aria-required="{{required}}"
             [attr.aria-disabled]="disabled ? true : null"
             attr.aria-describedby="{{id}}-err {{id}}-phone-err {{id}}-custom-err {{id}}-custom-err-key"
             attr.aria-controls="{{id.concat('-required-err')}} {{id.concat('-custom-err-key')}} {{id.concat('-minmax-length-err-key')}} {{id.concat('-custom-minmax-length-err-key')}} {{id.concat('-regex-err-key')}} {{id.concat('-custom-err')}} {{id.concat('-custom-minmax-length-err-key')}}"
             ellipsis
             matInput
             [matAutocomplete]="auto"
             matAutocompletePosition="auto"
             autocompletePosition="trigger">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredAutoCompleteOptions | async" class="textbox-auto-complete-word-wrap" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <sd-hint-text *ngIf="hintText && hintExpandableContent"
                id="{{id}}-question-hintText"
                [occurrence]="labelParam?.index"
                [text]="hintText"
                [expandableContent]="hintExpandableContent"
                [expandableContentParam]="hintExpandableContentParam">
  </sd-hint-text>
</div>
