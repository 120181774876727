import {Injectable} from '@angular/core';

import {ClientConfig} from '../models/client-config';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigServiceImpl implements ConfigService {
  private clientConfig: ClientConfig;

  constructor() {}

  getClientConfig(): ClientConfig {
    return this.clientConfig;
  }
  setClientConfig(clientConfig: ClientConfig) {
    this.clientConfig = clientConfig;
  }

  getUrl(language: string, key: string): string {
    return this.getClientConfig()?.urls[language || 'en'][key];
  }

  /**
   * Returns an object with urls of the provided key of all languages.
   * @param key Key of the URLs sought after.
   */
  getUrls(key: string): any {
    let urls: any = null;
    if (this.getClientConfig()?.urls) {
      urls = {};
      Object.keys(this.getClientConfig().urls).forEach((urlKey) => {
        urls[urlKey] = this.getClientConfig().urls[urlKey][key];
      })
    }
    return urls;
  }

  // TODO: remove it when ODSP resubmission is allowed
  getIsODSPResubmissionAllowedFlag(): boolean{
    let isODSPResubmissionAllowed = false;
    if (this.getClientConfig()?.enableODSPResubmission) {
      isODSPResubmissionAllowed = this.getClientConfig().enableODSPResubmission
    }

    return isODSPResubmissionAllowed;
  }
}
