import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Observable, of, throwError} from 'rxjs';
import {catchError, concatMap, delay, map, retryWhen} from 'rxjs/operators';

import {ValidationService} from './validation.service'
import {ApplicationValidationResponse} from '../models/application-validation-response';

@Injectable({providedIn: 'root'})
export class ValidationServiceImpl implements ValidationService {
  constructor(private http: HttpClient) {
  }

  validateHealthCardNumber(healthCardNumber: string, resultCallback: any) {
    this.validate(`health-card-number`, healthCardNumber).subscribe(
      (value) => {
        return resultCallback(value)
      },
      () => resultCallback(false))
  }

  validateSocialInsuranceNumber(sin: string, resultCallback: any) {
    this.validate(`sin`, sin).subscribe(
      (value) => {
        return resultCallback(value)
      },
      () => resultCallback(false))
  }

  validate(uri: string, payload: string): Observable<boolean> {
    const maxRetry = 3;

    return this.http.post(
      `/v1/validation/${uri}`,
      {payload},
      {withCredentials: true})
      .pipe(map(response => {
          return true
        }),
        retryWhen(error =>
          error.pipe(
            concatMap((err, count) => {
              // retry on any error that is not 404 such as network connection issue
              if (err.status !== 404 && count < maxRetry) {
                return of(err);
              } else {
                return throwError(err);
              }
            }),
            delay(1000) // retry wait milliseconds
          )
        ),
        catchError((err) => {
          return of(false)
        })
      )
  }

  validateApplication(): Observable<ApplicationValidationResponse> {
    return this.http.get<ApplicationValidationResponse>(`/v1/application/validate`,
      {withCredentials: true}).pipe(
        catchError(error => of(error.error)),
    );
  }
}
