import {Component, Inject, LOCALE_ID, NgZone, OnDestroy, OnInit} from '@angular/core';
import {IntakeService} from '../../../services/intake.service';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router} from '@angular/router';
import {ValidationService} from '../../../services/validation.service';
import {ApplicationValidationError} from '../../../models/application-validation-error';
import {reviewQuestions} from './review-questions';
import {BaseComponent} from '../../BaseComponent';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ExternalRouter} from '../../../external.router';
import {ApplicationAnswers} from '../../../models/data.model';
import {LabelParam, Page, Panel, Question} from './page';
import {ConfigService} from '../../../services/config.service';
import {PageInfo} from '../../../models/page-map';
import {AuthService} from '../../../services/auth.service';
import {PageService} from '../../../services/page.service';
import {LoadingSpinnerService} from '../../../services/loading-spinner.service';
import {getCurrentMonthAsString} from '../../../utils/date-util';
import {PageScrollingUtil} from '../../../utils/page-scrolling-util';
import {SadaCustomValidator} from 'src/app/validator/sada-custom-validator';
import {UrlInfo} from 'src/app/models/url-map';
import {ReviewValidationError} from 'src/app/models/review-validation-error';
import {SharedUtil} from '@shared/shared.util';
import {ReapplicationService} from '../../../services/reapplication.service';
import {ProgramTypeService} from '../../../services/program-type.service';
import {ProgramTypeUpdateRequest} from '../../../models/program-type-update-request';
import {ProgramType} from '../../../models/program-type';

@Component({
  selector: 'sd-app-summary',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent extends BaseComponent implements OnInit, OnDestroy {
  applicationValidationErrors: ApplicationValidationError[];
  reviewValidationErrors: ReviewValidationError[] = [];
  pages: Page[];
  readonly faPencilAlt = faPencilAlt;
  earnedIncomeError:boolean;
  dateOfReleaseFromInstitutionError:boolean;
  receivingMoneyForReasonError: boolean;
  invalidLivingWithYouRelationshipError: boolean;
  emergencyAssistanceLink: string; // prevent potential performance issue, because not use OnPush
  hasError: boolean;
  applicationAnswers: ApplicationAnswers = {jsonData: {}}
  errorHeader: string;
  errorBody: string;

  constructor(intakeService: IntakeService,
              private validationService: ValidationService,
              private router: Router,
              route: ActivatedRoute,
              @Inject(LOCALE_ID) protected localeId: string,
              public translator: TranslateService,
              public ngZone: NgZone, public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService,
              private reapplicationService: ReapplicationService,
              private programTypeService: ProgramTypeService) {
    super(intakeService, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService,
      loadingSpinnerService);
    this.pageId = PageInfo.review;
  }

  ngOnInit(): void {
    this.invalidLivingWithYouRelationshipError = false; // Initialize the error flag

    this.subscriptions$.push(
      this.route.data.subscribe((data: { appData: ApplicationAnswers }) => {
        this.applicationAnswers.jsonData = { ...data.appData.jsonData };
        this.initValidationErrors();

        // TODO - This is a temporary fix to handle the case where user's answer in the panel question is no longer available for livingWithYouRelationship question. It should be implemented in a generic way to handle all similar cases where panel questions have errors.
        const currentHousingSituation = this.applicationAnswers.jsonData.currentHousingSituation;
        const validRelationshipChoices = SharedUtil.getLivingWithYouRelationshipChoices(currentHousingSituation) || [];
        const validRelationshipValues = validRelationshipChoices.map(choice => choice.value);

        this.reviewValidationErrors = this.reviewValidationErrors || [];
        const invalidRelationshipIndexes: number[] = [];

        // Iterate over otherPersonsLivingWithYou and validate relationships
        if (Array.isArray(this.applicationAnswers.jsonData.otherPersonsLivingWithYou)) {
          this.applicationAnswers.jsonData.otherPersonsLivingWithYou.forEach((person, index) => {
            const relationship = person.livingWithYouRelationship;
            if (!validRelationshipValues.includes(relationship)) {
              invalidRelationshipIndexes.push(index);
              this.invalidLivingWithYouRelationshipError = true;
            }
          });
        }

        // TODO This is temporary fix to handle condo fee and property tax for resumed apps. Should be removed in next release.
        if (SharedUtil.doNotPayRent(currentHousingSituation)) {
          if (Array.isArray(this.applicationAnswers.jsonData.otherHousingCostList)) {
            this.applicationAnswers.jsonData.otherHousingCostList = this.applicationAnswers.jsonData.otherHousingCostList
              .filter(value => !['Condo fees', 'Property tax', 'Home or tenant insurance'].includes(value)
              );
          }
        }

        this.subscriptions$.push(
          this.validationService.validateApplication().subscribe(validateApplicationResponse => {
            if (validateApplicationResponse && validateApplicationResponse.errorCode) {
              this.applicationValidationErrors = validateApplicationResponse.errors;
            }

            this.hasError =
              this.invalidLivingWithYouRelationshipError ||
              this.earnedIncomeError ||
              this.dateOfReleaseFromInstitutionError ||
              this.receivingMoneyForReasonError ||
              (!!this.applicationValidationErrors && !!this.applicationValidationErrors.length);


            if (this.invalidLivingWithYouRelationshipError) {
              this.errorHeader = 'review.livingWithYouRelationship.error.header';
              this.errorBody = 'review.livingWithYouRelationship.error.body';

              invalidRelationshipIndexes.forEach(index => {
                this.reviewValidationErrors.push(
                  new ReviewValidationError(
                    `otherPersonsLivingWithYou-${index}-livingWithYouRelationship`,
                    'review.bya.receive.money.reason.inline.error'
                  )
                );
              });
            } else if (this.applicationValidationErrors && this.earnedIncomeError) {
              this.errorHeader = 'review.earnedIncome.month.error.header';
              this.errorBody = 'review.app.validation.earned.month.error.body';
            } else if (this.applicationValidationErrors) {
              this.errorHeader = 'error.mandatory.fields.missing.header';
              this.errorBody = 'error.mandatory.fields.missing';
            } else if (this.earnedIncomeError) {
              this.errorHeader = 'review.earnedIncome.month.error.header';
              this.errorBody = 'review.earnedIncome.month.error.body';
            } else if (this.dateOfReleaseFromInstitutionError) {
              this.errorHeader = 'review.bya.incarceration.date.error.header';
              this.errorBody = 'review.bya.incarceration.date.error.body';
            } else if (this.receivingMoneyForReasonError) {
              this.errorHeader = 'review.bya.receive.money.reason.error.header';
              this.errorBody = 'review.bya.receive.money.reason.error.body';
              this.reviewValidationErrors.push(
                new ReviewValidationError('receivingMoneyForReason', 'review.bya.receive.money.reason.inline.error')
              );
            }

            this.pages = this.populateAnswers(reviewQuestions);

            //   Set timeout so error messages are rendered first
            setTimeout(() => {
              // Add new selectors to the query string if new control types are added
              const queryString = '.ontario-alert__header-title.ontario-h4, .ontario-error-messaging__icon';
              const invalidFormControl = PageScrollingUtil.locateElement(queryString);

              PageScrollingUtil.scrollToElement(invalidFormControl, 150);
            },500);
          })
        );
      })
    );
  }



  ngOnDestroy(): void {
    super.onDestroy()
  }

  onEditClicked(page: Page) {
    this.router.navigate([  '/', 'intake', page.name])
  }

  onSubmit(toContinue: boolean): void {
    this.showPsRedirectError = false;  // Reset the error flag.

    if(this.reapplicationService.isReapplication()){
      this.openDialog({
        title: 'review.modal.reapplication.title',
        body: 'review.modal.reapplication.body',
        button: 'review.modal.reapplication.button',
        rightButton: 'review.modal.reapplication.rightButton'
      }, false, ()=>{
        this.saveAndSubmit(toContinue)
      }, ()=>{}, false)
    }else{
      this.saveAndSubmit(toContinue);
    }
  }

  saveAndSubmit(toContinue){
    if (toContinue && !this.authService.isAuthorizedToSave()) {
      this.intakeService.saveApplication(this.applicationAnswers, this.pageId).subscribe(() => {
        this.router.navigate(['/', 'intake', PageInfo.programRecommendation])
      })
    } else if (toContinue) {
      this.saveApplicationToResume(this.applicationAnswers, this.pageId).subscribe(() => {
        if (this.reapplicationService.isReapplication()) {
          // TODO below needs to be removed once reapplication page is implemented
          const request = new ProgramTypeUpdateRequest();
          request.programType = ProgramType.ONW;
          request.recommendedProgramType = ProgramType.ONW;
          this.programTypeService.updateProgramType(request).subscribe(() => {
            this.router.navigate(['/', PageInfo.wda]);
          })
        } else {
          this.router.navigate(['/', 'intake', PageInfo.programRecommendation])
        }
      })
    } else {  // 'Save & Exit' is requested for authenticated applicant.
      this.saveToResumeAndLogout(this.applicationAnswers, this.pageId);
    }
  }
  findApplicationValidationError(question: Question): ApplicationValidationError {
    if (this.applicationValidationErrors && this.applicationValidationErrors.length) {
      return this.applicationValidationErrors.find((error) => question.name === error.field);
    }
    return undefined;
  }

  editAria(title: any) {
    return this.translator.instant('review.edit.label').concat(' ', this.translator.instant(title));
  }

  populateAnswers(pages: Page[]): Page[] {
    const populatedPages: Page[] = [];
    pages.forEach((page: Page) => {
      const populatedPage: Page = {...page};
      populatedPage.isVisible = page.isVisibleCondition ? page.isVisibleCondition(this.applicationAnswers) : true;
      populatedPage.isErrorIconVisible = page.isErrorIconVisibleCondition ?
        // tslint:disable-next-line:max-line-length
                            page.isErrorIconVisibleCondition(this.applicationAnswers, this.applicationValidationErrors, this.reviewValidationErrors): false;
      if (populatedPage.isVisible) {
        populatedPage.questions = this.populateElementValues(page.questions, this.applicationAnswers.jsonData);
        populatedPages.push(populatedPage)
      }
    })

    return populatedPages;
  }

  private initValidationErrors() {
    this.earnedIncomeError = !!this.applicationAnswers.jsonData.earnedIncome
        && this.applicationAnswers.jsonData.earnedIncome[0].earnedIncomeMonth !== getCurrentMonthAsString() ? true : false;

    this.dateOfReleaseFromInstitutionError = !!this.applicationAnswers.jsonData.dateOfReleaseFromInstitution
        &&
          (SadaCustomValidator.isInPastExcludeToday([this.applicationAnswers.jsonData.dateOfReleaseFromInstitution])
            || !SadaCustomValidator.isDateWithinDaysIncludeToday([this.applicationAnswers.jsonData.dateOfReleaseFromInstitution, '10'])
          );
    this.receivingMoneyForReasonError = !!this.applicationAnswers.jsonData.receivingMoneyForReason
        && 'yes' === this.applicationAnswers.jsonData.receivingMoneyForReason.toLowerCase();

    this.emergencyAssistanceLink = this.configService.getUrl(this.translator.currentLang, UrlInfo.emergencyAssistance);
  }

  private populateElementValues(
    elements: Question[],
    jsonData: any,
    parentName?: string
  ): Question[] {
    const resultElements: Question[] = [];
    elements.forEach((element: Question) => {
      const populatedElement: Question = { ...element };
      if (element.isVisibleCondition) {
        populatedElement.isVisible = element.isVisibleCondition(this.applicationAnswers, jsonData);
      } else {
        populatedElement.isVisible = populatedElement.isVisible !== undefined ? populatedElement.isVisible : true;
      }

      if (populatedElement.isVisible) {

        // TODO - Temporary fix. Should be reimplemented to handle all similar cases where panel questions have errors.
        let fullQuestionName = element.name;
        if (parentName && element.name === 'livingWithYouRelationship') {
          fullQuestionName = `${parentName}-${element.name}`;
        }
        populatedElement.name = fullQuestionName;

        if (element.type === 'paneldynamic') {
          const panelElements = element.panels[0]?.questions;
          const panelDataArray = jsonData[element.name];
          if (panelDataArray) {
            populatedElement.panels = panelDataArray.map((elementData, index) => {
              const panel: Panel = new Panel();
              panel.label = element.panels[0].label;
              panel.populatedLabelParams = element.panels[0].labelParams
                ? this.populateLabelParams(element.panels[0].labelParams, elementData)
                : '';

              // TODO - Temporary fix. Should be reimplemented to handle all similar cases where panel questions have errors.
              // Check if the panel is 'otherPersonsLivingWithYou'
              let panelParentName = fullQuestionName;
              if (element.name === 'otherPersonsLivingWithYou') {
                panelParentName = `${fullQuestionName}-${index}`;
              }

              panel.questions = this.populateElementValues(panelElements, elementData, panelParentName);
              return panel;
            });
          }
        } else if (element.type === 'radiogroup') {
          this.populateRadioGroupDisplayValues(element, populatedElement, jsonData);
        } else if (element.type === 'dropdown' || element.type === 'textbox-auto-complete') {
          const value = jsonData[element.name];
          populatedElement.value = element.choices?.get(value) ? element.choices.get(value) : value;
        } else if (element.type === 'checkbox') {
          const val = jsonData[element.name];
          populatedElement.value = (val && val !== 'no' && (!Array.isArray(val) || (val[0] !== 'no' && val[0] !== 'None'))) ? 'choices.yesOrNo.yes' : 'choices.yesOrNo.no';
        } else if (element.type === 'checkboxlist') {
          const value = jsonData[element.name];
          if (value && Array.isArray(value)) {
            let listValue = '';
            value?.forEach(v => {
              // Need to translate as its list of values
              const translatedValue = element.choices ? this.translator.instant(element.choices.get(v)) : v
              listValue = listValue.concat(translatedValue, '<br>')
            })
            populatedElement.value = listValue
          }
        } else if (element.type === 'applicantlist') {
          const value = jsonData[element.name];
          if (value && Array.isArray(value)) {
            populatedElement.value = this.constructApplicantList(value);
           }
        } else {
          populatedElement.value = jsonData[element.name];
        }
        populatedElement.populatedLabelParams = element.labelParams ? this.populateLabelParams(element.labelParams, jsonData) : '';
        populatedElement.value = populatedElement.value ? populatedElement.value : '--';
      }
      resultElements.push(populatedElement);
    })
    return resultElements;
  }

  private populateLabelParams(labelParams: LabelParam[], jsonData) {
    const result: {} = {};
    labelParams.forEach((labelParam: LabelParam) => {
      Object.keys(labelParam.param).forEach(key => {
        let value: string;
        if (labelParam.skipAttrValueFromJsonData) {
          value = labelParam.param[key]
        } else {
          value = jsonData[labelParam.param[key]];
        }
        if (labelParam.translateParamValue) {
          const splitValues: string[] = value.split(' ');
          if (splitValues[0] === 'Child') {
            result[key] = `${this.translator.instant('review.dynamic.panel.applicantType.' + splitValues[0])} ${splitValues[1]}`
          } else {
            result[key] = this.translator.instant('review.dynamic.panel.applicantType.' + value)
          }
        } else {
          result[key] = value;
        }
      })
    })
    return result;
  }

  private populateRadioGroupDisplayValues(element: Question, populatedElement: Question, jsonData) {
    const value = jsonData[element.name];
    if (element.radioValueAsLabel) {
      populatedElement.label = element.choices?.get(value);
      populatedElement.value = 'choices.yesOrNo.yes';
    } else {
      populatedElement.value = element.choices?.get(value) ? element.choices.get(value) : value;
    }
  }


  private constructApplicantList(applicantList: any[]): string {
    let listValue = '';
    applicantList?.forEach(applicant => {
      let translatedValue: string;
      let applicantType: string;
      let applicantName: string;
      const currentLang: string = this.translator.currentLang;
        if (applicant.hasOwnProperty('applicantType')) {
          applicantType = applicant.applicantType
          applicantName = applicant.applicantName
        } else {
          applicantType = applicant.split(':')[0]
          applicantName = applicant.split(':')[1]
        }
      if (applicantType === 'Applicant') {
        const translatedApplicantType = this.translator.instant('review.dynamic.panel.applicantType.Applicant');
        translatedValue = currentLang === 'fr' ? `${applicantName} : ${translatedApplicantType}` : `${translatedApplicantType}: ${applicantName}`
      } else if (applicantType === 'Spouse') {
        const translatedApplicantType = this.translator.instant('review.dynamic.panel.applicantType.Spouse');
        translatedValue = currentLang === 'fr' ? `${applicantName} : ${translatedApplicantType}` : `${translatedApplicantType}: ${applicantName}`
      } else if (applicantType.split(' ')[0] === 'Child') {
        const translatedApplicantType = this.translator.instant(`review.dynamic.panel.applicantType.Child`);
        translatedValue = currentLang === 'fr' ? `${applicantName} : ${translatedApplicantType} ${applicantType.split(' ')[1]}`
          : `${translatedApplicantType} ${applicantType.split(' ')[1]}: ${applicantName}`
      }

      listValue = listValue.concat(translatedValue, '<br>')
    })

    return listValue;
  }

  getReapplicationModalContent() {
    return {
      title: 'test',
      body: 'test body',
      button: 'button test',
      rightButton: 'right button test'
    }
  }
}
