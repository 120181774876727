<sd-page-container [pageId]="pageId"
                   [showBackButton]=false
                   [showPsRedirectError]="showPsRedirectError"
                   [showResumeBanner]="showResumeBanner"
                   [showRequiredInfoBanner]="showRequiredInfoBanner">
  <div id="page-alerts-container">
    <sd-warning-text [title]="'start-two.warning.title'">
      <div id="warning-content">
        {{'start-two.warning.content' | translate}}
      </div>
      <ul>
        <!-- authenticated users -->
        <ng-container *ngIf="isLoggedInPS; else notLoggedIn">
          <li id="warning-content-list2">{{'start-two.warning.content.list2' | translate}}</li>
        </ng-container>

        <ng-template #notLoggedIn>
          <li id="warning-content-list1">{{'start-two.warning.content.list1' | translate}}</li>

          <!-- caseworkers -->
          <ng-container *ngIf="isAuthorizedUser; else notCaseworker">
            <li id="warning-content-list5">{{'start-two.warning.content.list5' | translate}}</li>
          </ng-container>

          <!-- unauthenticated users -->
          <ng-template #notCaseworker>
            <li id="warning-content-list3">{{'start-two.warning.content.list3' | translate}}</li>
          </ng-template>
        </ng-template>

        <li id="warning-content-list4">{{'start-two.warning.content.list4' | translate}}</li>
      </ul>
      <div class="hintText">
        <sd-hint-text [id]="'start-two.warning.clear.cache.hint'"
                      [text]="'start-two.warning.clear.cache.step.hintText'"
                      [expandableContent]="'confirmation.clear.cache.step.hintContent'"
                      [expandableContentParam]="clearCacheLinkParam">
        </sd-hint-text>
      </div>
    </sd-warning-text>
    <sd-info-text contentId="emergency-authorization" header="important.info.header"
                  content="start-two.emergency.authorization.info.text"
                  [contentParam]="emergencyAssistanceLinkParam">
    </sd-info-text>
  </div>
  <form [formGroup]="form" sdDisableAutocomplete>
    <div class="form-group required">
      <sd-radio-question [showError]="showError" [id]="'start-two.receivingMoneyForReason'"
                         [hintText]="'start-two.receivingMoneyForReason.list'"
                         [required]="true"
                         [ignoreValidationSetup]=true
                         [context]="'start-two.receivingMoneyForReason.context'"
                         [showCustomError]="receivingMoneyReasonError"
                         [customErrorParam]="emergencyAssistanceLinkParam"
                         controlName="receivingMoneyForReason">
      </sd-radio-question>

      <sd-radio-question [showError]="showError" [id]="'start-two.receivingMoneyFromExistingProgram'"
                         [required]="true"
                         [ignoreValidationSetup]=true
                         [showCustomError]="receiveMoneyError"
                         [customErrorParam]="notEligibleLinkParam"
                         controlName="receivingMoneyFromExistingProgram">
      </sd-radio-question>

      <sd-radio-question [showError]="showError" [id]="'start-two.moneyForImmediateNeed'"
                         [required]="true" controlName="moneyForImmediateNeed">
      </sd-radio-question>

      <sd-radio-question [showError]="showError" [id]="'start-two.receivedSocialAssistanceInPast'"
                         [required]="true" [ignoreValidationSetup]=true
                         [hintText]="'start-two.receivedSocialAssistanceInPast.hintText'"
                         controlName="receivedSocialAssistanceInPast">
      </sd-radio-question>
      <sd-radio-question *ngIf="hasReceivedSocialAssistanceInPast"
                         [showError]="showError" [id]="'start-two.receivedSocialAssistanceProgram'"
                         [required]="true"
                         [choices]="receivedSocialAssistanceProgramRadio"
                         [hintText]="'start-two.receivedSocialAssistanceProgram.hintText'"
                         [hintExpandableContent]="'start-two.receivedSocialAssistanceProgram.hintContent'"
                         [validationFunctions]="receivedSocialAssistanceProgramValidationFns"
                         [customErrorParam]="officeLocationLinkParam"
                         controlName="receivedSocialAssistanceProgram">
      </sd-radio-question>
      <sd-date *ngIf="hasReceivedSocialAssistanceInPast && !!receivedSocialAssistanceProgram"
               [showError]="showError"
               [showDay]=false
               id="start-two-dateOfSocialAssistanceInPast"
               label="start-two.dateOfSocialAssistanceInPast"
               controlNameDate="dateOfSocialAssistanceInPast"
               [validationFns]="dateOfAssistanceValidationFns"
               [customErrorParam]="dateOfSocialAssistanceInPastParam"
               [needMarginTop] = true
               [required]="hasReceivedSocialAssistanceInPast">
      </sd-date>

      <sd-text-question *ngIf="hasReceivedSocialAssistanceInPast && !!receivedSocialAssistanceProgram" [showError]="showError"
                        [id]="'start-two.memberId'"
                        [validationFns]="memberIdValidationFns"
                        [hintText]="'start-two.memberId.hintText'"
                        [characterWidth]= 20
                        [hintExpandableContent]="'start-two.memberId.hintContent'"
                        controlName="memberID">
      </sd-text-question>

      <sd-radio-question [id]="'start-two.hasDisability'"
                         [required]="true"
                         [showError]="showError"
                         [boldHintLabel]="'start-two.hasDisability-hintLabel'"
                         [hintText]="'start-two.hasDisability-hintContent'"
                         [removeLabelMargin]="true"
                         [removeHintLabelMargin]="true"
                         controlName="withDisability">
      </sd-radio-question>

      <sd-dropdown-question [choices]="statusInCanada" [showError]="showError" [id]="'start-two.statusInCanada'"
                            [showCustomError]="invalidStatusInCanadaError"
                            [customErrorParam]="officeLocationLinkParam"
                            [required]="true" [ignoreValidationSetup]=true
                            [disabled]="authenticatedMyBApplicant"
                            [hintText]="'start-two.statusInCanada.hintText'"
                            [hintExpandableContent]="'start-two.statusInCanada.hintContent'"
                            [hintExpandableContentParam]="legalStatusHintTextParam"
                            controlName="statusInCanada">
      </sd-dropdown-question>

      <sd-date *ngIf="isStatusInCanadaGovAssistedRefugee"
               [showError]="showError"
               id="start-two-arrivalDateToCanada"
               label="start-two.arrivalDateToCanada"
               controlNameDate="arrivalDateToCanada"
               [validationFns]="arrivalDateValidationFn"
               [needMarginTop] = true
               [required]="isStatusInCanadaGovAssistedRefugee">
      </sd-date>
      <sd-radio-question [showError]="showError" [id]="'start-two.releasedFromInstitution'"
                         [required]="true"
                         controlName="releasedFromInstitution">
      </sd-radio-question>

      <sd-date *ngIf="isReleasedFromInstitution"
               [showError]="showError"
               id="start-two-dateOfReleaseFromInstitution"
               label="start-two.dateOfReleaseFromInstitution"
               controlNameDate="dateOfReleaseFromInstitution"
               [validationFns]="dateOfReleaseFromInstitutionValidationFns"
               [required]="isReleasedFromInstitution"
               [needMarginTop] = true
               (blurEvent)="onDateOfReleaseFromInstitutionChange($event)">
      </sd-date>

      <sd-radio-question *ngIf="isReleasedFromInstitution"
                         [showError]="showError" [required]="isReleasedFromInstitution"
                         [id]="'start-two.stayPartTimeOrFullTime'"
                         [choices]="incarceratedStayChoices"
                         controlName="incarceratedStayPartTimeOrFullTime">
      </sd-radio-question>

      <sd-radio-question [showError]="showError" [id]="'start-two.livingOnFirstNationsReserveLand'"
                         [required]="true"
                         [validationFunctions]="firstNationsDisabilityValidationFns"
                         [showCustomError]="firstNationError"
                         [customErrorParam]="firstNationErrorParam"
                         [disabled]="isReapplication"
                         controlName="livingOnFirstNationsReserveLand">
      </sd-radio-question>

      <sd-text-question *ngIf="hasDisability&&isLivingOnFirstNationsLand"
                        [showError]="showError"
                        [id]="'start-two.firstNationsName'"
                        [required]="hasDisability&&isLivingOnFirstNationsLand"
                        [isAutoComplete]="true"
                        [autoCompleteChoices]="choices"
                        [validationFns]="firstNationsNameValidationFns"
                        [customErrorParam]="firstNationsNameLinkParam"
                        controlName="firstNationsName">
      </sd-text-question>

      <sd-radio-question [showError]="showError" [id]="'start-two.applyingForYourselfOrSomeoneElse'"
                         [choices]="applyingForChoices" [required]="true"
                         [disabled]="authenticatedMyBApplicant"
                         controlName="applyingForYourselfOrSomeoneElse">
      </sd-radio-question>

      <div *ngIf="isApplyingForSomeoneElse" class="ontario-margin-bottom-16-!"></div>

      <sd-dropdown-question *ngIf="isApplyingForSomeoneElse"
                            [choices]="relationshipsToApplicantChoices" [showError]="showError"
                            [labelTitle]="'start-two.applyingForYourselfOrSomeoneElse.title'"
                            [labelDescription]="'start-two.applyingForYourselfOrSomeoneElse.text'"
                            [id]="'start-two.relationToApplicant'"
                            [required]="isApplyingForSomeoneElse"
                            [hintText]="'start-two.relationToApplicant.hintText'"
                            [hintExpandableContent]="'start-two.relationToApplicant.hintContent'"
                            controlName="applyingForSomeoneElseRelationship">
      </sd-dropdown-question>

      <sd-radio-question *ngIf="isApplyingForSomeoneElse && isRelationShipToApplicantSelected"
                         [showError]="showError" [id]="'start-two.need.trustee'"
                         [choices]="needTrustee" [required]="isApplyingForSomeoneElse"
                         [hintText]="'start-two.needTrustee.hintText'"
                         controlName="applyingForSomeoneElseRelationshipNeedTrustee">
      </sd-radio-question>
      <sd-trustee-info-questions *ngIf="trusteeIdentified"
                                 [applicationAnswers]="applicationAnswers"
                                 [trusteeType]="trusteeType"
                                 [isAuthorizedUser]="isAuthorizedUser"
                                 [dialogVisible]="dialogVisible"
                                 [showError]="showError"></sd-trustee-info-questions>
      <div id="recaptcha-err" class="ontario-margin-top-16-!">
        <sd-alert-err *ngIf="recaptchaV2Service.reCaptchaVerifyCallFailure===true"
                      [errorId]="'before-you-apply.recaptcha-verify-call-err'"
                      [errorMessage]="'error.reCaptchaVerifyCallError' | translate">
        </sd-alert-err>
        <sd-alert-err *ngIf="recaptchaV2Service.captchaSuccess === false"
                      [errorId]="'before-you-apply.recapcha-verify-err'"
                      [errorMessage]="'error.reCaptchaVerifyFailedError' | translate">
        </sd-alert-err>
      </div>
    </div>
    <app-mccss-save-continue-button [pageId]="pageId"
                                    dataE2E="continueBtn"
                                    (handlePrimaryButtonClick)="onSubmit(true)"
                                    (handleSecondaryButtonClick)="onSubmit(false)">
    </app-mccss-save-continue-button>
    <div class="recaptchaBox" *ngIf="recaptchaDisplayed">
      <ngx-invisible-recaptcha #captchaElem
                               [siteKey]="recaptchaV2Service.siteKey"
                               (reset)="recaptchaV2Service.handleReset()"
                               (expire)="recaptchaV2Service.handleExpire()"
                               (load)="recaptchaV2Service.handleLoad()"
                               (success)="handleRecaptchaV2Success($event)"
                               [useGlobalDomain]="recaptchaV2Service.useGlobalDomain"
                               [hl]="recaptchaV2Service.lang"
                               [theme]="recaptchaV2Service.scheme"
                               [type]="recaptchaV2Service.type"
                               [badge]="'inline'"
                               formControlName="recaptcha">
      </ngx-invisible-recaptcha>
    </div>
  </form>
</sd-page-container>
