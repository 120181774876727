<div class="{{formGroupClass}}" [formGroup]="form">
  <fieldset class="ontario-fieldset" [attr.aria-labelledby]="this.id.concat('Label')">
    <label [id]="this.id.concat('Label')" class="ontario-fieldset__legend ontario-margin-bottom-8-!">
      {{label | translate: labelParam}}<span class="ontario-label__flag">{{(required ? 'Required' : 'Optional') | translate}}</span>
    </label>
    <sd-alert-err *ngIf="shouldShowErrorMessageRequired()"
                  [errorId]="this.id.concat('.error.invalid.sd.date.required')"
                  [errorMessage]="'error.invalid.sd.date.required' | translate">
    </sd-alert-err>
    <sd-alert-err *ngIf="shouldShowErrorMessageYearMonthRequired()"
                  [errorId]="this.id.concat('.error.invalid.sd.year.month.missing')"
                  [errorMessage]="'error.invalid.sd.year.month.missing' | translate">
    </sd-alert-err>
    <sd-alert-err *ngIf="shouldShowErrorMessageMonthDayRequired()"
                  [errorId]="this.id.concat('.error.invalid.sd.month.day.missing')"
                  [errorMessage]="'error.invalid.sd.month.day.missing' | translate">
    </sd-alert-err>
    <sd-alert-err *ngIf="shouldShowErrorMessageYearDayRequired()"
                  [errorId]="this.id.concat('.error.invalid.sd.year.day.missing')"
                  [errorMessage]="'error.invalid.sd.year.day.missing' | translate">
    </sd-alert-err>
    <sd-alert-err *ngIf="shouldShowErrorMessageYearRequired()"
                  [errorId]="this.id.concat('.error.invalid.sd.year.missing')"
                  [errorMessage]="'error.invalid.sd.year.missing' | translate">
    </sd-alert-err>
    <sd-alert-err *ngIf="shouldShowErrorMessageMonthRequired()"
                  [errorId]="this.id.concat('.error.invalid.sd.month.missing')"
                  [errorMessage]="'error.invalid.sd.month.missing' | translate">
    </sd-alert-err>
    <sd-alert-err *ngIf="shouldShowErrorMessageDayRequired()"
                  [errorId]="this.id.concat('.error.invalid.sd.day.missing')"
                  [errorMessage]="'error.invalid.sd.day.missing' | translate">
    </sd-alert-err>
    <sd-alert-err *ngIf="shouldShowErrorMessageInvalidPattern() else customValidation"
                  [errorId]="this.id.concat('.error.invalid.pattern.sd.date')"
                  [errorMessage]="'error.invalid.pattern.sd.date' | translate">
    </sd-alert-err>

    <div class="ontario-error-messaging ontario-info-message alert-nomaxwidth" *ngIf="infoMessage">
      <span class="ontario-error-messaging__icon">
        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
          <use href="#ontario-icon-alert-information"></use>
        </svg>
      </span>
      <span [id]="infoMessage" class="ontario-error-messaging__content ontario-info-message alert-nomaxwidth" [innerHTML]="infoMessage | translate:{link: officeLocatorLink}"></span>
    </div>

    <ng-template #customValidation>
      <sd-alert-err *ngIf="shouldShowCustomErrorMessage()"
                    [errorId]="this.id.concat('.error.invalid.date.customError')"
                    [errorMessage]="customErrorParam ? (this.formControlDate.errors?.customErrorKey | translate: customErrorParam) : errorMessage"
                    [isInnerHtml]="'true'">
      </sd-alert-err>
    </ng-template>

    <p class="ontario-hint">
      <span *ngIf="customHintText" [id]="this.id.concat('CustomHint')">{{customHintText | translate}}&nbsp;</span>
      <span [id]="this.id.concat('Hint')">{{hintText | translate}}</span>
    </p>

    <div class="ontario-date__group">
      <div class="ontario-date__group-input" [ngClass]="{'ontario-date__group-input-small-right-margin': smallRightMargin}">
        <label [id]="this.id.concat('YearLabel')" [for]="id.concat('YearInput').concat(elementIndex ? elementIndex : '')">{{'year' | translate}}<span class="ontario-show-for-sr">(4 digits)</span></label>
        <input class="ontario-input ontario-input--4-char-width"
               [ngClass]="{'ontario-input__error': shouldShowErrorStyle }"
               [id]="id.concat('YearInput').concat(elementIndex ? elementIndex : '')"
               type="text"
               maxlength="4"
               minlength="4"
               inputmode="numeric"
               (blur) = "onBlur()"
               [formControlName]="controlNameYear"
               attr.aria-required="{{required}}"
               [attr.aria-disabled]="disabled ? true : null">
      </div>
      <div class="ontario-date__group-input" [ngClass]="{'ontario-date__group-input-small-right-margin': smallRightMargin}">
        <label [id]="this.id.concat('MonthLabel')" [for]="id.concat('MonthInput').concat(elementIndex ? elementIndex : '')">{{'month' | translate}}<span class="ontario-show-for-sr">(1 or 2 digits)</span></label>
        <input class="ontario-input ontario-input--2-char-width"
               [ngClass]="{'ontario-input__error': shouldShowErrorStyle }"
               [id]="id.concat('MonthInput').concat(elementIndex ? elementIndex : '')"
               type="text"
               maxlength="2"
               inputmode="numeric"
               (blur) = "onBlur()"
               [formControlName]="controlNameMonth"
               attr.aria-required="{{required}}"
               [attr.aria-disabled]="disabled ? true : null">
      </div>
      <div *ngIf="showDay" class="ontario-date__group-input" [ngClass]="{'ontario-date__group-input-small-right-margin': smallRightMargin}">
        <label [id]="this.id.concat('DayLabel')" [for]="id.concat('DayInput').concat(elementIndex ? elementIndex : '')">{{'day' | translate}}<span class="ontario-show-for-sr">(1 or 2 digits)</span></label>
        <input class="ontario-input ontario-input--2-char-width"
               [ngClass]="{'ontario-input__error': shouldShowErrorStyle }"
               [id]="id.concat('DayInput').concat(elementIndex ? elementIndex : '')"
               type="text"
               maxlength="2"
               inputmode="numeric"
               (blur) = "onBlur()"
               [formControlName]="controlNameDay"
               attr.aria-required="{{required}}"
               [attr.aria-disabled]="disabled ? true : null">
      </div>
    </div>
  </fieldset>
</div>
