import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';

import {AuthService} from './services/auth.service';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {MyBErrorCodes} from './utils/myb-error-codes';
import {SadaErrorCodes} from './utils/sada-error-codes';
import {RedirectService} from './services/redirect.service';

@Injectable({
  providedIn: 'root'
})
export class ResumeApplicationGuard  {
  constructor(
    private authService: AuthService,
    private router: Router,
    private redirectService: RedirectService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const source = route.params.source;
    const token = route.queryParams.token;
    const referenceCode = route.queryParams.refcd;
    const isNew = route.queryParams.new;
    const lang = route.queryParams.lang;

    if (source && (token || referenceCode)) {
      let status: Observable<boolean>;
      if (('myb' === source || 'ps' === source) && token) {
        status = this.authService.validateRedirectTokenAndInitializeSession(token, isNew, lang);
      }

      return status
        .pipe(
          catchError((err: HttpErrorResponse) => {
            const errorCode = err.error?.errorCode;
            let code;
            if ('email' === source) {
              if (SadaErrorCodes.AUTHEC06 === errorCode || SadaErrorCodes.AUTHEC11 === errorCode) {
                code = SadaErrorCodes.AUTHEC03; // For AUTHEC06, message should be same as 03
              } else if (SadaErrorCodes.AUTHEC05 === errorCode) {
                code = SadaErrorCodes.AUTHEC05;
              } else {
                code = SadaErrorCodes.AUTHEC01
              }

              console.log('Invalid access from email activation');
              return of(this.router.createUrlTree(['login-error'], {queryParams: {errorCode: code}}));
            } else {
              // Redirect to MyB with error
              if (SadaErrorCodes.AUTHEC06 === errorCode || SadaErrorCodes.AUTHEC05 === errorCode) {
                console.log('Deny resuming application, redirect to MyB with error EC00451');
                this.redirectService.redirectToMyB(MyBErrorCodes.EC00451);
              } else {
                console.log('Deny resuming application, redirect to MyB with error EC00454');
                this.redirectService.redirectToMyB(MyBErrorCodes.EC00454);
              }
            }
          }));
    }

    return false;
  }
}
