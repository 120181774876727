<div attr.name="{{fieldName}}" [ngClass]="{'ontario-form-group required' : displayQuestionLabel}" [formGroup]="form">
  <fieldset class="ontario-fieldset" [attr.aria-label]="getAriaLabel()">
    <div *ngIf="displayQuestionLabel">
      <label class="ontario-label" [ngClass]="{'remove-margin': removeLabelMargin}" id="{{id}}" [attr.aria-hidden]="'true'">{{label | translate: labelParam}}<span class="ontario-label__flag">{{(required ? 'Required' : 'Optional') | translate}}
      </span></label>
      <label *ngIf="boldHintLabel" [ngClass]="{'remove-margin': removeHintLabelMargin}" class="ontario-label" id="{{id}}-hintLabel">{{boldHintLabel | translate: labelParam}}</label>
      <sd-hint-text *ngIf="hintText && !hintExpandableContent"
                    id="{{id}}-question-hintText"
                    [occurrence]="labelParam?.index"
                    [text]="hintText">
      </sd-hint-text>
    </div>

    <div class="ontario-margin-bottom-16-!" id="{{id}}-extraLabel" *ngIf="extraLabelDescription" [innerHTML]="extraLabelDescription | translate"></div>

    <sd-alert-err *ngIf="showError && formControl.hasError('required')" [errorId]="id.concat('-required-err')" [errorMessage]="'error.required' | translate">
    </sd-alert-err>
    <sd-alert-err *ngIf="showCustomError" [errorId]="id.concat('-custom-err')" [errorMessage]="showCustomError | translate: customErrorParam" [isInnerHtml]="'true'">
    </sd-alert-err>
    <sd-alert-err *ngIf="!formControl.hasError('required') && formControl.errors?.customErrorKey" [errorId]="id.concat('-custom-err-key')" [errorMessage]="formControl.errors?.customErrorKey | translate: customErrorParam" [isInnerHtml]="'true'">
    </sd-alert-err>

    <div class="ontario-radios" id="{{id}}-radio"
         attr.aria-labelledby="{{id}}-extraLabel"
         attr.aria-describedby="{{id}}-err {{id}}-custom-err">
      <div #content>
        <ng-content></ng-content>
      </div>
      <div *ngIf="content?.children.length == 0">
        <div *ngFor="let choice of choices; let i = index" class="ontario-radios__item">
          <input class="ontario-radios__input"
                 [formControlName]="controlName"
                 id="{{id}}-radio-button{{labelParam?.index}}-option-{{i}}"
                 [attr.name]="labelParam?.index ? id + '-radio-button' +  labelParam?.index : id + '-radio-button'"
                 type="radio"
                 [attr.aria-disabled]="disabled ? true : null"
                 value="{{choice.value}}">
          <label class="ontario-label ontario-radios__label" for="{{id}}-radio-button{{labelParam?.index}}-option-{{i}}">
            <span [innerHTML]="choice.label | translate"></span>
          </label>
        </div>
      </div>
    </div>
    <sd-hint-text *ngIf="hintText && hintExpandableContent"
                  id="{{id}}-question-hintText"
                  [occurrence]="labelParam?.index"
                  [text]="hintText"
                  [expandableContent]="hintExpandableContent">
    </sd-hint-text>
  </fieldset>
</div>
