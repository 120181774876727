import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

import {FlowSources} from '../../constants';
import {ExternalRouter} from '../../external.router';
import {PageInfo} from '../../models/page-map';
import {ResumeApplicationResponse} from '../../models/resume-application-response';
import {AuthService} from '../../services/auth.service';
import {ConfigService} from '../../services/config.service';
import {IntakeService} from '../../services/intake.service';
import {PageService} from '../../services/page.service';
import {MyBErrorCodes} from '../../utils/myb-error-codes';
import {TranslationUtil} from '../../utils/translation-util';
import {RedirectService} from '../../services/redirect.service';

/**
 * This page handles the request to resume of the previously-saved application.
 */
@Component({
  selector: 'resume-application',
  templateUrl: './resume-application.component.html',
  styleUrls: ['./resume-application.component.scss']
})
export class ResumeApplicationComponent implements OnInit, OnDestroy {
  private readonly FLOW_SOURCES = FlowSources;

  private subscriptions$: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private externalRouter: ExternalRouter,
    private intakeService: IntakeService,
    private pageService: PageService,
    private translateService: TranslateService,
    private configService: ConfigService,
    private authService: AuthService,
    private redirectService: RedirectService) {
  }

  ngOnInit(): void {
    this.subscriptions$.push(this.route.queryParams.subscribe(params => {
      const source = this.route.snapshot.params.source;
      const isNewApplication = params.new;
      const lang = params.lang;
      if (TranslationUtil.isFrenchLang(lang)) {
        this.translateService.use(lang)
      }

      if ('yes' === isNewApplication) {
        this.redirectToBeforeYouApply();
      } else if ((this.FLOW_SOURCES.MyBenefit === source) || (this.FLOW_SOURCES.PublicSecure === source) ||
        (this.FLOW_SOURCES.email === source)) {
        this.handleResume(source);
      } else {
        this.redirectService.redirectToMyB(MyBErrorCodes.EC00451);
      }
    }));
  }

  /**
   * This method handles the resume-application request. If there is any changes in handlResumeApplication method in ApplicationService in
   * sadabff, this method needs to be revised accordingly.
   * @param source From where this request is from, the 2 accepted sources are Public Secure and MyBenefit.
   */
  private handleResume(source: string): void {
    this.subscriptions$.push(this.intakeService.resumeApplication().subscribe((response) => {
      if (response.reapplicationEligible) {
        this.router.navigate(['/', 'intake', PageInfo.reApplication]);
      } else if (this.FLOW_SOURCES.MyBenefit === source) {
        this.handleResumeFromMyB(response.pageName === PageInfo.programRecommendation ? PageInfo.review: response.pageName);
      } else {
        this.handleResumeFromPs(response);
      }
    }, () => {
      this.redirectService.redirectToMyB(MyBErrorCodes.EC00454)
    }));
  }

  private handleResumeFromMyB(pageName: string): void {
    this.router.navigate(this.pageService.findRouteUrl(pageName), {queryParams: {action: 'resume', showResumeBanner: true}});
  }

  private handleResumeFromPs(response: ResumeApplicationResponse): void {
    if (!!response.pageName) {
      if (response.incompleteAppAvailable) {
        this.router.navigate(this.pageService.findRouteUrl(PageInfo.inprogressOrExpiredApplication));
      } else {  // Account-activation
        this.router.navigate(this.pageService.findRouteUrl(response.pageName), {queryParams: {action: 'resume'}});
      }
    } else if (response.incompleteAppAvailable) {
      this.router.navigate(this.pageService.findRouteUrl(PageInfo.inprogressOrExpiredApplication), {queryParams: {expired: true}});
    } else {
      this.subscriptions$.push(this.authService.resetApplication(this.translateService.currentLang).subscribe(() =>
        this.redirectToBeforeYouApply()
      ));
    }
  }

  private redirectToBeforeYouApply(): void {
    this.router.navigate(['/', 'intake', PageInfo.bya]);
  }

  // Unsubscribe to observables when component is destroyed
  ngOnDestroy(): void {
    this.subscriptions$.forEach((subscription) => {
      subscription.unsubscribe();
    })
  }
}
