<sd-page-container [pageId]="pageId" [showBackButton]=true>
  <div id="page-alerts-container">
    <div *ngIf="showPsRedirectError" class="ontario-margin-bottom-32-!">
      <sd-message-box id="psRedirectError" msgType="error" [title]="'error.service.down.title' | translate" [message]="'error.ps.redirect.error' | translate"></sd-message-box>
    </div>
  </div>
  <div>
    <div class="ontario-row">
      <div class="ontario-medium-6 ontario-small-12 ontario-columns">
        <h1 id="create-account.page.title" class="ontario-h1"  aria-level="1" tabindex="0" [innerHTML]="'create-account.page.title' | translate"></h1>
        <form [formGroup]="form" sdDisableAutocomplete>
        <div class="ontario-bg-white ontario-margin-bottom-16-! content-button">
          <div class="ontario-h4" id="myBenefits-SubHeading" [innerHTML]="'create-account.page.subheading'  | translate"></div>
          <div [innerHTML]="'create-account.page.info' | translate"></div>
          <sd-checkbox-question [id]="'create-account.termAndCondition'" [options]="termAndConditionItems"
                                [showLabel]=false
                                [showCustomError]="showCustomError"
                                (toggle)="onTermAndConditionBoxChanges('termAndCondition', $event)">
          </sd-checkbox-question>
          <div class="button-container">
            <app-mccss-submit-button dataE2E="createAccountBtn" buttonLabel="{{'create-account.create.account.button' | translate}}" [preventDoubleClick]="true" (handleClick)="onSubmit(true)"></app-mccss-submit-button>
            <app-mccss-submit-button dataE2E="signInBtn" buttonLabel="{{'create-account.sign-in' | translate}}" [secondaryButton]="true" [preventDoubleClick]="true" (handleClick)="onSubmit(true, true)"></app-mccss-submit-button>
          </div>
          <table>
            <tr>
              <td class="blue-info-td"><img class="blue-info-img" src="assets/images/blue-info.svg" alt=""></td>
              <td class="reapply-ontario-works" id="reapply-ontario-works" [innerHTML]="'create-account.reapply.ontario.works' | translate"></td>
            </tr>
          </table>
        </div>
        </form>
        <div class="extra-top-for-small">
          <a id="create-later-link" data-e2e="createLaterLink" (click)="onSubmit(false)">
            <span>{{ 'create-account.create.later' | translate}}</span>
          </a>
          <span *ngIf="showMockLoginLink">
            &nbsp;&nbsp;&nbsp;
            <a id="mock-signIn-link" (click)="onMockLogin()">
              <span>Mock Login</span>
            </a>
          </span>
        </div>
        <div>
          <strong><span>{{ 'create-account.powered-by.text' | translate}}</span></strong>
        </div>
      </div>
      <div class="ontario-medium-6 ontario-small-12 ontario-columns">
        <img id="device-image" class="device-img" alt="{{'create-account.device.image.alt' | translate}}" src="{{'create-account.device.image' | translate}}" tabindex="5"/>
      </div>
    </div>
  </div>
</sd-page-container>
