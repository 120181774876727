import {Injectable} from '@angular/core';
import {Question} from '../../common/utils/questions/question';
import {amountMaxLength, positiveCurrencyPattern} from '../../common/utils/validators/custom-validators';
import {ApplicationAnswers} from '../../models/data.model';

@Injectable({
  providedIn: 'root'
})
export class HousingSituationQuestionService {
  getHousingSituationQuestions(appData: ApplicationAnswers): Question<string>[] {
    const questions: Question<string>[] = [
      {
        controlType: 'dropdown',
        key: 'currentHousingSituation',
        label: 'housing-situation.currentHousingSituation',
        required: true,
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ],
        disableOthersOnCondition:[
          {
            questionKey: 'monthlyHousingCost',
            questionControlType: 'textbox',
            value: [
              'I am staying in an emergency hostel or shelter',
              'I have no housing costs',
              'I do not pay rent',
              '']
          },
          {
            questionKey: 'anyoneElseLivingBesidesFamily',
            questionControlType: 'radio',
            value: ['I am staying in an emergency hostel or shelter', '']
          },
          {
            questionKey: 'areYourMealsProvided',
            questionControlType: 'radio',
            value: [
              'I pay rent, my meals are not included',
              'I pay rent that includes my meals',
              'I pay subsidized rent',
              'I own my home',
              'I am staying in an emergency hostel or shelter',
              'I have no housing costs',
              '']
          }
        ],
        options: [
          {
            value: 'I pay rent, my meals are not included',
            label: 'housing-situation.choices.currentHousingSituation.iPayRentExcludingMeals'
          },
          {
            value: 'I pay rent that includes my meals',
            label: 'housing-situation.choices.currentHousingSituation.iPayRentIncludingMeals'
          },
          {
            value: 'I pay subsidized rent',
            label: 'housing-situation.choices.currentHousingSituation.iPaySubsidizedRent'
          },
          {
            value: 'I own my home',
            label: 'housing-situation.choices.currentHousingSituation.iOwnMyHome'
          },
          {
            value: 'I am staying in an emergency hostel or shelter',
            label: 'housing-situation.choices.currentHousingSituation.iStayInEmergencyHostelOrShelter'
          },
          {
            value: 'I do not pay rent',
            label: 'housing-situation.choices.currentHousingSituation.iDoNotPayRent'
          },
          {
            value: 'I have no housing costs',
            label: 'housing-situation.choices.currentHousingSituation.noHouseCost'
          }
        ]
      },
      {
        controlType: 'textbox',
        key: 'monthlyHousingCost',
        label: 'housing-situation.monthlyHousingCostRent',
        required: true,
        needMarginTop: true,
        characterWidth: 10,
        hintText: { text: 'housing-situation.monthlyHousingCost.hintText' },
        isDollarValue: true,
        visibleOnConditions: [
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: ['I pay rent, my meals are not included',
              'I pay rent that includes my meals',
              'I pay subsidized rent']
          }
        ],
        validators: this.getAmountFieldValidators()
      },
      {
        controlType: 'textbox',
        key: 'monthlyHousingCostForOwner',
        label: 'housing-situation.monthlyHousingCostOwn',
        required: true,
        needMarginTop: true,
        isDollarValue: true,
        characterWidth: 10,
        hintText: { text: 'housing-situation.monthlyHousingCost.hintText' },
        visibleOnConditions: [
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: [
              'I own my home']
          }
        ],
        validators: this.getAmountFieldValidators()
      },
      {
        controlType: 'radio',
        key: 'areYourMealsProvided',
        label: 'housing-situation.areYourMealsProvided',
        context: 'housing-situation.areYourMealsProvided.context',
        required: true,
        needMarginTop: true,
        options: [
          {
            value: 'yes',
            label: 'choices.radioDefault.yes'
          },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
        ],
        visibleOnConditions: [
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: ['I do not pay rent']
          },
        ]
      },
      {
        controlType: 'checkbox',
        label: 'housing-situation.housingCostDetail',
        hintText: {text: 'housing-situation.housingCostDetailSelect'},
        key: 'otherHousingCostHeatCheckBox',
        required:false,
        needMarginTop: true,
        characterWidth: 10,
        options: [{
          value: false,
          label: 'housing-situation.otherHousingCostHeat.label.checkbox'
        }],
        disableOthersOnCondition:[
          {
            questionKey: 'otherHousingCostHeat',
            questionControlType: 'textbox',
            value: [false]
          }
        ],
        visibleOnConditions: [
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: ['I pay rent, my meals are not included',
              'I pay subsidized rent',
              'I own my home',
              'I do not pay rent']
          },
        ]
      },
      {
        controlType: 'textbox',
        key: 'otherHousingCostHeat',
        required: true,
        needMarginBottom: true,
        isDollarValue: true,
        characterWidth: 10,
        label: 'housing-situation.otherHousingCost.label.textbox',
        visibleOnConditions: [
          {
            questionKey: 'otherHousingCostHeatCheckBox',
            questionControlType: 'checkbox',
            index: 0,
            value: ['false']
          },
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: ['I pay rent, my meals are not included',
              'I pay subsidized rent',
              'I own my home',
              'I do not pay rent']
          }
        ],
        validators: this.getAmountFieldValidators()
      },
      {
        controlType: 'checkbox',
        key: 'otherHousingCostHydroCheckBox',
        options: [{
          value: false,
          label: 'housing-situation.otherHousingCostHydro.label.checkbox'
        }],
        disableOthersOnCondition:[
          {
            questionKey: 'otherHousingCostHydro',
            questionControlType: 'textbox',
            value: [false]
          }
        ],
        visibleOnConditions: [
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: ['I pay rent, my meals are not included',
              'I pay subsidized rent',
              'I own my home',
              'I do not pay rent']
          },
        ]
      },
      {
        controlType: 'textbox',
        key: 'otherHousingCostHydro',
        required: true,
        needMarginBottom: true,
        isDollarValue: true,
        label: 'housing-situation.otherHousingCost.label.textbox',
        characterWidth: 10,
        visibleOnConditions: [
          {
            questionKey: 'otherHousingCostHydroCheckBox',
            questionControlType: 'checkbox',
            index: 0,
            value: ['false']
          },
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: ['I pay rent, my meals are not included',
              'I pay subsidized rent',
              'I own my home',
              'I do not pay rent']
          }
        ],
        validators: this.getAmountFieldValidators()
      },
      {
        controlType: 'checkbox',
        key: 'otherHousingCostUtilitiesCheckBox',
        options: [{
          value: false,
          label: 'housing-situation.otherHousingCostUtilities.label.checkbox'
        }],
        disableOthersOnCondition:[
          {
            questionKey: 'otherHousingCostUtilities',
            questionControlType: 'textbox',
            value: [false]
          }
        ],
        visibleOnConditions: [
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: ['I pay rent, my meals are not included',
              'I pay subsidized rent',
              'I own my home',
              'I do not pay rent']
          }
        ]
      },
      {
        controlType: 'textbox',
        key: 'otherHousingCostUtilities',
        required: true,
        needMarginBottom: true,
        isDollarValue: true,
        label: 'housing-situation.otherHousingCost.label.textbox',
        characterWidth: 10,
        visibleOnConditions: [
          {
            questionKey: 'otherHousingCostUtilitiesCheckBox',
            questionControlType: 'checkbox',
            index: 0,
            value: ['false']
          },
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: ['I pay rent, my meals are not included',
              'I pay subsidized rent',
              'I own my home',
              'I do not pay rent']
          }
        ],
        validators: this.getAmountFieldValidators()
      },
      {
        controlType: 'checkbox',
        key: 'otherHousingCostCondoFeeCheckBox',
        options: [{
          value: false,
          label: 'housing-situation.otherHousingCostCondoFee.label.checkbox'
        }],
        disableOthersOnCondition:[
          {
            questionKey: 'otherHousingCostCondoFee',
            questionControlType: 'textbox',
            value: [false]
          }
        ],
        visibleOnConditions: [
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: ['I own my home']
          },
        ]
      },
      {
        controlType: 'textbox',
        key: 'otherHousingCostCondoFee',
        required: true,
        needMarginBottom: true,
        isDollarValue: true,
        label: 'housing-situation.otherHousingCost.label.textbox',
        characterWidth: 10,
        visibleOnConditions: [
          {
            questionKey: 'otherHousingCostCondoFeeCheckBox',
            questionControlType: 'checkbox',
            index: 0,
            value: ['false']
          },
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: ['I own my home']
          }
        ],
        validators: this.getAmountFieldValidators()
      },
      {
        controlType: 'checkbox',
        key: 'otherHousingCostHomeOrTenantInsuranceCheckBox',
        options: [{
          value: false,
          label: 'housing-situation.otherHousingCostHomeOrTenantInsurance.label.checkbox'
        }],
        disableOthersOnCondition:[
          {
            questionKey: 'otherHousingCostHomeOrTenantInsurance',
            questionControlType: 'textbox',
            value: [false]
          }
        ],
        visibleOnConditions: [
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: ['I pay rent, my meals are not included',
              'I pay subsidized rent',
              'I own my home']
          },
        ]
      },
      {
        controlType: 'textbox',
        key: 'otherHousingCostHomeOrTenantInsurance',
        required: true,
        needMarginBottom: true,
        isDollarValue: true,
        label: 'housing-situation.otherHousingCost.label.textbox',
        characterWidth: 10,
        visibleOnConditions: [
          {
            questionKey: 'otherHousingCostHomeOrTenantInsuranceCheckBox',
            questionControlType: 'checkbox',
            index: 0,
            value: ['false']
          },
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: ['I pay rent, my meals are not included',
              'I pay subsidized rent',
              'I own my home']
          }
        ],
        validators: this.getAmountFieldValidators()
      },
      {
        controlType: 'checkbox',
        key: 'otherHousingCostPropertyTaxCheckBox',
        options: [{
          value: false,
          label: 'housing-situation.otherHousingCostPropertyTax.label.checkbox'
        }],
        disableOthersOnCondition:[
          {
            questionKey: 'otherHousingCostPropertyTax',
            questionControlType: 'textbox',
            value: [false]
          }
        ],
        visibleOnConditions: [
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: ['I own my home']
          },
        ]
      },
      {
        controlType: 'textbox',
        key: 'otherHousingCostPropertyTax',
        required: true,
        isDollarValue: true,
        label: 'housing-situation.otherHousingCost.label.textbox',
        characterWidth: 10,
        visibleOnConditions: [
          {
            questionKey: 'otherHousingCostPropertyTaxCheckBox',
            questionControlType: 'checkbox',
            index: 0,
            value: ['false']
          },
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: ['I own my home']
          }
        ],
        validators: this.getAmountFieldValidators()
      },
      {
        controlType: 'radio',
        key: 'anyoneElseLivingBesidesFamily',
        label: 'housing-situation.anyoneElseLivingBesidesFamily',
        context: 'housing-situation.anyoneElseLivingBesidesFamily.context',
        required: true,
        needMarginTop: true,
        hintText: {text: 'housing-situation.anyoneElseLivingBesidesFamily.hintText', expandableContent: 'housing-situation.anyoneElseLivingBesidesFamily.hintContent'},
        subLabelList: this.getSubLabelList(appData),
        ariaHideSubLabelList: true,
        options: [
          {
            value: 'yes',
            label: 'choices.radioDefault.yes'
          },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
        ],
        visibleOnConditions: [
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: ['I pay rent, my meals are not included',
              'I pay rent that includes my meals',
              'I pay subsidized rent',
              'I own my home',
              'I have no housing costs',
              'I do not pay rent']
          },
        ]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
        visibleOnConditions: [
          {
            questionKey: 'anyoneElseLivingBesidesFamily',
            questionControlType: 'radio',
            value: ['yes']
          },
          {
            questionKey: 'currentHousingSituation',
            questionControlType: 'dropdown',
            value: ['I pay rent, my meals are not included',
              'I pay rent that includes my meals',
              'I pay subsidized rent',
              'I own my home',
              'I have no housing costs',
              'I do not pay rent']
          }
        ]
      }
    ]
    return questions
  }

  /**
   * To retrieve the list of persons that are in the application.
   * @param appData The collected application data.
   */
  private getSubLabelList(appData: ApplicationAnswers): Array<{textKey: string, textParam?: {index?: number, name: string}}> {
    const subLabelList = new Array<{textKey: string, textParam?: {index?: number, name: string}}>();
    let subLabelElement = this.constructSubLabelElement('appData.multiple.applicant.label.applicant', appData.jsonData.firstName,
      appData.jsonData.lastName, 0);
    if (subLabelElement) {
      subLabelList.push(subLabelElement);
    }
    subLabelElement = this.constructSubLabelElement('appData.multiple.applicant.label.spouse', appData.jsonData.spouseFirstName,
      appData.jsonData.spouseLastName, 0);
    if (subLabelElement) {
      subLabelList.push(subLabelElement);
    }
    if (appData.jsonData.childList) {
      appData.jsonData.childList.forEach((child, index) => {
        subLabelElement = this.constructSubLabelElement('appData.multiple.applicant.label.child', child.childFirstName, child.childLastName, index + 1);
        if (subLabelElement) {
          subLabelList.push(subLabelElement);
        }
      })
    }
    return subLabelList;
  }

  private constructSubLabelElement(messageKey: string, firstName: string, lastName: string, index: number):
    {textKey: string, textParam?: {index?: number, name: string}} {
    if (!firstName && !lastName) {
      return null;
    }

    let name = '';
    if (firstName) {
      name = firstName
    }
    if (lastName) {
      name += ' ' + lastName;
    }
    if (index) {
      return {textKey: messageKey, textParam: {index, name}};
    } else {
      return {textKey: messageKey, textParam: {name}};
    }
  }

  private getAmountFieldValidators() {
    return [
      {
        type: 'required',
        errorKey: 'error.required'
      },
      {
        type: 'min',
        minimumValue: 0,
        errorKey: 'error.invalid.min.amount'
      },
      {
        type: 'maxNumericLength',
        maxLengthValue: amountMaxLength,
        errorKey: 'error.invalid.max.amount'
      },
      {
        type: 'pattern',
        regex: positiveCurrencyPattern,
        errorKey: 'error.invalid.currency.amount'
      }
    ];
  }
}
