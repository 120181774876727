import { Component, Inject, LOCALE_ID, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageBaseComponent } from '../PageBaseComponent';
import { PageInfo } from 'src/app/models/page-map';
import { getAddressReviewFlags, MccssAddressChoiceResponse, MccssAddressData, MccssAddressLookupComponent, MccssAddressSearchStore } from '@mccss/pclookup-common-ui';
import { ActivatedRoute, Router } from '@angular/router';
import { IntakeService } from 'src/app/services/intake.service';
import { TranslateService } from '@ngx-translate/core';
import { Idle } from '@ng-idle/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ExternalRouter } from 'src/app/external.router';
import { ConfigService } from 'src/app/services/config.service';
import { AuthService } from 'src/app/services/auth.service';
import { PageService } from 'src/app/services/page.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { take } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'sd-address-info',
  templateUrl: './address-info.component.html',
  styleUrls: ['./address-info.component.scss'],

})
export class AddressInfoComponent extends PageBaseComponent implements OnInit, OnDestroy {

  @ViewChild(MccssAddressLookupComponent) addressLookup!: MccssAddressLookupComponent;

  addressData!: MccssAddressData;

  constructor(private readonly router: Router,
              public route: ActivatedRoute,
              public intake: IntakeService,
              @Inject(LOCALE_ID) protected localeId: string,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService,
              private readonly addressStrore: MccssAddressSearchStore,
              private readonly location: Location) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.addressInfo;
  }

  ngOnInit(): void {
    // To support browser back button
    this.addUseCacheParam();

    this.addressStrore.getEnteredAddress$.pipe(
      take(1)
    ).subscribe(address => {
      // For existing address, navigate to address summary page directly
      if (this.isToConfirmAddress(address)) {
        this.router.navigate([`/intake/${PageInfo.confirmAddress}`], { queryParams: { useCache: 'true' } });
        return;
      }
      this.addressStrore.setShowReapplicationTitle(false);
      this.addressData = address;
    });
  }

  /**
   * This is for supporting the browser back button
   */
  private addUseCacheParam(): void {
    // Get current URL with query parameters
    const currentUrl = this.location.path(true);
    const url = new URL(window.location.origin + currentUrl);
    // Set the useCache parameter
    url.searchParams.set('useCache', 'true');
    this.location.replaceState(url.pathname + url.search);
  }

  private isToConfirmAddress(address: MccssAddressData) {
    return this.route.snapshot.queryParamMap.get('useCache') !== 'true'
        && address?.homeAddress?.postalCode
        && (!address?.isMailingAddressEnabled || address?.isMailingAddressSameAsHome || address?.mailingAddress)
        && (!address?.isTrusteeAddressEnabled || address?.trusteeAddress);
  }

  onSubmit(toContinue: boolean): void {
    this.addressLookup.onSubmit().subscribe((choicesResponse) => {
      if (choicesResponse) {
        this.continue(choicesResponse, this.addressLookup.localAddressData);
      }
    });
  }

    /**
   * Based on the entered address and the returned address choices/suggestions, decide the next page to navigate to.
   * @param choicesResp the response of address choices/suggestions API
   * @param address the user-entered address in Address Information page
   */
    continue(choicesResp: MccssAddressChoiceResponse, address: MccssAddressData | null) {
      let reviewFlags = getAddressReviewFlags(choicesResp, address);
  
      if (reviewFlags.reviewHomeAddress || reviewFlags.reviewMailingAddress || reviewFlags.reviewTrusteeAddress) {
        this.router.navigate([`/intake/${PageInfo.addressReview}`], { queryParams: { useCache: 'true' } });
      } else {
        this.router.navigate([`/intake/${PageInfo.confirmAddress}`], { queryParams: { useCache: 'true' } });
      }
    }

  preSaveApplication() { }

  postInitializeForm() {
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }

}
