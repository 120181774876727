// Map every SADA page to a unique ID which is also used as the route
// Ensure that IDs are synced with app-progress.yml in the config repo (for pages that can be saved/resumed)

export class PageInfo {
  public static readonly home = 'home-page';
  public static readonly bya = 'before-you-apply';
  public static readonly createAccount = 'create-account';
  public static readonly personalInfo = 'personal-information';
  public static readonly spouseInfo = 'spouse-information';
  public static readonly childrenInfo = 'children-information';
  public static readonly additionalInfo = 'additional-information';
  public static readonly financialIndependence = 'financial-independence';
  public static readonly sponsorship = 'sponsorship';
  public static readonly addressInfo = 'address-information';
  public static readonly addressReview = 'address-review';
  public static readonly confirmAddress = 'confirm-address';
  public static readonly housingSituation = 'housing-situation';
  public static readonly earnedIncome = 'earned-income';
  public static readonly householdIncome = 'household-income';
  public static readonly financialAssets = 'financial-assets';
  public static readonly bankDetails = 'bank-details';
  public static readonly review = 'review';
  public static readonly programRecommendation = 'program-recommendation';
  public static readonly wda = 'demographic-information';
  public static readonly consent = 'consent';

  // EID
  public static readonly eidPage = 'eid-page';
  public static readonly eidQuestions = 'eid-questions';

  // ESign
  public static readonly signatureLanding = 'signature-landing';
  public static readonly additionalSignatures = 'additional-signatures';

  // Confirmation
  public static readonly confirmation = 'confirmation';

  // ASC
  public static readonly ascLanding = 'app-status-landing';
  public static readonly ascPending = 'app-status-pending';
  public static readonly ascResultsOds = 'app-status.results.ODS';
  public static readonly ascResultsOnw = 'app-status.results.ONW';
  public static readonly ascResultsOnwOds = 'app-status.results.ONWODS';
  // Other
  public static readonly error = 'error';
  public static readonly globalError = 'global-error';
  public static readonly somethingWentWrong = 'something-went-wrong';
  public static readonly inprogressOrExpiredApplication = 'inprogress-or-expired-application';
  public static readonly reApplication = 're-application';
  public static readonly unauthorized = 'unauthorized';

  // Page order mapping for in-app back button
  public static readonly pageOrderedArray = [PageInfo.home, PageInfo.bya, PageInfo.personalInfo,PageInfo.createAccount,
    PageInfo.spouseInfo, PageInfo.childrenInfo, PageInfo.additionalInfo, PageInfo.sponsorship, PageInfo.addressInfo,
    PageInfo.addressReview, PageInfo.confirmAddress, PageInfo.housingSituation, PageInfo.financialIndependence,
    PageInfo.earnedIncome, PageInfo.householdIncome,
    PageInfo.financialAssets, PageInfo.bankDetails, PageInfo.review, PageInfo.programRecommendation];
}


